import React from "react";
import { useDispatch } from "react-redux";
import { toastify } from "../../../helper/helper";
import { signOutCall } from "../../../utils/common";
import { disconnectSSO } from "../../../services/authenticationServices";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import usePersistState from "../../../state/usePersistState";
import { resetOPTInitialState } from "../../../redux/reducers/optReducer";

export default function DisconnectLinkedinDialogue({
  setDisconnectLinkedinPopUp,
}) {
  const dispatch = useDispatch();
  const { users } = usePersistState();
  const { id: userId } = users || {};

  const handleSignOut = async () => {
    dispatch(resetOPTInitialState());
    signOutCall();
    // refreshSignOutAPI(localStorage.getItem("email"));
  };

  const handleSubmit = async () => {
    try {
      await disconnectSSO(userId);
      if (localStorage.getItem("isLoginWithLinkedin")) {
        handleSignOut();
      }
      toastify("success", "Successfully disconnected from LinkedIn");
      setDisconnectLinkedinPopUp(false);
    } catch (err) {
      toastify("error", err && err.data ? err.data.message : err);
      setDisconnectLinkedinPopUp(false);
      console.log("====>=====", err, ">>>>>>>>>>>>>>");
    }
  };

  return (
    <div>
      <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
        <div className="customized-button">
          <ButtonCustom onClick={handleSubmit}>Yes</ButtonCustom>
        </div>
        <a
          href="/#"
          className="text-grey cursor-pointer link-focus"
          style={{ marginTop: "30px" }}
          onClick={(e) => {
            e.preventDefault();
            setDisconnectLinkedinPopUp(false);
          }}
          aria-label="Not now button"
        >
          Not Now
        </a>
      </div>
    </div>
  );
}

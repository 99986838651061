import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { isEmpty } from "lodash";

import { toastify } from "../../../../helper/helper";
import usePromise from "../../../../hooks/usePromise/usePromise";
import usePersistState from "../../../../state/usePersistState";
import { updateUserProfileDetails } from "../../../../services/authenticationServices";

import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import { cacheTimezone } from "../../../../utils/common";

export default function TimezoneUpdateDialog({
  setUpdateTimezonePopUp = () => {},
  setSelectedTimezone = () => {},
  setIsDDClicked = () => {},
  isDDClicked,
  hideButton = false,
  updateTimezone = true,
}) {
  const { users = {}, updatePersistState } = usePersistState();
  const [updatedTimezone, setUpdatedTimezone] = useState({
    value: users?.time_zone,
    id: users?.timezone_id,
  });
  const [defaultTimezone, setDefaultTimezone] = useState(null);
  const [, refreshUpdateUserProfileDetails] = usePromise(
    updateUserProfileDetails
  );
  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    if (!timezones?.length) {
      setTimezones(
        JSON.parse(sessionStorage.getItem("get_rails_timezone"))?.timezones
      );
    } else {
      cacheTimezone();
    }
  }, []);

  useEffect(() => {
    if (typeof timezones !== "undefined") {
      timezones.map((item) => {
        if (users.timezone_id && item.id === users.timezone_id) {
          setDefaultTimezone(item.timezone_name_label);
          setUpdatedTimezone({
            label: item.timezone_name_label,
            value: item.timezone_name_value,
            id: item.id,
          });
        } else if (!users.timezone_id) {
          let offset = new Date().getTimezoneOffset();
          offset = -offset;
          timezones.find((i) => {
            if (i.offset === offset) {
              setDefaultTimezone(i.timezone_name_label);
              setUpdatedTimezone({
                value: i.timezone_name_value,
                id: i.id,
                label: i.timezone_name_label,
              });
              setSelectedTimezone({ value: i.timezone_name_value, id: i.id });
            }
            return i.offset === offset;
          });
        }
      });
    }
  }, [defaultTimezone, timezones]);

  let newArrayOfObj = [];
  if (timezones) {
    newArrayOfObj = (timezones ?? []).map((item) => {
      return {
        label: item.timezone_name_label,
        value: item.timezone_name_value,
        id: item.id,
        timezone_short: item.timezone_short,
      };
    });
  }

  const handleSubmit = async () => {
    if (updateTimezone) {
      users.time_zone = updatedTimezone.value;
      users.timezone_id = updatedTimezone.id;
      try {
        let requestBody = {
          user: {
            time_zone: updatedTimezone.value,
            timezone_id: updatedTimezone.id,
          },
        };
        refreshUpdateUserProfileDetails(users?.id, requestBody);
        updatePersistState({ users: { ...users } });
        setUpdateTimezonePopUp(false);
      } catch (err) {
        toastify(
          "error",
          err && err.data ? err.data.message : "Something went wrong"
        );
        console.log("error", err, "errors");
      }
    }
  };

  return (
    <Formik
      initialValues={{
        timezone: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, ...props }) => (
        <Form>
          <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
            <div onClick={() => setIsDDClicked(!isDDClicked)}>
              {defaultTimezone ? (
                <Field
                  name="timezone"
                  {...props}
                  type="select"
                  className="input-wrap"
                >
                  {() => (
                    <DropdownCustom
                      className={
                        hideButton ? "timezoneDropdown" : "timezoneSelect"
                      }
                      marginBottom={0}
                      name="timezone"
                      required={true}
                      placeholder={hideButton ? "Select timezone" : ""}
                      justifyContent="left"
                      value={{
                        label: updatedTimezone.label,
                        value: updatedTimezone.value,
                      }}
                      onChange={(val) => {
                        setSelectedTimezone(val);
                        setUpdatedTimezone(val);
                      }}
                      options={newArrayOfObj}
                    />
                  )}
                </Field>
              ) : (
                ""
              )}
            </div>
            {!hideButton ? (
              <>
                <div className="checkbox-wrap">
                  Select timezone to schedule a meeting.
                </div>
                <div className="customized-button">
                  <ButtonCustom
                    isSubmitting={isSubmitting}
                    isDisabled={!isEmpty(errors)}
                    type="submit"
                  >
                    Save
                  </ButtonCustom>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}

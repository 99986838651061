import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

import SavedSearchesStyles from "./SavedSearchesStyles";
import deleteIcon from "../../../assets/svg/f-delete-icon.svg";
import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";
import Image from "../../../assets/svg/discover_no_people.svg";
import { routes } from "../../../routes";
import { useQueryParams } from "../../../hooks/useQueryParams";
import usePromise from "../../../hooks/usePromise/usePromise";
import { useAppState } from "../../../context";
import usePersistState from "../../../state/usePersistState";
import { NOTIFICATION_FREQUENCY } from "../../../constant/mockdata";
import {
  toastify,
  capitalizeFirstLetterOnType,
  capitalizeFirstLetter,
} from "../../../helper/helper";

import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import CardCustom from "../../CardCustom/CardCustom";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import DropdownCustom from "../../DropdownCustom/DropdownCustom";
import TextboxCustom from "../../../components/Textbox/TextboxCustom";
import JobsListingEffect from "../../effects/JobsListingEffect/JobsListingEffect";
import RemoveSearchModal from "../RemoveSearchModal/RemoveSearchModal";
import EmptyListWithImage from "../../EmptyListWithImage/EmptyListWithImage";

import {
  getSavedSearchesAPI,
  deleteSavedSearchAPI,
  updateJobsSearchAPI,
} from "../../../services/JobServices";

const SaveSearchList = () => {
  const navigate = useNavigate();
  const { setJobsFilter } = useAppState("job");
  const { jobsCountryList } = usePersistState();
  const query = useQueryParams();

  const [callingGetSavedSearchesAPI, refreshGetSavedSearchesAPI] =
    usePromise(getSavedSearchesAPI);
  const [callingDeleteSavedSearchAPI, refreshDeleteSavedSearchAPI] =
    usePromise(deleteSavedSearchAPI);
  const [callingUpdateSavedSearchAPI, refresUpdateSavedSearchAPI] =
    usePromise(updateJobsSearchAPI);
  const [searchList, setSearchList] = useState([]);
  const [showDeleteSearchPopup, setShowDeleteSearchPopup] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState(null);
  const [showEditMode, setShowEditMode] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editSearchData, setEditSearchData] = useState({});
  const [notificationFrequency, setNotificationFrequency] = useState(null);
  const { title, keyword, country, visa } = editSearchData;
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  useEffect(() => {
    refreshGetSavedSearchesAPI();
  }, []);

  useEffect(() => {
    if (
      callingGetSavedSearchesAPI.hasFetched() &&
      callingGetSavedSearchesAPI.hasErrors() === false &&
      callingGetSavedSearchesAPI.data() &&
      callingGetSavedSearchesAPI.data().data &&
      callingGetSavedSearchesAPI.data().data.saved_jobs
    ) {
      setSearchList(callingGetSavedSearchesAPI.data().data.saved_jobs);
    }
  }, [callingGetSavedSearchesAPI.isFetching()]);

  useEffect(() => {
    if (
      callingDeleteSavedSearchAPI.hasFetched() &&
      callingDeleteSavedSearchAPI.hasErrors() === false &&
      callingDeleteSavedSearchAPI.data() &&
      callingDeleteSavedSearchAPI.data().message
    ) {
      toastify("success", callingDeleteSavedSearchAPI.data().message);
      setSelectedSearch(null);
      setShowDeleteSearchPopup(false);
      setShowEditMode(false);
      refreshGetSavedSearchesAPI();
    }
  }, [callingDeleteSavedSearchAPI.isFetching()]);

  useEffect(() => {
    if (
      callingUpdateSavedSearchAPI.hasFetched() &&
      callingUpdateSavedSearchAPI.hasErrors() === false &&
      callingUpdateSavedSearchAPI.data() &&
      callingUpdateSavedSearchAPI.data().message
    ) {
      toastify("success", callingUpdateSavedSearchAPI.data().message);
      setSelectedSearch(null);
      setShowEditMode(false);
      refreshGetSavedSearchesAPI();
      setEditSearchData({});
    }
  }, [callingUpdateSavedSearchAPI.isFetching()]);

  const onSearchAgainClick = (searchData) => {
    setJobsFilter({
      ...searchData,
    });
    navigate("/jobs/search");
  };

  const deleteSavedSearch = () => {
    refreshDeleteSavedSearchAPI(searchList[selectedSearch].id);
  };

  const editSavedSearch = () => {
    if (title) {
      refresUpdateSavedSearchAPI({
        ...editSearchData,
        search_type:
          country && country === "us" && visa !== null ? visa : "international",
      });
    }
  };

  const editSavedSearchJobAlerts = () => {
    refresUpdateSavedSearchAPI({
      ...notificationFrequency,
    });
  };

  const handleEditOperation = (data, i) => {
    setSelectedSearch(i);
    if (window.screen.width <= 1024) setShowEditModal(true);
    else setShowEditMode(true);
    setEditSearchData({
      ...data,
      visa:
        data.search_type && data.search_type !== "international"
          ? data.search_type
          : null,
    });
    setNotificationFrequency({
      ...data,
    });
  };

  const handleQueryEditOperation = () => {
    const id = Number(query.get("id"));
    if (isNaN(id)) return;
    const index = searchList.findIndex((item) => item.id == id);
    if (index !== -1) {
      const data = searchList[index];
      query.delete("id");
      handleEditOperation(data, index);
      navigate(window.location.pathname, { replace: true });
    }
  };
  useEffect(() => {
    if (query.has("id") && searchList?.length > 0) {
      try {
        handleQueryEditOperation();
      } catch (error) {
        console.log(`exception with message ${error?.message}`);
      }
    }
  }, [searchList]);

  const renderSaveSearchList = (data, i) => {
    const { title = "", state = null, city = null, created_at = null } = data;

    const country =
      data.country !== null
        ? jobsCountryList.find((rec) => rec.value === data.country)
        : "";

    return (
      <Grid item xs={12} key={i}>
        <CardCustom
          className={`search-list-card ${
            selectedSearch !== null && selectedSearch === i ? "active" : ""
          }`}
        >
          <div className="search-item">
            <div className="content">
              <div className="header-primary">{title}</div>
              <div className="text-grey">
                {city !== null
                  ? `${capitalizeFirstLetter(city)}${state !== null ? "," : ""}`
                  : ""}{" "}
                {state !== null ? `${state}${country !== null ? "," : ""}` : ""}
                {"  "}
                {country ? country.label : ""}{" "}
                {created_at !== null
                  ? `|  ${moment(created_at).format("MMM DD, YYYY")}`
                  : ""}
              </div>
            </div>
            <div
              className={`btn-wrap card-actions-wrapper ${
                accessibilityHelp == "true" ? "show-actions" : ""
              }`}
            >
              {selectedSearch == null &&
                selectedSearch !== i &&
                showEditMode === false && (
                  <div className="apply-btn">
                    <ButtonCustom
                      width="125"
                      height="40"
                      onClick={() => onSearchAgainClick(data)}
                      className={
                        selectedSearch !== null &&
                        selectedSearch === i &&
                        showEditMode === true
                          ? "focus-white"
                          : ""
                      }
                    >
                      Search again
                    </ButtonCustom>
                  </div>
                )}
              <div className="apply-btn edit-btn">
                <ButtonCustom
                  width="94"
                  height="40"
                  color="primary"
                  className={
                    selectedSearch !== null &&
                    selectedSearch === i &&
                    showEditMode === true
                      ? "focus-white"
                      : ""
                  }
                  onClick={() => {
                    if (
                      selectedSearch !== null &&
                      selectedSearch === i &&
                      showEditMode === true
                    ) {
                      setSelectedSearch(null);
                      setShowEditMode(false);
                      setShowEditModal(false);
                      setEditSearchData({});
                    } else {
                      handleEditOperation(data, i);
                    }
                  }}
                >
                  {selectedSearch !== null &&
                  selectedSearch === i &&
                  showEditMode === true
                    ? "Cancel edits"
                    : "Edit"}
                </ButtonCustom>
              </div>
              {selectedSearch == null &&
                selectedSearch !== i &&
                showEditMode === false && (
                  <div className="apply-btn delete-btn">
                    <ButtonCustom
                      width="125"
                      height="40"
                      className={`bg-gray ${
                        selectedSearch !== null &&
                        selectedSearch === i &&
                        showEditMode === true
                          ? "focus-white"
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedSearch(i);
                        setShowDeleteSearchPopup(true);
                      }}
                    >
                      <img src={deleteIcon} alt="" />
                    </ButtonCustom>
                  </div>
                )}
            </div>
          </div>
        </CardCustom>
      </Grid>
    );
  };

  const renderEditSearch = (
    <>
      <div className="edit-saved-search">
        {!showEditModal && (
          <div className="title-primary mb-15">Edit Saved Search</div>
        )}
        <Grid container>
          <Grid item xs={12}>
            <CardCustom className="search-card">
              <div className="form-field-group">
                <TextboxCustom
                  name="search"
                  type="text"
                  placeholder="Enter search name"
                  label="Search name"
                  lableaignment="left"
                  required={true}
                  value={title}
                  onChange={(e) => {
                    setEditSearchData({
                      ...editSearchData,
                      title: capitalizeFirstLetterOnType(e.target.value),
                    });
                  }}
                />
              </div>
              <div className="form-field-group">
                <TextboxCustom
                  name="search"
                  type="text"
                  placeholder="Enter job title or company"
                  label="Search keyword"
                  lableaignment="left"
                  value={keyword}
                  onChange={(e) =>
                    setEditSearchData({
                      ...editSearchData,
                      keyword: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-field-group">
                <DropdownCustom
                  name="country"
                  label="Select country"
                  placeholder="Country"
                  justifyContent="left"
                  options={jobsCountryList}
                  value={jobsCountryList.find((rec) => rec.value === country)}
                  onChange={(e) => {
                    setEditSearchData({
                      ...editSearchData,
                      country: e.value,
                      state: null,
                      city: null,
                    });
                  }}
                />
              </div>
              <div className="searchBtn">
                <ButtonCustom
                  onClick={editSavedSearch}
                  width="250"
                  disabled={title ? false : true}
                >
                  Apply Changes
                </ButtonCustom>
              </div>
            </CardCustom>
          </Grid>
        </Grid>

        <div className="edit-saved-search mt-30">
          <div
            className={
              "title-primary mb-15 " + (!showEditModal ? "" : "text-center")
            }
          >
            Edit job alerts
          </div>
          <Grid container>
            <Grid item xs={12}>
              <CardCustom className="search-card">
                <div className="form-field-group">
                  <DropdownCustom
                    width="350"
                    name="notification_frequency"
                    label="Notification frequency"
                    justifyContent="left"
                    options={NOTIFICATION_FREQUENCY}
                    value={NOTIFICATION_FREQUENCY.find(
                      (rec) =>
                        rec.value ===
                        notificationFrequency?.notification_frequency
                    )}
                    onChange={(e) =>
                      setNotificationFrequency({
                        ...notificationFrequency,
                        notification_frequency: e.value,
                      })
                    }
                  />
                </div>
                <div className="searchBtn">
                  <ButtonCustom onClick={editSavedSearchJobAlerts} width="250">
                    Apply Changes
                  </ButtonCustom>
                </div>
              </CardCustom>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );

  const Loader = () => {
    return <JobsListingEffect count={5} />;
  };
  const handleClose = () => {
    setSelectedSearch(null);
    setShowDeleteSearchPopup(false);
  };

  return (
    <SavedSearchesStyles>
      <div className="f-main-title-with-button mb-30">
        <div className="btn-wrapper-left">
          <ButtonCustom
            className="back-to-btn"
            onClick={() => navigate(routes.JOBS.SEARCH)}
          >
            <img src={BackArrow} width="21" alt="Back arrow" />
            Back to Jobs
          </ButtonCustom>
        </div>
        <h2 className="f-main-title mb-0 w-100">Saved Searches</h2>
      </div>
      <div className={`search-list ${showEditMode ? "two-columns" : ""}`}>
        {callingGetSavedSearchesAPI.isFetching() ? (
          <Loader />
        ) : searchList && searchList.length > 0 ? (
          <div className="saved-search-content">
            {searchList.map((data, i) => renderSaveSearchList(data, i))}
          </div>
        ) : (
          <div className="saved-search-content">
            <EmptyListWithImage
              Image={Image}
              title="Looks like there are no saved searches."
              image
            />
          </div>
        )}
        {showEditMode && (
          <div className="edit-saved-search-wrapper" id="detail-panel">
            {renderEditSearch}
          </div>
        )}
      </div>
      {showDeleteSearchPopup && (
        <RemoveSearchModal
          showDeleteSearchPopup={showDeleteSearchPopup}
          handleClose={handleClose}
          deleteSavedSearch={deleteSavedSearch}
        />
      )}

      {showEditModal && (
        <CustomDialog
          open={showEditModal}
          title={"Edit Saved Search"}
          handleClose={() => {
            setSelectedSearch(null);
            setShowEditMode(false);
            setShowEditModal(false);
            setEditSearchData({});
          }}
          className="edit-saved-search-popup"
          dialogTitleClass="modal-title"
          dialogSubTitleClass="text-para"
        >
          <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
            {renderEditSearch}
            <a
              href="/#"
              className="ngray-link mt-30"
              onClick={(e) => {
                e.preventDefault();
                setSelectedSearch(null);
                setShowEditMode(false);
                setShowEditModal(false);
                setEditSearchData({});
              }}
              aria-label="cancel"
            >
              Cancel
            </a>
          </div>
        </CustomDialog>
      )}
    </SavedSearchesStyles>
  );
};
export default SaveSearchList;

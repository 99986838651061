import styled from "styled-components";
import { PRIMARY_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .event-card {
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color);
      cursor: pointer;
    }
    &.active {
      background: ${PRIMARY_COLOR};
      .card-content {
        .event-item {
          .title,
          .sub-title {
            color: #fff;
          }
          .time {
            color: var(--medium-gray-color);
          }
          .btn-view-details {
            display: none;
          }
        }
      }
    }
    .card-content {
      padding: 20px;
      cursor: auto;
      @media (max-width: 1367px) {
        box-shadow: var(--card-box-shadow);
        border-radius: 10px;
        background: #fff;
      }
      @media (max-width: 767px) {
        padding: 16px;
      }
      .event-item {
        h3 {
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          color: var(--primary-dark-color);
          margin: 0 0 1px 0;
        }
        h2 {
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          color: var(--primary-dark-color);
          margin: 11px 0 0 0;
        }
        .tag {
          background: var(--icon_background);
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          text-align: center;
          color: var(--primary-dark-color);
          padding: 5px 10px 3px;
          border-radius: 100px;
          display: inline-block;
          margin: 8px 0 0 0;
        }
        .up-btn {
          background-color: #8094ab !important;
          margin: 8px 0 0 10px;
          color: #ffffff;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          text-align: center;
          padding: 5px 15px;
        }
        .customContent {
          display: flex;
          text-transform: capitalize;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;

import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import { Avatar, Grid } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import { useNavigate, Link } from "react-router-dom";

import DiscoverTopicStyles from "./DiscoverTopicStyles";
import EmptyImage from "../../../assets/svg/no_discover_conver.svg";
import searchIcon from "../../../assets/svg/f-search.svg";
import { useAppState } from "../../../context";
import { createFreshChatPreview } from "../../../utils/common";
import usePersistState from "../../../state/usePersistState";
import usePromise from "../../../hooks/usePromise/usePromise";
import { toastify } from "../../../helper/helper";

import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { InfoPanel } from "../../InfoPanel/InfoPanel";
import { NoUserImage, capitalizeFirstLetter } from "../../../helper/helper";
import EmptyListWithImage from "../../EmptyListWithImage/EmptyListWithImage";
import JobsListingEffect from "../../effects/JobsListingEffect/JobsListingEffect";
import Textbox from "../../Textbox/TextboxCustom";
import { getDiscoverTopicsByNameAPI } from "../../../services/topicServices";
import RecommendationsDetails from "../../NetworkDashboard/RecommendationsDetails/RecommendationsDetails";
import {
  favorite_Unfavorite_NetworksAPI,
  getFavoriteNetworksAPI,
} from "../../../services/networkServices";
import { getUserById } from "../../../services/authenticationServices";
import {
  followUnfollowTopicAPI,
  getRecommendedTopicsAPI,
} from "../../../services/networkServices";

const DiscoverTopic = () => {
  const navigate = useNavigate();
  const { setFavoriteUsers } = usePersistState();
  const {
    getChatContactListItem,
    getChatPreviewByReceiverId,
    chats,
    setChats,
    setConversationInformation,
    setCurrentConversationId,
    createFreshChatRecord,
    setFavoriteUsersCount,
  } = useAppState("chat");
  const { viewTopic } = useAppState("topic");

  const { discoverNetworks } = useAppState("network");
  const [discoverTopics, setDiscoverTopics] = useState([]);
  const [topicTypeOptions, setTopicTypeOptions] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [topicType, setTopicType] = useState("");
  const [callingDiscoverTopicsByNameAPI, refreshDiscoverTopicsByNameAPI] =
    usePromise(getDiscoverTopicsByNameAPI);
  const [callingGetRecommendedTopicsAPI, refreshRecommendedTopicsAPI] =
    usePromise(getRecommendedTopicsAPI);
  const [callingFollowUnfollowTopicAPI, refreshFollowUnfollowTopicAPI] =
    usePromise(followUnfollowTopicAPI);
  const [callingGetFavoriteNetworksAPI, refreshGetFavoriteNetworksAPI] =
    usePromise(getFavoriteNetworksAPI);
  const [
    callingFavorite_Unfavorite_NetworksAPI,
    refreshFavorite_Unfavorite_NetworksAPI,
  ] = usePromise(favorite_Unfavorite_NetworksAPI);
  const [callingRefreshgetUserById, refreshgetUserById] =
    usePromise(getUserById);
  const [activeUser, setActiveUser] = useState(null);
  const [showUserPopup, setShowUserPopup] = useState({
    open: false,
    index: -1,
  });
  const [selectedTopic, setSelectedTopic] = useState(null);

  let sortBy = "name";

  useEffect(() => {
    if (
      callingDiscoverTopicsByNameAPI.hasFetched() &&
      callingDiscoverTopicsByNameAPI.hasErrors() === false &&
      callingDiscoverTopicsByNameAPI.data() &&
      callingDiscoverTopicsByNameAPI.data().data
    ) {
      const { data } = callingDiscoverTopicsByNameAPI.data();
      setDiscoverTopics(data?.recommended_topics);
      setTopicTypeOptions([...data?.topic_type, ...data?.portal_type]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingDiscoverTopicsByNameAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetRecommendedTopicsAPI.hasFetched() &&
      callingGetRecommendedTopicsAPI.hasErrors() === false
    ) {
      const { data } = callingGetRecommendedTopicsAPI.data();
      setDiscoverTopics(data.recommended_topics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetRecommendedTopicsAPI.isFetching()]);

  useEffect(() => {
    if (callingFollowUnfollowTopicAPI.hasFetched()) {
      if (callingFollowUnfollowTopicAPI.hasErrors() === false) {
        if (
          callingFollowUnfollowTopicAPI.data() &&
          callingFollowUnfollowTopicAPI.data().message
        ) {
          toastify("success", callingFollowUnfollowTopicAPI.data().message);
          fetchTopics();
          setSelectedTopic(null);
        }
      } else {
        if (
          callingFollowUnfollowTopicAPI.data() &&
          callingFollowUnfollowTopicAPI.data().message
        ) {
          toastify("error", callingFollowUnfollowTopicAPI.data().message);
          setSelectedTopic(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingFollowUnfollowTopicAPI.isFetching()]);

  useEffect(() => {
    if (
      callingFavorite_Unfavorite_NetworksAPI.hasFetched() &&
      callingFavorite_Unfavorite_NetworksAPI.hasErrors() === false
    ) {
      toastify(
        "success",
        callingFavorite_Unfavorite_NetworksAPI.data().message
      );
      refreshGetFavoriteNetworksAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingFavorite_Unfavorite_NetworksAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetFavoriteNetworksAPI.hasFetched() &&
      callingGetFavoriteNetworksAPI.hasErrors() === false
    ) {
      const { favourite_list = [] } = callingGetFavoriteNetworksAPI.data().data;
      setFavoriteUsersCount(favourite_list.length);
      setFavoriteUsers(favourite_list.map(({ id }) => id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetFavoriteNetworksAPI.isFetching()]);

  const fetchTopics = ({
    sort = sortBy,
    search_keyword = searchKeyword,
    topic_type = topicType,
  } = {}) => {
    if (sort === "name") {
      refreshDiscoverTopicsByNameAPI({ search_keyword, topic_type });
    } else {
      refreshRecommendedTopicsAPI();
    }
  };

  useEffect(() => {
    if (viewTopic?.topic_name?.length > 0) {
      setSearchKeyword(viewTopic?.topic_name);
      fetchTopics({ search_keyword: viewTopic?.topic_name });
    } else {
      fetchTopics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const followTopic = (topicId) => {
    setSelectedTopic(topicId);
    refreshFollowUnfollowTopicAPI(topicId);
  };

  const handleSearchChange = debounce((value) => {
    fetchTopics({ search_keyword: value });
    setSearchKeyword(value);
  }, 500);

  const onContactInfoUserClick = async (userId = null, index = -1) => {
    let response = await refreshgetUserById(userId);
    if (response.success) {
      setActiveUser(response.data.user_info);
      setShowUserPopup({
        open: true,
        index,
      });
    }
  };

  const handleClose = (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    setShowUserPopup({
      open: false,
      index: -1,
    });
  };

  const startChatWithNewUser = (e, item) => {
    if (e) e.stopPropagation();
    try {
      const { id } = item;
      const user = getChatContactListItem(id, discoverNetworks);
      const chat_preview = getChatPreviewByReceiverId(id);
      if (chat_preview) {
        setCurrentConversationId(chat_preview.conversation_id);
        // redirect to chat
        navigate("/network/messenger", { state: { userid: id } });
        return;
      }

      const randomConversationId = new Date().getTime();
      const { preview, conversation } = createFreshChatPreview(
        user,
        randomConversationId
      );
      setChats([preview, ...chats]);
      setConversationInformation(randomConversationId, conversation);
      setCurrentConversationId(randomConversationId);
      createFreshChatRecord(randomConversationId); // we will track by this id
      //redirect to chat page
      navigate("/network/messenger", { state: { userid: id } });
    } catch (error) {
      toastify(
        "error",
        "Something went wrong. Please try again later or contact us for support."
      );
    }
  };
  const handleFavorite = (e, userId) => {
    refreshFavorite_Unfavorite_NetworksAPI(userId);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setTopicType("");
    setTimeout(() => {
      fetchTopics({ search_keyword: "", topic_type: "" });
    }, 1000);
  };

  return (
    <DiscoverTopicStyles>
      <div className="discover-page">
        <div className="f-search-wrapper">
          <div className="fsearch-box">
            <Textbox
              name="search_keyword"
              type="text"
              placeholder="Search keyword"
              label=""
              lableaignment="left"
              value={searchKeyword}
              onChange={(obj) => setSearchKeyword(obj.target.value)}
              onEnterKey={() => handleSearchChange(searchKeyword)}
            />
            <a
              className="search-btn"
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                handleSearchChange(searchKeyword);
              }}
            >
              <img src={searchIcon} alt="Search icon" />
            </a>
          </div>
          {topicTypeOptions.length > 0 && (
            <div className="filter-item">
              <label>Filter</label>
              <div className="filter-item-content">
                {topicTypeOptions.map((item, i) => {
                  return (
                    <a
                      href="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        if (topicType === item) {
                          setTopicType("");
                          fetchTopics({ topic_type: "" });
                        } else {
                          setTopicType(item);
                          fetchTopics({ topic_type: item });
                        }
                      }}
                      className={`item ${topicType === item ? "active" : ""}`}
                      key={i}
                    >
                      {item}
                    </a>
                  );
                })}
              </div>
              {(topicType || searchKeyword) && (
                <div className="clear-filter">
                  <Link
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      clearFilters();
                    }}
                    className="ngray-link"
                  >
                    Clear all
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="discover-topics-content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {callingDiscoverTopicsByNameAPI.isFetching() ||
              callingGetRecommendedTopicsAPI.isFetching() ? (
                <JobsListingEffect count={10} />
              ) : discoverTopics.length > 0 ? (
                discoverTopics.map((topic, index) => {
                  return (
                    <InfoPanel
                      key={index}
                      avatar={topic.topic_icon}
                      title={topic.topic_name}
                      subTitle={null}
                      customCardClass="discover-topic-info-panel"
                      tags={[
                        topic?.topic_portal_type === "admissions"
                          ? "Admissions"
                          : "",
                      ]}
                      customContent={
                        <div className="panel-info-contact">
                          {topic?.total_participants > 0 && (
                            <div className="member-count">
                              {`${topic.total_participants} ${
                                topic.total_participants > 1
                                  ? "members"
                                  : "member"
                              }${
                                topic?.global === true &&
                                topic?.participants_from_school > 0
                                  ? ` (${topic?.participants_from_school} from your school)`
                                  : ""
                              }`}
                            </div>
                          )}
                          <div className="type-global">
                            <span>
                              {capitalizeFirstLetter(topic?.topic_type || "")}
                              {" | "}
                              {topic?.global ? "All schools" : "My school"}
                            </span>
                          </div>
                          <div className="created-by">
                            <span>Created by</span>
                            <a
                              href="/#"
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                onContactInfoUserClick(
                                  topic.created_by_id,
                                  index
                                );
                              }}
                            >
                              <Avatar
                                alt=""
                                src={topic.created_by_image || NoUserImage}
                              />
                              <span>{topic.created_by_name}</span>
                            </a>
                            {showUserPopup?.open &&
                              showUserPopup?.index === index && (
                                <ClickAwayListener
                                  onClickAway={() => handleClose()}
                                >
                                  <div className="created-by__popup">
                                    <RecommendationsDetails
                                      details={activeUser && activeUser}
                                      handleClose={handleClose}
                                      handleMessage={startChatWithNewUser}
                                      handleFavorite={handleFavorite}
                                      showLoader={callingRefreshgetUserById.isFetching()}
                                      isTopicUser={true}
                                    />
                                  </div>
                                </ClickAwayListener>
                              )}
                          </div>
                        </div>
                      }
                      intro={
                        <div className="description">
                          {topic.topic_description}
                        </div>
                      }
                      displayStar={false}
                      action={
                        <ButtonCustom
                          height={30}
                          width={100}
                          color="secondary"
                          onClick={() => {
                            followTopic(topic.id);
                          }}
                          isDisabled={
                            topic && topic.following_status === "requested"
                              ? true
                              : false
                          }
                          aria-label={`${capitalizeFirstLetter(
                            topic.following_status
                          )} ${topic.topic_name}`}
                          isSubmitting={
                            selectedTopic === topic.id &&
                            callingFollowUnfollowTopicAPI.isFetching()
                          }
                        >
                          {capitalizeFirstLetter(topic.following_status)}
                        </ButtonCustom>
                      }
                    />
                  );
                })
              ) : (
                <EmptyListWithImage
                  Image={EmptyImage}
                  title={"Looks like there is no Community with this search."}
                  subTitle={
                    "Please try another search or start the Community you’re looking for and invite others to join!"
                  }
                  containerClassname={"empty-discover-topics"}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </DiscoverTopicStyles>
  );
};

export default DiscoverTopic;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Tooltip } from "@mui/material";
import { ClickAwayListener } from "@mui/base";

import { useAppState } from "../../../context";
import usePersistState from "../../../state/usePersistState";
import {
  NoUserImage,
  capitalizeFirstLetter,
  toastify,
} from "../../../helper/helper";
import usePromise from "../../../hooks/usePromise/usePromise";
import { routes } from "../../../routes";
import { followUnfollowTopicAPI } from "../../../services/networkServices";
import {
  closeTopicAPI,
  muteUnmuteTopicAPI,
  reportTopicAPI,
  favouriteTopic,
} from "../../../services/topicServices";
import CardCustom from "../../CardCustom/CardCustom";
import SavedPostsWidget from "../../SavedPostWidget/SavedPostWidget";
import MenuDialog from "./MenuDialog";
import ReadMoreReadLess from "../../atoms/ReadMoreLess/ReadMoreLess";
import TopicRightStyles from "./TopicRightStyles";

import topicMenuOpen from "../../../assets/svg/topic-menu-open.svg";
import topicMenuClose from "../../../assets/svg/topic-menu-close.svg";
import savePinIcon from "../../../assets/svg/save-pin.svg";

const MAX_LINES_TOPIC_POST = 7;

const TopicRight = ({ refreshGetAllSavedPosts = () => {} }) => {
  const {
    activeTopic,
    toggleActiveTopicMuteStatus,
    setPreApiCallSavedTopic,
    activeTopicId,
    handlePostTopicCloseUnfollow,
    updateTopicFavouriteStatus,
    topicPosts,
  } = useAppState("topic");
  const navigate = useNavigate();
  const { users } = usePersistState();
  const { id: userId } = users || {};

  const [menuOptions, setMenuOptions] = useState(false);
  const [menuModelState, setMenuModelState] = useState({
    isOpen: false,
  });
  const [reportText, setReportText] = useState("");

  const [callingMuteUnmuteTopicAPI, refreshMuteUnmuteTopicAPI] =
    usePromise(muteUnmuteTopicAPI);
  const [callingCloseTopicAPI, refreshCloseTopicAPI] =
    usePromise(closeTopicAPI);
  const [callingReportTopicAPI, refreshReportTopicAPI] =
    usePromise(reportTopicAPI);
  const [callingFollowUnfollowTopicAPI, refreshFollowUnfollowTopicAPI] =
    usePromise(followUnfollowTopicAPI);

  const menuModelHandler = (modelId) => {
    switch (modelId) {
      case 1:
        setMenuModelState((current) => ({
          ...current,
          modelId,
          isOpen: true,
          title: `Are you sure you want to ${
            activeTopic?.mute_topic ? "unmute" : "mute"
          } this Community?`,
          btnText: `${activeTopic?.mute_topic ? "Unmute" : "Mute"}`,
          bottomText: "Cancel",
          showTextBox: false,
        }));
        break;
      case 2:
        setMenuModelState((current) => ({
          ...current,
          modelId,
          isOpen: true,
          title: "Are you sure you want to close this Community?",
          subTitle:
            "If you close this Community, others will no longer be able to access the content.",
          btnText: "Close Community for all",
          bottomText: "Keep Community open",
          showTextBox: false,
        }));
        break;
      case 3:
        setMenuModelState((current) => ({
          ...current,
          modelId,
          isOpen: true,
          title: "What would you like to report?",
          btnText: "Share Report",
          bottomText: "No thanks",
          showTextBox: true,
        }));
        break;
      case 4:
        setMenuModelState((current) => ({
          ...current,
          modelId,
          isOpen: true,
          title: "Thanks for sharing your feedback!",
          subTitle: "We will review your report and take the necessary action.",
          btnText: "Got It",
          bottomText: "",
          showTextBox: false,
          className: "report-feedback",
        }));
        break;
      case 5:
        setMenuModelState((current) => ({
          ...current,
          modelId,
          isOpen: true,
          title: `Are you sure you want to unfollow this Community?`,
          btnText: `Unfollow`,
          bottomText: "Cancel",
          showTextBox: false,
        }));
        break;
      default:
        break;
    }
  };

  const closeMenuModel = () => {
    setMenuModelState({ isOpen: false });
  };

  const onTextChange = (value) => {
    setReportText(value);
  };

  const callAPI = (modelId) => {
    switch (modelId) {
      case 1:
        // mute-unmute
        refreshMuteUnmuteTopicAPI(
          activeTopic?.topic_follower_id,
          activeTopic?.id
        );
        break;
      case 2:
        // close Community
        refreshCloseTopicAPI(activeTopic?.id);
        break;
      case 3:
        //Report Community
        refreshReportTopicAPI({
          report_text: reportText?.trim(),
          topic_id: activeTopic?.id,
        });
        break;
      case 4:
        closeMenuModel();
        break;
      case 5:
        // Unfollow Community
        refreshFollowUnfollowTopicAPI(activeTopic?.id);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      callingMuteUnmuteTopicAPI.hasFetched() &&
      callingMuteUnmuteTopicAPI.hasErrors() === false
    ) {
      toastify("success", callingMuteUnmuteTopicAPI.data().message);
      toggleActiveTopicMuteStatus();
      closeMenuModel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingMuteUnmuteTopicAPI.isFetching()]);

  useEffect(() => {
    if (
      callingCloseTopicAPI.hasFetched() &&
      callingCloseTopicAPI.hasErrors() === false
    ) {
      handlePostTopicCloseUnfollow(activeTopic?.id);
      toastify("success", callingCloseTopicAPI.data().message);
      closeMenuModel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingCloseTopicAPI.isFetching()]);

  useEffect(() => {
    if (callingReportTopicAPI.hasFetched()) {
      if (callingReportTopicAPI.hasErrors() === false) {
        toastify("success", callingReportTopicAPI.data().message);
        menuModelHandler(4);
      }
      if (callingReportTopicAPI.hasErrors() === true) {
        toastify("error", callingReportTopicAPI.data().message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingReportTopicAPI.isFetching()]);

  useEffect(() => {
    if (
      callingFollowUnfollowTopicAPI.hasFetched() &&
      callingFollowUnfollowTopicAPI.hasErrors() === false
    ) {
      handlePostTopicCloseUnfollow(activeTopic?.id);
      toastify("success", callingFollowUnfollowTopicAPI.data().message);
      closeMenuModel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingFollowUnfollowTopicAPI.isFetching()]);

  const handleSavePostsClick = () => {
    setPreApiCallSavedTopic("topic-saved");
    refreshGetAllSavedPosts(activeTopicId);
  };

  const handleFavorite = async () => {
    setMenuOptions(false);
    if (activeTopic?.id) {
      const response = await favouriteTopic(activeTopic.id);
      if (response?.status === 200) {
        toastify("success", response?.data?.message);
        // Update local app state
        updateTopicFavouriteStatus(activeTopic.id);
      }
    } else {
      toastify("error");
    }
  };

  const handleEditTopic = (topicId = null) => {
    if (topicId) {
      navigate(routes.NETWORK.NEW_TOPIC + "?topicId=" + topicId);
    }
  };

  return (
    <TopicRightStyles>
      <div className="right-section">
        <MenuDialog
          isOpen={menuModelState.isOpen}
          data={menuModelState}
          handleClose={closeMenuModel}
          callAPI={callAPI}
          onTextChange={onTextChange}
          reportText={reportText}
        />
        <CardCustom className="details ntopics-cards-wrapper">
          <div className="right-section__options">
            <img
              alt="menu-icon"
              src={menuOptions ? topicMenuClose : topicMenuOpen}
              onClick={() => {
                setMenuOptions((current) => !current);
              }}
            />
            {menuOptions && (
              <ClickAwayListener onClickAway={() => setMenuOptions(false)}>
                <ul className="right-section__options__list">
                  <li onClick={handleFavorite}>
                    {activeTopic?.favourite ? "Unfavorite" : "Favorite"}
                  </li>
                  <li onClick={() => menuModelHandler(1)}>
                    {activeTopic?.mute_topic
                      ? "Unmute Community"
                      : "Mute Community"}
                  </li>
                  <li onClick={() => menuModelHandler(3)}>Report</li>
                  {activeTopic?.created_by_id === userId ? (
                    <>
                      <li onClick={() => menuModelHandler(2)}>
                        Close Community
                      </li>
                      <li
                        onClick={(e) => {
                          e.preventDefault();
                          handleEditTopic(activeTopic?.id);
                        }}
                      >
                        Edit Community
                      </li>
                    </>
                  ) : !activeTopic?.show_default ? (
                    <li onClick={() => menuModelHandler(5)}>
                      Unfollow Community
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </ClickAwayListener>
            )}
          </div>

          <div className="max-width-wrap">
            <div>
              <Avatar
                src={activeTopic?.topic_icon || NoUserImage}
                className="images"
                alt="topic image"
              />
            </div>
            <Tooltip title={activeTopic?.topic_name}>
              <div className="title-primary">
                {activeTopic?.topic_name?.length > 60
                  ? activeTopic?.topic_name?.slice(0, 60) + "..."
                  : activeTopic?.topic_name}
              </div>
            </Tooltip>
            <div className="decription">
              <ReadMoreReadLess
                maxLines={MAX_LINES_TOPIC_POST}
                moreComp={
                  <span className="cursor-pointer nlink-secondary">
                    Read more
                  </span>
                }
                lessComp={
                  <span className="cursor-pointer nlink-secondary">
                    Read less
                  </span>
                }
              >
                {activeTopic.topic_description}
              </ReadMoreReadLess>
            </div>
            <div className="participant">
              {`${activeTopic?.total_participants} ${
                activeTopic?.total_participants > 1 ? "members" : "member"
              }${
                activeTopic?.global === true &&
                activeTopic?.participants_from_school > 0
                  ? ` (${activeTopic?.participants_from_school} from your school)`
                  : ""
              }`}
            </div>
            <div className="type">
              {capitalizeFirstLetter(activeTopic?.topic_type)}
              {" | "}
              {activeTopic?.global ? "All schools" : "My school"}
            </div>
          </div>
        </CardCustom>
        {topicPosts?.saved_posts > 0 && (
          <div className="right-section__saved" onClick={handleSavePostsClick}>
            <img src={savePinIcon} alt="save" />
            <span>Your saved posts ({topicPosts?.saved_posts})</span>
          </div>
        )}
        <div className="right-section__members">
          <h3 className="right-section__members__header">
            {activeTopic?.global ? "Community Creator" : "Creator and members"}
          </h3>
          <div className="right-section__members__creator">
            <Avatar
              src={activeTopic?.created_by_image}
              alt={activeTopic?.created_by_name}
            />
            <div className="right-section__members__creator__title">
              <div className="right-section__members__creator__title__line-one">
                <span className="right-section__members__creator__title__line-one__name">
                  {activeTopic?.created_by_name}
                </span>
                {activeTopic?.user_type && (
                  <div className="right-section__members__creator__title__line-one__tag">
                    {activeTopic?.user_type}
                  </div>
                )}
              </div>
              <div className="right-section__members__creator__title__line-two">
                <span>{activeTopic?.created_by_school_name}</span>
              </div>
            </div>
          </div>
          {!activeTopic?.global && (
            <SavedPostsWidget
              widgetText={`View all ${activeTopic?.total_participants} members`}
              onClick={(e) => {
                e.preventDefault();
                if (activeTopic?.total_participants > 0) {
                  navigate(
                    routes.NETWORK.TOPIC_PARTICIPANTS +
                      "?topicId=" +
                      activeTopic?.id
                  );
                }
              }}
              showIcon={false}
              customClassName="mt-15"
            />
          )}
        </div>
      </div>
    </TopicRightStyles>
  );
};
export default TopicRight;

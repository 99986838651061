import { useReducer } from "react";
import { findIndex, filter, get, find } from "lodash";

import { NOTIFICATION_FILTER } from "../constant/mockdata";
import usePersistState from "./usePersistState";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  notifications: [],
  notificationFilters: NOTIFICATION_FILTER,
  notificationSettings: {},
  new_notifications: [],
  past_notifications: [],
  unread_notifications: [],
  iFollowingTopics: [],
  iCreatedTopics: [],
  unDoTopic: null,
  unReadNotiCount: 0,
  saveJobs: [],
};

const getNotificationFilters = (permissions) => {
  return [
    ...(permissions["chat"]
      ? [{ id: 1, title: "Networking", key: "networking", checked: true }]
      : []),

    ...(permissions["webinar"]
      ? [{ id: 2, title: "Webinars", key: "webinars", checked: true }]
      : []),

    { id: 3, title: "Jobs", key: "job", checked: true },

    ...(permissions["resources"]
      ? [
          {
            id: 4,
            title: "Other Resources",
            key: "other_resources",
            checked: true,
          },
        ]
      : []),

    ...(permissions["appointment"]
      ? [{ id: 5, title: "Calendar", key: "calendar", checked: true }]
      : []),

    ...(permissions["topics"]
      ? [{ id: 6, title: "Topics", key: "topics", checked: true }]
      : []),

    ...(permissions["appointment"]
      ? [
          {
            id: 6,
            title: "Book Appointment",
            key: "appointment",
            checked: true,
          },
        ]
      : []),
  ];
};

const useNotification = () => {
  const { permissions = {} } = usePersistState();

  const initialNotificationFilters = getNotificationFilters(permissions);

  const [state, setState] = useReducer(reducer, {
    ...initialArgs,
    notificationFilters: initialNotificationFilters,
  });

  const setNotifications = (data) => {
    const { new_notifications, past_notifications, unread_notifications } =
      data;
    /*
    let newNotificationList = [];
    let pastNotificationList = [];

    if (new_notifications.length > 0) {
      _map(new_notifications, (category, i) => {
        _map(category, (noti, i) => {
          newNotificationList.push(noti);
        });
      });
    }

    if (past_notifications.length > 0) {
      _map(past_notifications, (category, i) => {
        _map(category, (noti, i) => {
          pastNotificationList.push(noti);
        });
      });
    }

    setState({
      new_notifications:
        new_notifications.length > 0 ? newNotificationList : [],
      past_notifications:
        past_notifications.length > 0 ? pastNotificationList : []
    });
    */
    setState({
      new_notifications: new_notifications,
      past_notifications: past_notifications,
      unread_notifications: unread_notifications,
    });
  };

  const updateNotificationFilters = (id) => {
    let newNotificationFilters = Object.assign([], state.notificationFilters);
    const objIndex = findIndex(newNotificationFilters, { id: id });
    if (objIndex > -1) {
      newNotificationFilters[objIndex].checked =
        !newNotificationFilters[objIndex].checked;
    }
    setState({ notificationFilters: newNotificationFilters });
  };

  const deleteNotification = (id, notificationStatus) => {
    if (notificationStatus) {
      const data = filter(state.new_notifications, function (o) {
        return o.id !== id;
      });
      setState({
        new_notifications: data,
      });
    } else {
      const data = filter(state.past_notifications, function (o) {
        return o.id !== id;
      });
      setState({
        past_notifications: data,
      });
    }
  };

  const setNotificationSettings = (obj) => {
    setState({
      notificationSettings: obj,
    });
  };

  const newNotificationSettings = (obj) => {
    setState({
      notificationSettings: obj,
    });
  };

  const notificationSubcribeSetting = (data) => {
    setState({
      notification_subsrcibe: data,
    });
  };

  const setTopicsInNotificationSettings = (data) => {
    // const loginUserId = localStorage.getItem("userId");
    // const following_topics = get(data, "following_topics", []);
    // if (following_topics.length > 0) {
    //   setState({
    //     iFollowingTopics: filter(following_topics, o => {
    //       return o.created_by_id !== loginUserId;
    //     }),
    //     iCreatedTopics: filter(following_topics, o => {
    //       return o.created_by_id == loginUserId;
    //     })
    //   });
    // }

    setState({
      iFollowingTopics: get(data, "topics_you_follow", []),
      iCreatedTopics: get(data, "topics_created_by_you", []),
    });
  };

  const setTopicsMuteUnmuteNotificationSettings = (isMute, switchKey) => {
    const { id, key } = switchKey;
    let iFollowingTopics = state.iFollowingTopics;
    let iCreatedTopics = state.iCreatedTopics;
    if (isMute) {
      if (key === "following") {
        const index = findIndex(iFollowingTopics, (o) => {
          return o.id === id;
        });
        // console.log("NN FF  index =>", index);
        iFollowingTopics[index].mute_topic = true;
        setState({
          iFollowingTopics: iFollowingTopics,
        });
      }
      if (key === "created") {
        const index = findIndex(iCreatedTopics, (o) => {
          return o.id === id;
        });
        // console.log("NN CC index =>", index);
        iCreatedTopics[index].mute_topic = true;
        setState({
          iCreatedTopics: iCreatedTopics,
        });
      }
    } else {
      if (key === "following") {
        const index = findIndex(iFollowingTopics, (o) => {
          return o.id === id;
        });
        iFollowingTopics[index].mute_topic = false;
        setState({
          iFollowingTopics: iFollowingTopics,
        });
      }
      if (key === "created") {
        const index = findIndex(iCreatedTopics, (o) => {
          return o.id === id;
        });
        iCreatedTopics[index].mute_topic = false;
        setState({
          iCreatedTopics: iCreatedTopics,
        });
      }
    }
  };

  const closeTopic = (topicId) => {
    setState({
      iCreatedTopics: filter(state.iCreatedTopics, (o) => {
        return o.id !== topicId;
      }),
    });
  };

  const unFollow = (data, topicId) => {
    const iFollowingTopics = state.iFollowingTopics;
    const obj = find(iFollowingTopics, (o) => {
      return o.id === topicId;
    });
    setState({ unDoTopic: obj });
    if (data === "") {
      setState({
        iFollowingTopics: filter(iFollowingTopics, (o) => {
          return o.id !== topicId;
        }),
      });
    } else {
      setState({
        iFollowingTopics: [state.unDoTopic, ...iFollowingTopics],
      });
    }
    // setState({
    //   iFollowingTopics: filter(state.iFollowingTopics, (o) => { return o.id !== topicId })
    // })
  };

  const setJobsInNotificationSettings = (data, isChange) => {
    const savedJobsList = state.saveJobs;
    if (isChange) {
      if (data.job_search) {
        let index = findIndex(savedJobsList, (o) => {
          return o.id === data.job_search.id;
        });
        savedJobsList[index].email_notification =
          data.job_search.email_notification;
        savedJobsList[index].push_notification =
          data.job_search.push_notification;
        savedJobsList[index].notification_frequency =
          data.job_search.notification_frequency;
        setState({
          saveJobs: savedJobsList,
        });
      }
    } else {
      setState({
        saveJobs: data.saved_jobs ? data.saved_jobs : [],
      });
    }
  };

  return {
    setNotifications,
    updateNotificationFilters,
    deleteNotification,
    setNotificationSettings,
    newNotificationSettings,
    setTopicsInNotificationSettings,
    setTopicsMuteUnmuteNotificationSettings,
    closeTopic,
    notificationSubcribeSetting,
    unFollow,
    setJobsInNotificationSettings,
    ...state,
  };
};

export default useNotification;

import React, { memo, useEffect } from "react";

import { useAppState } from "../../../../context";
import {
  scrollToActiveConversation,
  chatFilterOptions,
} from "../../../../utils/common";
import { createModuleStyleExtractor } from "../../../../utils/css";
import ChatUsersListItem from "../ChatUsersListItem/ChatUsersListItem";
import { readChatNotification } from "../../../../services/notificationServices";
import usePersistState from "../../../../state/usePersistState";
import styles from "./ChatUsersList.module.scss";

const cx = createModuleStyleExtractor(styles);

const ChatUsersList = () => {
  const {
    chats,
    activeConversation,
    previousActiveConversation,
    setTriggerReloadUnreadCountUpdate,
    setPreviousActiveConversation,
    chatFilterTab,
    setChatFilterTabAndReloadChats,
  } = useAppState("chat");
  const {
    chat_specific_notifcations_count,
    readChatSpecificNotification,
    users,
  } = usePersistState();
  const { admission_user_type } = users || {};

  useEffect(() => {
    return () => {
      setPreviousActiveConversation(-1);
    };
  }, []);

  useEffect(() => {
    if (activeConversation !== -1) {
      scrollToActiveConversation(activeConversation);
      setTriggerReloadUnreadCountUpdate(true);
    }
  }, [activeConversation, chats]);

  // Read chat notification upon conversation change
  useEffect(() => {
    (async () => {
      // Check if previous active conversation has notification, if yes then call api and update presist state
      const previousIndex = chat_specific_notifcations_count.findIndex(
        (notification) => notification[0] === previousActiveConversation
      );
      if (
        previousIndex !== -1 &&
        chat_specific_notifcations_count[previousIndex][1] > 0
      ) {
        const response = await readChatNotification(previousActiveConversation);
        if (response?.success) {
          const { chat_notifcations_count, unread_notification_count } =
            response.data ?? {};
          readChatSpecificNotification(
            previousIndex,
            chat_notifcations_count,
            unread_notification_count
          );
        } else {
          console.error(response);
        }
      }
      // Check if current active conversation has notification, if yes then call api and update presist state
      const currentIndex = chat_specific_notifcations_count.findIndex(
        (notification) => notification[0] === activeConversation
      );
      if (
        currentIndex !== -1 &&
        chat_specific_notifcations_count[currentIndex][1] > 0
      ) {
        const response = await readChatNotification(activeConversation);
        if (response?.success) {
          const { chat_notifcations_count, unread_notification_count } =
            response.data ?? {};
          readChatSpecificNotification(
            currentIndex,
            chat_notifcations_count,
            unread_notification_count
          );
        } else {
          console.error(response);
        }
      }
    })();
  }, [activeConversation]);

  return (
    <div className={cx("contacts-list")} id={"chat-contacts-list__interstride"}>
      {admission_user_type && (
        <div className={cx("contacts-list__filters")}>
          <div
            className={cx([
              "contacts-list__filters__item",
              chatFilterTab === chatFilterOptions[0]
                ? "contacts-list__filters__item--active"
                : "",
            ])}
            onClick={() => setChatFilterTabAndReloadChats(chatFilterOptions[0])}
          >
            <span>All</span>
          </div>
          <div
            className={cx([
              "contacts-list__filters__item",
              chatFilterTab === chatFilterOptions[1]
                ? "contacts-list__filters__item--active"
                : "",
            ])}
            onClick={() => setChatFilterTabAndReloadChats(chatFilterOptions[1])}
          >
            <span>Student</span>
          </div>
          <div
            className={cx([
              "contacts-list__filters__item",
              chatFilterTab === chatFilterOptions[2]
                ? "contacts-list__filters__item--active"
                : "",
            ])}
            onClick={() => setChatFilterTabAndReloadChats(chatFilterOptions[2])}
          >
            <span>Admissions</span>
          </div>
        </div>
      )}
      {!chats || chats?.length === 0 ? (
        <div className="facelift-empty-boxwrap">
          <h3>It looks like you have not sent or received any messages.</h3>
          <p>
            Click on Community to start a new message with students, alumni, and
            admissions staff.
          </p>
        </div>
      ) : (
        chats?.map((user, index) => (
          <ChatUsersListItem key={user?.conversation_id || index} user={user} />
        ))
      )}
    </div>
  );
};
export default memo(ChatUsersList);

import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import { routes } from "../../routes";
import Discover from "./Discover/Discover";
import Messenger from "./Messenger/Messenger";
import { useInitChat } from "../../hooks/useInitChat";
import { useChatsList } from "../../hooks/useChatsList";
import { useAppState } from "../../context";
import AllTopics from "../Topics/AllTopics/AllTopics";
import usePersistState from "../../state/usePersistState";

import MyTopicsIcon from "../../assets/svg/myTopicsIcon.svg";
import DiscoverIcon from "../../assets/FocusIcons/MenuIcons/Network/DiscoverNew.svg";
import MyTopicsFocusIcon from "../../assets/FocusIcons/MenuIcons/Network/MyTopics.svg";
import SavedNetwork from "./SavedNetwork/SavedNetwork";
import NewMessage from "./NewMessage/NewMessage";
import NewGroup from "./NewGroup/NewGroup";
import NewTopic from "./NewTopic/NewTopic";
import ParticipantsListV2 from "./ParticipantsListV2/ParticipantsListV2";

const Network = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { permissions = {}, topics_notifcations_count } = usePersistState();
  const { setTriggerReloadUnreadCountUpdate } = useAppState("chat");
  const { handleCommunitySubHeaderClick } = useAppState("topic");
  useInitChat();
  useChatsList();

  useEffect(() => {
    if (
      ((location.pathname.includes(routes.NETWORK.MESSAGES) ||
        location.pathname.includes(routes.NETWORK.MESSENGEN) ||
        location.pathname.includes(routes.NETWORK.DISCOVER)) &&
        !permissions["chat"]) ||
      (location.pathname.includes(routes.NETWORK.TOPICS) &&
        !permissions["topics"])
    )
      navigate(routes.DASHBOARD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  const tabs = [
    ...(permissions["topics"]
      ? [
          {
            title: "Community",
            toolTip: "Join cohorts within the broader Interstride community.",
            avatar: MyTopicsIcon,
            focusAvatar: MyTopicsFocusIcon,
            navigateURL: routes.NETWORK.TOPICS,
            navigateSideEffect: () => {
              handleCommunitySubHeaderClick();
            },
            isActive: location.pathname === routes.NETWORK.TOPICS,
            hasNotificationBadge: true,
            count: topics_notifcations_count,
          },
        ]
      : []),
    ...(permissions["chat"]
      ? [
          {
            title: "Discover Network",
            toolTip:
              "Find peers and alumni from your school who share similar background and interest.",
            avatar: DiscoverIcon,
            focusAvatar: DiscoverIcon,
            navigateURL: routes.NETWORK.DISCOVER,
            isActive: location.pathname === routes.NETWORK.DISCOVER,
          },
        ]
      : []),
  ];

  useEffect(() => {
    setTriggerReloadUnreadCountUpdate(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout
      title="Network Dashboard"
      withSubHeader={
        window.location.pathname.includes("messenger") ? false : true
      }
      tabs={tabs}
      location={location}
      viewOnlyNavbar
      containerClassName={
        location.pathname === "/network/discover-topics"
          ? "discover-network-main-container"
          : ""
      }
    >
      <Routes>
        {permissions["chat"] && (
          <>
            <Route path="/discover" element={<Discover />} />
            <Route path="/discover/saved-network" element={<SavedNetwork />} />
            <Route path="/messenger" element={<Messenger />} />
            <Route path="/messenger/new-message" element={<NewMessage />} />
            <Route path="/messenger/new-group" element={<NewGroup />} />
          </>
        )}
        {permissions["topics"] && (
          <>
            <Route path="/topics" element={<AllTopics />} />
            <Route path="/topics/new-topic" element={<NewTopic />} />
            <Route
              path="/topics/participants-list"
              element={<ParticipantsListV2 />}
            />
          </>
        )}
      </Routes>
    </MainLayout>
  );
};

export default Network;

import React, { useEffect, useState } from "react";
import _find from "lodash/find";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";

import Container from "../../../components/Container/Container";
import DiscoverFilter from "../../../components/NetworkDashboard/Discover/DiscoverFilter/DiscoverFilter";
import DiscoverPeople from "../../../components/NetworkDashboard/Discover/DiscoverPeople/DiscoverPeople";
import DiscoverStyles from "./DiscoverStyles";
import { useAppState } from "../../../context";
import usePromise from "../../../hooks/usePromise/usePromise";
import usePersistState from "../../../state/usePersistState";
import { toastify, getCountryListFromAPI } from "../../../helper/helper";
import { routes } from "../../../routes";
import { createFreshChatPreview } from "../../../utils/common";
import RecommendationsDetails from "../../../components/NetworkDashboard/RecommendationsDetails/RecommendationsDetails";
import SortByCustom from "../../../components/SortByCustom/SortByCustom";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import SavedPostsWidget from "../../../components/SavedPostWidget/SavedPostWidget";
import {
  getFavoriteNetworksAPI,
  getNetworkDiscoverFiltersAPI,
  getDiscoverNetWorkPeopleAPI,
  favorite_Unfavorite_NetworksAPI,
  getRecommendedNetworksAPI,
} from "../../../services/networkServices";

import FilterIcon from "../../../assets/svg/filter-Icon.svg";
import FilledFilterIcon from "../../../assets/svg/filled-filter-Icon.svg";

const Discover = () => {
  const navigate = useNavigate();
  let query = new URLSearchParams(useLocation().search);
  let countryParam = query.get("country");

  const { setFavoriteUsers } = usePersistState();

  const [activePanelIndex, setActivePanelIndex] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [openFilter, toggleFilter] = useState(false);
  const [sortBy, setSortBy] = useState("recommended");
  const USER_ID = new URLSearchParams(window.location.search).get("user_id");
  const [selectedUserData, setSelectedUserData] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({
    search: "",
    country: "",
    school: "",
    account_type: "",
    degree: "",
    major: "",
    graduation_year: "",
    interest: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const [
    callingGetNetworkDiscoverFiltersAPI,
    refreshGetNetworkDiscoverFiltersAPI,
  ] = usePromise(getNetworkDiscoverFiltersAPI);
  const [
    callingGetDiscoverNetWorkPeopleAPI,
    refreshGetDiscoverNetWorkPeopleAPI,
  ] = usePromise(getDiscoverNetWorkPeopleAPI);

  const [
    callingGetRecommendedNetWorkPeopleAPI,
    refreshGetRecommendedNetWorkPeopleAPI,
  ] = usePromise(getRecommendedNetworksAPI);

  const [callingGetFavoriteNetworksAPI, refreshGetFavoriteNetworksAPI] =
    usePromise(getFavoriteNetworksAPI);
  const [
    callingFavorite_Unfavorite_NetworksAPI,
    refreshFavorite_Unfavorite_NetworksAPI,
  ] = usePromise(favorite_Unfavorite_NetworksAPI);

  const {
    setNetwokDiscoverOptions,
    setDiscoverNetworks,
    discoverNetworks,
    setUserDetails,
    recommandedUserDetails,
    shouldClearDiscoverFilter,
    setShouldClearDiscoverFilter,
  } = useAppState("network");

  const sortByOptions = [
    { value: "name", label: "Name" },
    { value: "recommended", label: "Recommended" },
  ];
  const {
    getChatContactListItem,
    getChatPreviewByReceiverId,
    chats,
    setChats,
    setConversationInformation,
    setCurrentConversationId,
    createFreshChatRecord,
    favoriteUsersCount,
    setFavoriteUsersCount,
  } = useAppState("chat");
  useEffect(() => {
    /**
     * You can only pass a normal function as argument to useEffect,
     *  and not an async function. In order to use async await
     *  in useEffect, you can write your function as
     * an IIFE (Immediately Invoked Function Expression
     */
    (async () => {
      const COUNTRY_OPTIONS = await getCountryListFromAPI();
      if (countryParam) {
        const selectedCountry = COUNTRY_OPTIONS.find(
          ({ code = "" }) => code === countryParam
        );
        if (selectedCountry) {
          setCurrentFilters({
            ...currentFilters,
            country: selectedCountry.label,
          });
          applyFilter({ country: selectedCountry.label });
        } else applyFilter();
      } else applyFilter({ country: countryParam ?? "" });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryParam]);

  useEffect(() => {
    if (activePanelIndex && !showViewModal) {
      const DetailPanel = document.getElementById("detail-panel");
      if (DetailPanel) {
        DetailPanel.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePanelIndex]);

  useEffect(() => {
    if (shouldClearDiscoverFilter) {
      clearFilters();
      setShouldClearDiscoverFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClearDiscoverFilter]);

  const clearFilters = () => {
    setCurrentFilters({
      country: "",
      school: "",
      account_type: "",
      degree: "",
      major: "",
      graduation_year: "",
      interest: "",
    });
    setTimeout(() => {
      applyFilter({
        country: "",
        school: "",
        degree: "",
        account_type: "",
        major: "",
        graduation_year: "",
        interest: "",
      });
    }, 1000);
  };

  const handleView = (e, index) => {
    if (e) e.stopPropagation();

    setActivePanelIndex(index);
    if (selectedUserData) {
      setSelectedUserData(false);
    }
    // refreshGetUserDetailsAPI(index)
    setUserDetails(_find(discoverNetworks, { id: index }));
    if (window.screen.width <= 1366) {
      setShowViewModal(true);
    }
  };

  const handleClose = (e) => {
    if (e) e.stopPropagation();
    setActivePanelIndex(null);
    setShowViewModal(false);
  };

  const startChatWithNewUser = (e, item) => {
    try {
      const { id } = item;
      const user = getChatContactListItem(id, discoverNetworks);
      const chat_preview = getChatPreviewByReceiverId(id);
      if (chat_preview) {
        setCurrentConversationId(chat_preview.conversation_id);
        // redirect to chat
        navigate("/network/messenger", { state: { userid: id } });
        return;
      }

      const randomConversationId = new Date().getTime();
      const { preview, conversation } = createFreshChatPreview(
        user,
        randomConversationId
      );
      setChats([preview, ...chats]);
      setConversationInformation(randomConversationId, conversation);
      setCurrentConversationId(randomConversationId);
      createFreshChatRecord(randomConversationId); // we will track by this id
      //redirect to chat page
      navigate("/network/messenger", { state: { userid: id } });
    } catch (error) {
      toastify(
        "error",
        "Something went wrong. Please try again later or contact us for support."
      );
    }
  };

  const handleFavorite = (e, userId) => {
    if (e) e.stopPropagation();
    refreshFavorite_Unfavorite_NetworksAPI(userId);
  };

  useEffect(() => {
    refreshGetNetworkDiscoverFiltersAPI();
    refreshGetFavoriteNetworksAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (USER_ID && USER_ID != null) {
      setSelectedUserData(true);
      let btn = window.document.getElementById(USER_ID);
      if (btn && btn != null) {
        window.scrollTo({ top: btn, behavior: "smooth" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discoverNetworks]);

  useEffect(() => {
    if (
      callingGetNetworkDiscoverFiltersAPI.hasFetched() &&
      callingGetNetworkDiscoverFiltersAPI.hasErrors() === false
    ) {
      setNetwokDiscoverOptions(callingGetNetworkDiscoverFiltersAPI.data().data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetNetworkDiscoverFiltersAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetDiscoverNetWorkPeopleAPI.hasFetched() &&
      callingGetDiscoverNetWorkPeopleAPI.hasErrors() === false
    ) {
      const {
        networks_list = [],
        current_page,
        total_pages,
      } = callingGetDiscoverNetWorkPeopleAPI.data()?.data;

      const _discoverNetworks =
        current_page === 1
          ? [...networks_list]
          : [...discoverNetworks, ...networks_list];

      setDiscoverNetworks(_discoverNetworks);
      setTotalPages(total_pages);
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetDiscoverNetWorkPeopleAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetRecommendedNetWorkPeopleAPI.hasFetched() &&
      callingGetRecommendedNetWorkPeopleAPI.hasErrors() === false
    ) {
      const {
        recommended_network = [],
        current_page,
        total_pages,
      } = callingGetRecommendedNetWorkPeopleAPI.data()?.data;

      const _discoverNetworks =
        current_page === 1
          ? [...recommended_network]
          : [...discoverNetworks, ...recommended_network];

      setDiscoverNetworks(_discoverNetworks);
      setTotalPages(total_pages);
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetRecommendedNetWorkPeopleAPI.isFetching()]);

  useEffect(() => {
    if (
      callingFavorite_Unfavorite_NetworksAPI.hasFetched() &&
      callingFavorite_Unfavorite_NetworksAPI.hasErrors() === false
    ) {
      toastify(
        "success",
        callingFavorite_Unfavorite_NetworksAPI.data().message
      );
      refreshGetFavoriteNetworksAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingFavorite_Unfavorite_NetworksAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetFavoriteNetworksAPI.hasFetched() &&
      callingGetFavoriteNetworksAPI.hasErrors() === false
    ) {
      const { favourite_list = [] } = callingGetFavoriteNetworksAPI.data().data;
      setFavoriteUsersCount(favourite_list.length);
      setFavoriteUsers(favourite_list.map(({ id }) => id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetFavoriteNetworksAPI.isFetching()]);

  const applyFilter = (data = {}, sort = sortBy) => {
    toggleFilter(false);
    setShowLoader(true);

    if (sort === "name") {
      refreshGetDiscoverNetWorkPeopleAPI({
        ...currentFilters,
        ...data,
        page: 1,
      });
    } else {
      refreshGetRecommendedNetWorkPeopleAPI({
        ...currentFilters,
        ...data,
        page: 1,
      });
    }
    setCurrentFilters({
      ...currentFilters,
      ...data,
    });
    setActivePanelIndex(null);
    setCurrentPage(1);
  };

  const getPaginationData = () => {
    const nextPage = currentPage + 1;
    if (sortBy === "name") {
      refreshGetDiscoverNetWorkPeopleAPI({
        ...currentFilters,
        page: nextPage,
      });
    } else {
      refreshGetRecommendedNetWorkPeopleAPI({
        ...currentFilters,
        page: nextPage,
      });
    }
    setCurrentPage(nextPage);
  };

  return (
    <Container>
      <DiscoverStyles>
        <div className="discover-network-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div className={`collapsible-view ${openFilter ? "open" : ""}`}>
                <h2 className="title-primary mb-15">
                  Search & filter
                  <a
                    className="ngray-link"
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      clearFilters();
                    }}
                  >
                    Clear all
                  </a>
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleFilter(!openFilter);
                    }}
                    className="collapse-icon"
                  >
                    {openFilter ? (
                      <img src={FilledFilterIcon} alt="Filter Icon" />
                    ) : (
                      <img src={FilterIcon} alt="Filter Icon" />
                    )}
                  </a>
                </h2>
                <div className="search-card">
                  <DiscoverFilter
                    applyFilter={applyFilter}
                    isFetching={showLoader}
                    currentFilters={currentFilters}
                    setCurrentFilters={setCurrentFilters}
                  />
                </div>
              </div>
              <div className="ShowResponsiveDiv">
                {
                  <div id="detail-panel">
                    {activePanelIndex && !showViewModal && (
                      <RecommendationsDetails
                        details={recommandedUserDetails}
                        handleClose={handleClose}
                        handleMessage={startChatWithNewUser}
                        handleFavorite={handleFavorite}
                      />
                    )}

                    <SavedPostsWidget
                      widgetText="Your saved network"
                      count={favoriteUsersCount || 0}
                      onClick={(e) => {
                        e.preventDefault();
                        if (favoriteUsersCount && favoriteUsersCount > 0) {
                          navigate(routes.NETWORK.SAVED_NETWORK);
                        }
                      }}
                    />
                  </div>
                }
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="title-boxs">
                <h2 className="title-primary">People</h2>
                {discoverNetworks.length > 0 && (
                  <div className="sort-by ml-auto">
                    <SortByCustom
                      name="topic"
                      label="Name"
                      showLabelAtTop={false}
                      justifyContent="left"
                      options={sortByOptions}
                      value={
                        sortBy !== null
                          ? sortByOptions.find(
                              (option) => option.value === sortBy
                            )
                          : ""
                      }
                      isSearchable={true}
                      onChange={(e) => {
                        applyFilter({}, e.value);
                        setSortBy(e.value);
                      }}
                    />
                  </div>
                )}
              </div>

              <DiscoverPeople
                networks={discoverNetworks}
                showLoader={showLoader}
                activePanelIndex={activePanelIndex}
                handleView={handleView}
                handleMessage={startChatWithNewUser}
                handleClose={handleClose}
                handleFavorite={handleFavorite}
                selectedUserData={selectedUserData}
                userId={USER_ID}
                currentPage={currentPage}
                totalPages={totalPages}
                fetchMoreData={getPaginationData}
              />
            </Grid>
            {
              <Grid item xs={3} id="detail-panel">
                {activePanelIndex && !showViewModal && (
                  <RecommendationsDetails
                    details={recommandedUserDetails}
                    handleClose={handleClose}
                    handleMessage={startChatWithNewUser}
                    handleFavorite={handleFavorite}
                  />
                )}

                <SavedPostsWidget
                  widgetText="Your saved network"
                  count={favoriteUsersCount || 0}
                  onClick={(e) => {
                    e.preventDefault();
                    if (favoriteUsersCount && favoriteUsersCount > 0) {
                      navigate(routes.NETWORK.SAVED_NETWORK);
                    }
                  }}
                />
              </Grid>
            }
          </Grid>

          {showViewModal && (
            <CustomDialog
              open={showViewModal}
              isDisplayCloseButton={false}
              className="mobile-profile-popup nstudent-card-modal"
              dialogTitleClass="modal-title"
              dialogSubTitleClass="text-para"
              width={"auto"}
            >
              <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
                <RecommendationsDetails
                  details={recommandedUserDetails}
                  handleClose={handleClose}
                  handleMessage={startChatWithNewUser}
                  handleFavorite={handleFavorite}
                />
              </div>
            </CustomDialog>
          )}
        </div>
      </DiscoverStyles>
    </Container>
  );
};

export default Discover;

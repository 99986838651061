import { isUndefined } from "lodash";
import _debounce from "lodash/debounce";
import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";

import EditableDropdown from "../../../../components/DropdownCustom/EditableDropdown";
import { useAppState } from "../../../../context";
import { formatOptions } from "../../../../helper/helper";
import { useFetchDepartments } from "../../../../hooks/Profile/useFetchDepartments";
import { fetchSchools } from "../../../../services/profile.service";
import usePersistState from "../../../../state/usePersistState";
import {
  createFriendlyTitle,
  getYears,
  months,
  validateGPA,
  years,
} from "../../../../utils/common";
import { createModuleStyleExtractor } from "../../../../utils/css";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import TextboxCustom from "../../../Textbox/TextboxCustom";
import styles from "./EducationItem.module.scss";

const cx = createModuleStyleExtractor(styles);
const date = new Date();

export const EducationItem = ({
  education,
  index,
  showAllErrors = false,
  departmentList,
  checkDepartment,
}) => {
  const {
    essentials,
    setLocalEducationFormInfo,
    schools,
    profile,
    personalForm,
    educationsForm,
    setLocalEducationForm,
  } = useAppState("profile");
  const { isProfileCompleted } = profile || {};
  const { account_type } = personalForm || {};
  const interstrideSelectedEducation = educationsForm[0];
  const { updatePersistState, users } = usePersistState();
  const {
    draftId,
    id,
    gpa,
    second_major,
    minor,
    graduation_date,
    major,
    degree,
    department,
    school_name,
    graduation_month,
    graduation_year,
    school_id,
  } = education || {};

  const { degrees, majors } = essentials || {};
  const currentMonth = date.getMonth();
  const [currentSchool, setCurrentSchool] = useState(school_id);
  const [schoolOptions, setSchoolOptions] = useState([]); // Used to populate school search data from API
  const [schoolOptionsLoading, setSchoolOptionsLoading] = useState(false); // Used in school options dropdown
  const [majorsList, setMajorsList] = useState([]);
  const [fieldsTouched, setFieldsTouched] = useState({
    school_name: false,
    department: false,
    major: false,
    degree: false,
    graduation_year: false,
    graduation_month: false,
  });

  useEffect(() => {
    const _educations = educationsForm.map((item) =>
      item.gpa === 0 && item.id === id
        ? {
            ...item,
            gpa: "",
          }
        : item
    );
    setLocalEducationForm(_educations);
  }, [gpa]);

  useEffect(() => {
    if (checkDepartment) {
      if (
        departmentList[currentSchool]?.length > 0 &&
        !isUndefined(departmentList[currentSchool]) &&
        !departmentList[currentSchool].some((item) => {
          return item.name === department;
        })
      ) {
        updateInformation("department", "");
        updatePersistState({
          users: {
            ...users,
            educations: [
              ...(users?.educations.map((item, index) =>
                index === 0 ? { ...item, department: "" } : item
              ) || []),
            ],
          },
        });
      }
    }
  }, [checkDepartment, departmentList[currentSchool]]);

  useEffect(() => {
    if (showAllErrors) {
      setFieldsTouched({
        school_name: true,
        department: true,
        major: true,
        degree: true,
        graduation_year: true,
        graduation_month: true,
      });
    }
  }, [showAllErrors]);

  useEffect(() => {
    const { majors } = essentials || {};
    setMajorsList(
      (majors ?? []).map((sub) => {
        return {
          label: sub,
          value: sub,
        };
      })
    );
  }, [majors]);

  useEffect(() => {
    setSchoolOptions(
      (schools ?? []).map((sub) => {
        return {
          ...sub,
          label: sub.name,
          value: sub.name,
        };
      })
    );
  }, [schools]);

  const callback = () => {
    updateInformation("school_id", currentSchool);
  };

  const { loading: departmentsLoading } = useFetchDepartments(
    currentSchool,
    callback
  );

  const getDefaultMonthValue = () => {
    if (graduation_month)
      return { label: graduation_month, value: graduation_month };
    // if we have graduation date
    if (graduation_date) {
      //if it is valid. means full formatted
      if (moment(graduation_date).isValid()) {
        return {
          label: moment(graduation_date).format("MMMM"),
          value: moment(graduation_date).format("MMMM"),
        };
      }
    }
    return "";
  };

  const getDefaultYearValue = () => {
    if (graduation_year)
      return { label: graduation_year, value: graduation_year };
    // if we have graduation date
    if (graduation_date) {
      //if it is valid. means full formatted
      if (moment(graduation_date).isValid()) {
        return {
          label: moment(graduation_date).format("YYYY"),
          value: moment(graduation_date).format("YYYY"),
        };
      }
    }
    return "";
  };

  const errors = useMemo(() => {
    let response = {};

    response["school_name"] = !school_name ? "School Required" : "";
    response["degree"] = !degree ? "Degree Required" : "";
    response["major"] = !major ? "Major Required" : "";

    if (departmentList[currentSchool]?.length > 0) {
      if (
        !departmentList[currentSchool]?.some((item) => {
          return item.name === department;
        }) ||
        isUndefined(department) ||
        !department
      ) {
        response["department"] = "Department Required";
      }
    }
    if (!getDefaultYearValue()) {
      response["graduation_year"] = "Year Required";
    } else {
      if (
        account_type === "alumni" &&
        Number(getDefaultYearValue()) > new Date().getFullYear() &&
        id === interstrideSelectedEducation?.id
      ) {
        response["graduation_year"] = `Invalid year`;
      } else if (
        account_type === "student" &&
        Number(getDefaultYearValue()) < new Date().getFullYear() &&
        id === interstrideSelectedEducation?.id
      ) {
        response["graduation_year"] = `Invalid year`;
      }
    }
    if (!getDefaultMonthValue().value) {
      response["graduation_month"] = "Month Required";
    } else {
      const selectedMonthIndex = months.indexOf(getDefaultMonthValue().value);
      if (
        Number(graduation_year) === new Date().getFullYear() &&
        ((account_type === "student" && selectedMonthIndex < currentMonth) ||
          (account_type === "alumni" && selectedMonthIndex >= currentMonth))
      ) {
        response["graduation_month"] = "Invalid month";
      }
    }

    return response;
  }, [education, personalForm, educationsForm, departmentList[currentSchool]]);

  const updateInformation = (key, value) => {
    let ID = null;
    ID = draftId ? draftId : id;
    setLocalEducationFormInfo(ID, key, value, draftId ? true : false);
  };

  const handleDynamicChangeSchoolDropdown = _debounce(async (value) => {
    try {
      setSchoolOptionsLoading(true);
      const schoolsListOptions = await fetchSchools(value);
      setSchoolOptions(
        (schoolsListOptions ?? [])?.map((sub) => {
          return {
            ...sub,
            label: sub.name,
            value: sub.name,
          };
        })
      );
      setSchoolOptionsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, 500);

  let departmentValue = {};
  if (!isUndefined(departmentList[currentSchool])) {
    departmentValue =
      department &&
      departmentList[currentSchool].some((item) => {
        return item.name === department;
      })
        ? { label: department, value: department }
        : { label: "", value: "" };
  }

  let formattedDepartments = [];
  if (!isUndefined(departmentList[currentSchool])) {
    formattedDepartments = departmentList[currentSchool].map((item) => {
      return {
        label: item.name,
        value: item.name,
      };
    });
  }

  return (
    <div
      className={cx([
        isProfileCompleted
          ? "profile-education__collapse"
          : "profile-education",
      ])}
    >
      <div className={cx("profile-education__grid")}>
        <div className={cx("profile-education__grid__row__drop-down")}>
          <EditableDropdown
            className={
              index === 0 || school_name ? "react-custom-dropdown__valid" : ""
            }
            name="School"
            label="School"
            isLoading={schoolOptionsLoading}
            placeholder={schoolOptionsLoading ? "Loading" : "Select School"}
            isDisabled={index === 0}
            addText="Add School"
            justifyContent="left"
            options={schoolOptions || []}
            required={true}
            error={errors["school_name"]}
            onFocus={() => {
              setFieldsTouched({ ...fieldsTouched, school_name: true });
            }}
            touched={fieldsTouched["school_name"]}
            value={
              schoolOptions
                ? schoolOptions.find((option) => option.value === school_name)
                  ? schoolOptions.find((option) => option.value === school_name)
                  : { label: school_name, value: school_name }
                : ""
            }
            onChange={(item) => {
              updateInformation(
                "school_name",
                item?.__isNew__ ? createFriendlyTitle(item?.value) : item?.value
              );
              setCurrentSchool(item?.id || 0);
            }}
            onInputChange={handleDynamicChangeSchoolDropdown}
          />
        </div>
        {index === 0 && departmentList[currentSchool]?.length > 0 && (
          <div className={cx("profile-education__grid__row__drop-down")}>
            <DropdownCustom
              name={`Department-${index}`}
              label="Department"
              options={formattedDepartments || []}
              onChange={(item) => {
                updateInformation("department", item?.value);
              }}
              isSearch
              required
              value={departmentValue}
              containerClassName={
                department ? "a-dropdown-container__options__valid" : ""
              }
              placeholder={departmentsLoading ? "loading" : "Select Department"}
              onFocus={() => {
                setFieldsTouched({ ...fieldsTouched, department: true });
              }}
              touched={fieldsTouched["department"]}
              error={errors["department"]}
              className={`profile-react-custom-dropdown ${
                department ? "border-gray" : ""
              }`}
              labelClass="justify-left"
              justifyContent="left"
              placeholderColor={true}
            />
          </div>
        )}
        <div className={cx("profile-education__grid__row__drop-down")}>
          <DropdownCustom
            name={`Degree-${index}`}
            label="Degree"
            placeholder="Select Degree"
            options={formatOptions(degrees) || []}
            onChange={(item) => {
              updateInformation("degree", item?.value);
            }}
            isSearch
            required
            value={
              degree
                ? { label: degree, value: degree }
                : { label: "", value: "" }
            }
            containerClassName={
              degree ? "a-dropdown-container__options__valid" : ""
            }
            error={errors["degree"]}
            onFocus={() => {
              setFieldsTouched({ ...fieldsTouched, degree: true });
            }}
            touched={fieldsTouched["degree"]}
            className={`profile-react-custom-dropdown ${
              degree ? "border-gray" : ""
            }`}
            labelClass="justify-left"
            justifyContent="left"
            placeholderColor={true}
          />
        </div>
        <div className={cx("profile-education__grid__row__drop-down")}>
          <EditableDropdown
            className={major ? "react-custom-dropdown__valid" : ""}
            name={`Major-${index}`}
            label="Major"
            placeholder="Select Major"
            addText="Add Major"
            justifyContent="left"
            options={majorsList}
            required={true}
            error={errors["major"]}
            onFocus={() => {
              setFieldsTouched({ ...fieldsTouched, major: true });
            }}
            touched={fieldsTouched["major"]}
            value={
              majorsList
                ? majorsList.find((option) => option.value === major)
                  ? majorsList.find((option) => option.value === major)
                  : { label: major, value: major }
                : ""
            }
            onChange={(item) =>
              updateInformation(
                "major",
                item?.__isNew__ ? createFriendlyTitle(item?.value) : item?.value
              )
            }
          />
        </div>

        <div className={cx("profile-education__grid__row__drop-down")}>
          <EditableDropdown
            className={second_major ? "react-custom-dropdown__valid" : ""}
            name="Second Major"
            label="Second major"
            placeholder="Select Major"
            addText="Add Second Major"
            justifyContent="left"
            options={majorsList}
            required={false}
            value={
              majorsList
                ? majorsList.find((option) => option.value === second_major)
                  ? majorsList.find((option) => option.value === second_major)
                  : { label: second_major, value: second_major }
                : ""
            }
            onChange={(item) =>
              updateInformation(
                "second_major",
                item?.__isNew__ ? createFriendlyTitle(item?.value) : item?.value
              )
            }
          />
        </div>

        <div className={cx("profile-education__grid__row__drop-down")}>
          <EditableDropdown
            className={minor ? "react-custom-dropdown__valid" : ""}
            name="Minor"
            label="Minor"
            placeholder="Select Minor"
            addText="Add Minor"
            justifyContent="left"
            options={majorsList}
            required={false}
            value={
              majorsList
                ? majorsList.find((option) => option.value === minor)
                  ? majorsList.find((option) => option.value === minor)
                  : { label: minor, value: minor }
                : ""
            }
            onChange={(item) =>
              updateInformation(
                "minor",
                item?.__isNew__ ? createFriendlyTitle(item?.value) : item?.value
              )
            }
          />
        </div>

        <div className={cx("profile-education__grid__row__drop-down-grid")}>
          <div>
            <DropdownCustom
              name={`Graduation date-${index}`}
              label="Graduation date"
              placeholder="Month"
              options={formatOptions(months) || []}
              onChange={(item) => {
                updateInformation("graduation_month", item?.value);
              }}
              isSearch
              required
              value={getDefaultMonthValue()}
              containerClassName={
                getDefaultMonthValue().value
                  ? "a-dropdown-container__options__valid"
                  : ""
              }
              error={errors["graduation_month"]}
              touched={fieldsTouched["graduation_month"]}
              onFocus={() => {
                setFieldsTouched({ ...fieldsTouched, graduation_month: true });
              }}
              className={`profile-react-custom-dropdown ${
                graduation_month ? "border-gray" : ""
              }`}
              labelClass="justify-left"
              justifyContent="left"
              placeholderColor={true}
            />
          </div>
          <div>
            <DropdownCustom
              name={`Graduation Year-${index}`}
              label="Graduation Year"
              placeholder="Year"
              options={
                index === 0
                  ? formatOptions(getYears(account_type))
                  : formatOptions(years) || []
              }
              onChange={(item) => {
                updateInformation("graduation_year", item?.value);
              }}
              isSearch
              required
              showStarIcon={false}
              value={getDefaultYearValue()}
              containerClassName={
                getDefaultYearValue().value
                  ? "a-dropdown-container__options__valid"
                  : ""
              }
              error={errors["graduation_year"]}
              touched={fieldsTouched["graduation_year"]}
              onFocus={() => {
                setFieldsTouched({ ...fieldsTouched, graduation_year: true });
              }}
              className={`profile-react-custom-dropdown ${
                graduation_year ? "border-gray" : ""
              }`}
              labelClass="label-hidden"
              justifyContent="left"
              placeholderColor={true}
            />
          </div>
        </div>
        <div className={cx("profile-education__grid__row__inputs")}>
          <TextboxCustom
            label="GPA"
            name={`GPA-${index}`}
            placeholder="Enter your GPA"
            className={
              gpa
                ? "a-input-profile__completed custom-textbox"
                : "a-input-profile custom-textbox"
            }
            lableaignment="left"
            value={gpa && gpa !== 0 ? `${gpa}` : ""}
            onChange={(e) => {
              const isValidated = validateGPA(e.target.value);
              if (isValidated) {
                updateInformation("gpa", e.target.value);
              }
            }}
            required
            error={gpa && !validateGPA(gpa)}
            errorText={gpa && !validateGPA(gpa) ? "Invalid GPA" : ""}
          />
        </div>
      </div>
      <br />
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";

import CompanyDetailStyles from "./CompanyDetailStyles";
import SearchResult from "../../../../components/Jobs/Visa/SearchResult/SearchResult";
import CompanyInfo from "../../../../components/Jobs/Visa/CompanyInfo/CompanyInfo";
import FilterPositions from "../../../../components/Jobs/Visa/FilterPositions/FilterPositions";
import {
  updateFavouriteCompanyAPI,
  getSavedCompaniesAPI,
} from "../../../../services/JobServices";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { toastify } from "../../../../helper/helper";
import usePersistState from "../../../../state/usePersistState";
import SelectiveSavedCompanies from "../../../../components/Jobs/Visa/SelectiveSavedCompanies/SelectiveSavedCompanies";

const CompanyDetail = () => {
  const { appliedVisaFilters = {} } = usePersistState();
  let query = new URLSearchParams(useLocation().search);
  let saved = query.get("saved");

  const showPetition =
    appliedVisaFilters.visa &&
    (appliedVisaFilters.visa === "h1b" ||
      appliedVisaFilters.visa === "greencard");

  const [activeTab, setActiveTab] = useState(
    showPetition ? "petition" : "position"
  );
  const [positionFilters, setPositionFilters] = useState({
    country: "us",
  });
  const [refreshJobs, setRefreshJobs] = useState(1);
  const [savedCompaniesList, setSavedCompaniesList] = useState([]);
  const [callSavedCompaniesAPI, refreshSavedCompaniesAPI] =
    usePromise(getSavedCompaniesAPI);

  const [callingUpdateFavouriteCompanyAPI, refreshUpdateFavouriteCompanyAPI] =
    usePromise(updateFavouriteCompanyAPI);

  useEffect(() => {
    refreshSavedCompaniesAPI();
  }, []);

  useEffect(() => {
    if (
      callingUpdateFavouriteCompanyAPI.hasFetched() &&
      callingUpdateFavouriteCompanyAPI.hasErrors() === false
    ) {
      refreshSavedCompaniesAPI();
      toastify("success", callingUpdateFavouriteCompanyAPI.data().message);
    }
  }, [callingUpdateFavouriteCompanyAPI.isFetching()]);

  useEffect(() => {
    if (
      callSavedCompaniesAPI.hasFetched() &&
      callSavedCompaniesAPI.hasErrors() === false
    ) {
      if (callSavedCompaniesAPI.data() && callSavedCompaniesAPI.data().data) {
        setSavedCompaniesList(
          callSavedCompaniesAPI.data().data.favorite_companies
        );
      }
    }
  }, [callSavedCompaniesAPI.isFetching()]);

  const updateFavourites = ({ company_id, tab_type, employer_name, like }) => {
    refreshUpdateFavouriteCompanyAPI({
      tab_type:
        tab_type && like === "unlike" ? tab_type : appliedVisaFilters.visa,
      company_id,
      company_name: employer_name,
      like,
    });
  };

  useEffect(() => {
    if (activeTab === "petition")
      setPositionFilters({
        country: "us",
      });
  }, [activeTab]);

  return (
    <CompanyDetailStyles>
      <div className="company-mainpage">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            {activeTab === "petition" ? (
              <>
                {saved ? (
                  <SelectiveSavedCompanies
                    back2={"searchList"}
                    savedCompaniesList={savedCompaniesList}
                    updateFavourites={updateFavourites}
                  />
                ) : (
                  <SearchResult
                    back2={"searchList"}
                    savedCompaniesList={savedCompaniesList}
                    updateFavourites={updateFavourites}
                    fixedHeight
                  />
                )}
              </>
            ) : (
              <FilterPositions
                filter={positionFilters}
                setFilter={(changedFilters) => {
                  setPositionFilters({
                    ...positionFilters,
                    ...changedFilters,
                  });
                }}
                applyFilters={() => setRefreshJobs(refreshJobs + 1)}
                onClearAllClick={() => {
                  setPositionFilters({
                    search: "",
                    state: "",
                    city: "",
                    job_type: "",
                    publish_date: "",
                    country: "us",
                  });
                  setRefreshJobs(refreshJobs + 1);
                }}
              />
            )}
          </Grid>
          <Grid item xs={9}>
            <CompanyInfo
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              filters={positionFilters}
              refreshJobs={refreshJobs}
              savedCompaniesList={savedCompaniesList}
              updateFavourites={updateFavourites}
              showPetition={showPetition}
            />
          </Grid>
        </Grid>
      </div>
      {/* </MainLayout> */}
    </CompanyDetailStyles>
  );
};
export default CompanyDetail;

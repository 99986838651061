import React, { useState, useEffect } from "react";
import { Grid, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

import SavedJobsStyles from "./SavedJobsStyles";
import CardCustom from "../../CardCustom/CardCustom";
import usePromise from "../../../hooks/usePromise/usePromise";
import {
  getSavedJobsAPI,
  updateFavouriteJobsAPI,
} from "../../../services/JobServices";
import SortByCustom from "../../../components/SortByCustom/SortByCustom";
import {
  toastify,
  capitalizeFirstLetter,
  processJobLocationTime,
  extractUniqueAndGroupJobs,
} from "../../../helper/helper";
import InitialAvatar from "../../../components/InitialAvatar/InitialAvatar";
import JobsListingEffect from "../../effects/JobsListingEffect/JobsListingEffect";
import { routes } from "../../../routes";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import usePersistState from "../../../state/usePersistState";
import CustomDialog from "../../CustomDialog/CustomDialog";
import { useSavedJobs } from "../../../hooks/useSavedJobs";

import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";
import StarIcon from "../../StarIcon/StarIcon";

const SaveSearchList = () => {
  const navigate = useNavigate();
  const { users } = usePersistState();
  const { id: userId } = users || {};
  const [savedJobsList, setSavedJobsList] = useState([]);
  const [savedJobsListGrouped, setSavedJobsListGrouped] = useState({});
  const [callSavedJobsAPI, refreshSavedJobsAPI] = usePromise(getSavedJobsAPI);
  const [sortBy, setSortBy] = useState("date");
  const [viewGroupJobs, setViewGroupJobs] = useState({
    open: false,
    parentId: null,
  });

  const [callingUpdateFavouriteJobsAPI, refreshUpdateFavouriteJobsAPI] =
    usePromise(updateFavouriteJobsAPI);

  const { setSavedJobs = () => {} } = useSavedJobs();

  useEffect(() => {
    refreshSavedJobsAPI(sortBy);
  }, []);

  useEffect(() => {
    if (
      callSavedJobsAPI.hasFetched() &&
      callSavedJobsAPI.hasErrors() === false
    ) {
      if (
        callSavedJobsAPI.data() &&
        callSavedJobsAPI.data().data &&
        callSavedJobsAPI.data().data.favorite_jobs
      ) {
        const { favorite_jobs = [] } = callSavedJobsAPI.data().data;

        const { uniqueJobs, grouped_state_jobs } = extractUniqueAndGroupJobs(
          favorite_jobs,
          savedJobsListGrouped,
          "saved"
        );

        setSavedJobsList(uniqueJobs);
        setSavedJobsListGrouped(grouped_state_jobs);
        if (uniqueJobs?.length === 0) {
          navigate(routes.JOBS.SEARCH);
        }

        // Update saved jobs stored in state
        setSavedJobs(uniqueJobs);
      }
    }
  }, [callSavedJobsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingUpdateFavouriteJobsAPI.hasFetched() &&
      callingUpdateFavouriteJobsAPI.hasErrors() === false
    ) {
      toastify("success", callingUpdateFavouriteJobsAPI.data().message);
      refreshSavedJobsAPI(sortBy);
    }
  }, [callingUpdateFavouriteJobsAPI.isFetching()]);

  const updateSortBy = (newSortBy) => {
    setSortBy(newSortBy);
    refreshSavedJobsAPI(newSortBy);
  };

  const updateFavourites = (params) => {
    // Close modal and reset its state
    setViewGroupJobs({
      open: false,
      parentId: null,
    });
    // Reset saved group jobs
    setSavedJobsListGrouped({});
    // Refresh API call
    refreshUpdateFavouriteJobsAPI(params);
  };

  const sortByOptions = [
    { value: "relevance", label: "Relevance" },
    { value: "date", label: "Newest" },
  ];

  const handleCommonJobClick = (data, isGroupJob, redirectUrl, e) => {
    // No need to handle job card click on star icon click
    if (e?.target?.id?.includes("Star")) return;
    e?.preventDefault && e.preventDefault();

    if (isGroupJob) {
      setViewGroupJobs({
        open: true,
        parentId: data.job_title + data.company_name,
      });
    } else {
      window.open(redirectUrl);
    }
  };

  const renderJobsList = (data, i, isGroupJob) => {
    const {
      job_title = "",
      job_link = null,
      company_name = "",
      state = null,
      city = null,
      country = null,
      publish_date = null,
      is_favorite = true,
      permalink = null,
      is_employer = false,
    } = data;
    const accessibilityHelp = localStorage.getItem("accessibilityHelp");
    let websiteUrl = data.url;
    if (data.details === "url" && !/^https?:\/\//i.test(websiteUrl)) {
      websiteUrl = "http://" + websiteUrl;
    }
    const redirectUrl = job_link
      ? job_link
      : is_employer
      ? `/jobs/job-details/${permalink ?? ""}?type=featured`
      : "";
    return (
      <Grid item xs={12} key={i}>
        <CardCustom
          onClick={(e) =>
            handleCommonJobClick(data, isGroupJob, redirectUrl, e)
          }
        >
          <div className={`search-item ${is_employer ? "employer-job" : ""}`}>
            <div className="avtar">
              {data.logo && data.logo !== null ? (
                <>
                  {is_employer ? (
                    <Avatar variant="rounded" alt="" src={data.logo} />
                  ) : (
                    <img
                      alt="Avatar"
                      src={data.logo}
                      style={{
                        "border-radius": "4px",
                        height: "50px",
                        "max-width": "50px",
                      }}
                    />
                  )}
                </>
              ) : (
                <InitialAvatar
                  variant="rounded"
                  title={company_name}
                  index={i}
                  initialCount={1}
                />
              )}
            </div>
            <div className="content">
              <div className="header-primary">
                {capitalizeFirstLetter(job_title)}
              </div>
              <div className="sub-header-primary">{company_name}</div>
              {!isGroupJob && (
                <div className="text-grey">
                  {processJobLocationTime({
                    city,
                    state,
                    country,
                    time: publish_date,
                  })}
                </div>
              )}
            </div>
            <div
              className={`card-actions-wrapper ${
                accessibilityHelp == "true" ? "show-actions" : ""
              }`}
            >
              {isGroupJob ? (
                <>
                  <div>
                    <a
                      href="/#"
                      className="nlink-btn nbg-secondary"
                      aria-label={`Apply for ${job_title}`}
                      onClick={(e) => e.preventDefault()}
                    >
                      View
                    </a>
                  </div>
                  <div style={{ minWidth: "22px" }} />
                </>
              ) : (
                <>
                  <div>
                    <a
                      href={redirectUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="nlink-btn nbg-secondary"
                      aria-label={`Apply for ${job_title}`}
                      onClick={(e) => e.preventDefault()}
                    >
                      Apply
                    </a>
                  </div>
                  {is_favorite ? (
                    <StarIcon
                      aria-label={`Remove ${job_title} from saved jobs`}
                      variant="filled"
                      onClick={() =>
                        updateFavourites({
                          ...data,
                          userId,
                          like: "unlike",
                        })
                      }
                    />
                  ) : (
                    <StarIcon
                      aria-label={`Add ${job_title} to saved jobs`}
                      onClick={() =>
                        updateFavourites({
                          ...data,
                          userId,
                          job_id: data.job_key,
                          like: "like",
                        })
                      }
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </CardCustom>
      </Grid>
    );
  };

  return (
    <SavedJobsStyles>
      <div className="f-main-title-with-button mb-30">
        <div className="btn-wrapper-left">
          <ButtonCustom
            className="back-to-btn"
            onClick={() => navigate(routes.JOBS.SEARCH)}
          >
            <img src={BackArrow} width="21" alt="Back arrow" />
            Back to Jobs
          </ButtonCustom>
        </div>
        <h2 className="f-main-title mb-0 w-100">Saved Jobs</h2>
      </div>
      <div className={`saved-jobs-list`}>
        <div className="sort-by">
          <SortByCustom
            name="jobs"
            label="jobs"
            showLabelAtTop={false}
            justifyContent="left"
            options={sortByOptions}
            value={
              sortBy !== null
                ? sortByOptions.find((option) => option.value === sortBy)
                : ""
            }
            onChange={(e) => updateSortBy(e.value)}
          />
        </div>
        {callSavedJobsAPI.isFetching() ? (
          <JobsListingEffect count={5} />
        ) : (
          savedJobsList?.length > 0 &&
          savedJobsList.map((data, i) => {
            // Check if job belongs in a group
            const isGroupJob = Object.keys(savedJobsListGrouped).includes(
              data.job_title + data.company_name
            );
            return renderJobsList(data, i, isGroupJob);
          })
        )}
        {viewGroupJobs.open && (
          <CustomDialog
            open={viewGroupJobs.open}
            title="Multiple locations job"
            handleClose={() => {
              setViewGroupJobs({ open: false, parentId: null });
            }}
          >
            <div className="group-jobs-listing">
              <div className="search-list">
                {savedJobsListGrouped[viewGroupJobs?.parentId]?.map(
                  (groupJob, i) => renderJobsList(groupJob, i)
                )}
              </div>
            </div>
          </CustomDialog>
        )}
      </div>
    </SavedJobsStyles>
  );
};
export default SaveSearchList;

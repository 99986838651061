import React, { useState, useEffect } from "react";
import { sortBy } from "lodash";
import { Tooltip, Grid } from "@mui/material";

import TopEmployerGraph from "./Graphs/TopEmployerGraph/TopEmployerGraph";
import AlumniDegreesGraph from "./Graphs/AlumniDegreesGraph/AlumniDegreesGraph";
import AlumniMajorsGraph from "./Graphs/AlumniMajorsGraph/AlumniMajorsGraph";
import AlumniNationalityGraph from "./Graphs/AlumniNationalityGraph/AlumniNationalityGraph";
import EmploymentDataTable from "./EmploymentDataTable/EmploymentDataTable";
import {
  initialListingStateDataListing,
  initialStateDegreesGraph,
  initialStateMajorsGraph,
  initialStateNationalityGraph,
  initialStateTopEmployerGraph,
} from "./Utils/EmploymentDataConstants";
import Switch from "../../../components/atoms/Switch/Switch";
import usePersistState from "../../../state/usePersistState";
import { getFiltersData } from "../../../services/employmentDataService";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./EmploymentData.module.scss";

import InformationIcon from "../../../assets/svg/Information.svg";

const cx = createModuleStyleExtractor(styles);

export default function EmploymentData() {
  const [tab, setTab] = useState("left"); // "left" for Graphs and "right" for Data
  // Global shared filter data for all graphs and tables
  const [filters, setFilters] = useState({
    year: [],
    nationality: [],
    major: [],
    degree: [],
    employment_type: [],
    country: [],
  });
  // Data listing table state
  const [tableState, setTableState] = useState(initialListingStateDataListing);
  const [tableSearch, setTableSearch] = useState("");
  const [openFilterDataTable, toggleFilterDataTable] = useState(false);
  // Employer Graph state
  const [stateTopEmp, setStateTopEmp] = useState(initialStateTopEmployerGraph);
  const [openFilterTopEmp, toggleFilterTopEmp] = useState(false);
  // Degrees Graph state
  const [stateDegrees, setStateDegrees] = useState(initialStateDegreesGraph);
  const [openFilterDegrees, toggleFilterDegrees] = useState(false);
  // Nationality Graph state
  const [stateNationality, setStateNationality] = useState(
    initialStateNationalityGraph
  );
  const [openFilterNationality, toggleFilterNationality] = useState(false);
  // Majors Graph state
  const [stateMajors, setStateMajors] = useState(initialStateMajorsGraph);
  const [openFilterMajors, toggleFilterMajors] = useState(false);

  const { users } = usePersistState();

  // Call filters API to get data upon root component load / school change
  useEffect(() => {
    (async () => {
      if (!users?.school_id) return;

      // Get filter data
      const response = await getFiltersData({ school_id: users?.school_id });
      if (response?.success) {
        // Process options data
        let _year = [];
        let _nationality = [];
        let _major = [];
        let _degree = [];
        let _employment_type = [];
        let _country = [];
        if (response?.data?.year?.length > 0)
          response.data.year.forEach((element) =>
            _year.push({ value: element, label: element })
          );
        if (response?.data?.nationality?.length > 0)
          response.data.nationality.forEach((element) =>
            _nationality.push({ value: element, label: element })
          );
        if (response?.data?.major?.length > 0)
          response.data.major.forEach((element) =>
            _major.push({ value: element, label: element })
          );
        if (response?.data?.degree?.length > 0)
          response.data.degree.forEach((element) =>
            _degree.push({ value: element, label: element })
          );
        if (response?.data?.employment_type?.length > 0)
          response.data.employment_type.forEach((element) => {
            if (element === "CPT") {
              _employment_type.unshift({ value: element, label: element });
            } else {
              _employment_type.push({ value: element, label: element });
            }
          });
        if (response?.data?.country?.length > 0)
          response.data.country.forEach((element) =>
            _country.push({ value: element, label: element })
          );
        setFilters({
          year: sortBy(_year, "label"),
          nationality: sortBy(_nationality, "label"),
          major: sortBy(_major, "label"),
          degree: sortBy(_degree, "label"),
          employment_type: _employment_type,
          country: sortBy(_country, "label"),
        });
      }
    })();
  }, []);

  const processGraphClick = (coordinates, type, geoGraphStateRef = null) => {
    if (!coordinates?.label || !type) return;

    const filterData = { label: coordinates?.label, value: coordinates?.label };

    // Set search
    setTableSearch(type === "TopEmployer" ? coordinates.label : "");

    // Set table filters
    let _tableState = {
      ...initialListingStateDataListing,
    };
    if (type === "TopEmployer") {
      _tableState.selectedFilters = {
        ..._tableState.selectedFilters,
        ...stateTopEmp?.selectedFilters,
      };
    } else if (type === "AlumniDegrees") {
      _tableState.selectedFilters = {
        ..._tableState.selectedFilters,
        ...stateDegrees?.selectedFilters,
        degree: filterData,
      };
    } else if (type === "AlumniNationality") {
      _tableState.selectedFilters = {
        ..._tableState.selectedFilters,
        ...(geoGraphStateRef?.selectedFilters ||
          stateNationality?.selectedFilters),
        nationality: filterData,
      };
    } else if (type === "AlumniMajors") {
      _tableState.selectedFilters = {
        ..._tableState.selectedFilters,
        ...stateMajors?.selectedFilters,
        major: filterData,
      };
    }
    setTableState(_tableState);

    // Open filter tab
    window.innerWidth <= 1180 && toggleFilterDataTable(true);

    // Switch Tab
    setTab("right");
  };

  return (
    <div className={cx("o-hub")}>
      <div className={cx("o-hub__header")}>
        <h1 className={cx("o-hub__header__title")}>
          Employment data
          <Tooltip
            title={`The data hub provides employment information of international graduates ${
              users?.school_name && " from " + users?.school_name
            }`}
          >
            <img
              className={cx("o-hub__header__info-icon")}
              src={InformationIcon}
              alt="info"
            />
          </Tooltip>
        </h1>
        <Switch
          activeSide={tab}
          leftTitle="Graphs"
          rightTitle="Data"
          setActiveSide={setTab}
        />
      </div>
      {tab === "left" ? (
        <div className={cx("o-hub__body")}>
          <TopEmployerGraph
            filters={filters}
            state={stateTopEmp}
            setState={setStateTopEmp}
            openFilter={openFilterTopEmp}
            toggleFilter={toggleFilterTopEmp}
            schoolId={users?.school_id}
            processGraphClick={processGraphClick}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <AlumniDegreesGraph
                filters={filters}
                state={stateDegrees}
                setState={setStateDegrees}
                openFilter={openFilterDegrees}
                toggleFilter={toggleFilterDegrees}
                schoolId={users?.school_id}
                processGraphClick={processGraphClick}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <AlumniNationalityGraph
                filters={filters}
                state={stateNationality}
                setState={setStateNationality}
                openFilter={openFilterNationality}
                toggleFilter={toggleFilterNationality}
                schoolId={users.school_id}
                processGraphClick={processGraphClick}
              />
            </Grid>
          </Grid>
          <AlumniMajorsGraph
            filters={filters}
            state={stateMajors}
            setState={setStateMajors}
            openFilter={openFilterMajors}
            toggleFilter={toggleFilterMajors}
            schoolId={users?.school_id}
            processGraphClick={processGraphClick}
          />
        </div>
      ) : (
        <EmploymentDataTable
          tableState={tableState}
          setTableState={setTableState}
          tableSearch={tableSearch}
          setTableSearch={setTableSearch}
          openFilter={openFilterDataTable}
          toggleFilter={toggleFilterDataTable}
          filters={filters}
          selectedSchoolId={users?.school_id}
        />
      )}
    </div>
  );
}

import React from "react";

import RecommendationsDetailsStyles from "./RecommendationsDetailsStyles";
import CardCustom from "../../CardCustom/CardCustom";
import Loader from "../../Loader/Loader";
import StudentProfile from "../../StudentProfile/StudentProfile";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import usePersistState from "../../../state/usePersistState";

import Close from "../../../assets/svg/close.svg";
import CloseFocusIcon from "../../../assets/FocusIcons/Common/Close.svg";

const RecommendationsDetails = ({
  handleClose,
  details = {},
  handleFavorite,
  hideClose = false,
  showLoader = false,
  isTopicUser = false,
  showMessageButton = false,
  showMessageButtonText = "Message",
  showMessageButtonWidth = 238,
  handleMessage = () => {},
}) => {
  const { users = {} } = usePersistState();

  if (showLoader) return <Loader />;
  return (
    <RecommendationsDetailsStyles>
      <CardCustom
        className={`recom-details-card  text-primary ${
          isTopicUser ? "mt-0" : ""
        }`}
      >
        {!hideClose && (
          <a
            href="/#"
            className="card-close-btn"
            onClick={(e) => {
              e.preventDefault();
              handleClose(e);
            }}
            onFocus={() => {
              let img = document.getElementById(`CloseIcon`);
              if (img) img.src = CloseFocusIcon;
            }}
            onBlur={() => {
              let img = document.getElementById(`CloseIcon`);
              if (img) img.src = Close;
            }}
          >
            <img
              src={Close}
              id="CloseIcon"
              width="18px"
              alt="Close Profile Dialog"
            />
          </a>
        )}

        {details && (
          <StudentProfile
            user={details}
            showFavourite={
              users?.id === details?.id ||
              users?.school_id !== details?.school_id
                ? false
                : true
            }
            handleFavorite={handleFavorite}
            isTopicUser={isTopicUser}
            discoverNetwork={true}
            showUniversityName={details?.school_id !== users?.school_id}
          />
        )}

        {showMessageButton && (
          <ButtonCustom
            width={showMessageButtonWidth}
            onClick={(e) => handleMessage(e, details)}
          >
            {showMessageButtonText}
          </ButtonCustom>
        )}
      </CardCustom>
    </RecommendationsDetailsStyles>
  );
};

export default RecommendationsDetails;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { find, debounce } from "lodash";
import { Grid } from "@mui/material";

import checkmarkTrue from "../../../assets/svg/f-checkmark-true.svg";
import deleteIcon from "../../../assets/svg/f-delete-icon.svg";
import FallbackImage from "../../../assets/svg/discover_no_people.svg";
import usePersistState from "../../../state/usePersistState";
import usePromise from "../../../hooks/usePromise/usePromise";
import { toastify } from "../../../helper/helper";
import { routes } from "../../../routes";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { useAppState } from "../../../context";
import { InfoPanel } from "../../InfoPanel/InfoPanel";
import EmptyListWithImage from "../../../components/EmptyListWithImage/EmptyListWithImage";
import CardCustom from "../../CardCustom/CardCustom";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import TextboxCustom from "../../Textbox/TextboxCustom";
import JobsListingEffect from "../../effects/JobsListingEffect/JobsListingEffect";
import {
  getFavoriteNetworksAPI,
  favorite_Unfavorite_NetworksAPI,
} from "../../../services/networkServices";
import {
  createNewTopicAPI,
  getSuggestedTopicUsersAPI,
  editTopicAPI,
} from "../../../services/topicServices";

const Step2 = ({ handleClose, editTopicId = null }) => {
  const navigate = useNavigate();
  const { favoriteUsers = [], setFavoriteUsers } = usePersistState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    setNewTopicDetail,
    newTopicDetail: {
      topicName,
      description,
      file,
      topicProfile,
      department,
      topicType,
      selectedTagsForTopic,
    },
    newTopicDetail,
  } = useAppState("topic");

  const [callingCreateNewTopicAPI, refreshCreateNewTopicAPI] =
    usePromise(createNewTopicAPI);
  const [callingEditTopicAPI, refreshEditTopicAPI] = usePromise(editTopicAPI);
  const [callingGetSuggestedTopicUsersAPI, refreshGetSuggestedTopicUsersAPI] =
    usePromise(getSuggestedTopicUsersAPI);
  const [suggestedTopicUsersList, setSuggestedTopicUsersList] = useState([]);
  const [callingGetFavoriteNetworksAPI, refreshGetFavoriteNetworksAPI] =
    usePromise(getFavoriteNetworksAPI);
  const [
    callingFavorite_Unfavorite_NetworksAPI,
    refreshFavorite_Unfavorite_NetworksAPI,
  ] = usePromise(favorite_Unfavorite_NetworksAPI);
  const [searchValue, setSearchValue] = useState("");
  const [userArray, setUserArray] = useState([]);

  useEffect(() => {
    refreshGetSuggestedTopicUsersAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callingGetSuggestedTopicUsersAPI.hasFetched() &&
      callingGetSuggestedTopicUsersAPI.hasErrors() === false &&
      callingGetSuggestedTopicUsersAPI.data() &&
      callingGetSuggestedTopicUsersAPI.data().data
    ) {
      const selectedTagsIds = selectedTagsForTopic?.map((user) =>
        Number(user.id)
      );
      setSuggestedTopicUsersList(
        callingGetSuggestedTopicUsersAPI
          .data()
          ?.data.user_list?.map((user) => ({
            ...user,
            isSelected: selectedTagsIds?.includes(user.id),
          }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetSuggestedTopicUsersAPI.isFetching()]);

  useEffect(() => {
    if (
      callingCreateNewTopicAPI.hasFetched() &&
      callingCreateNewTopicAPI.hasErrors() === false
    ) {
      setIsSubmitting(false);
      setNewTopicDetail({
        topicName: "",
        description: "",
        file: "",
        department: {},
        topicType: true,
        selectedTagsForTopic: [],
      });
      toastify(
        "success",
        callingCreateNewTopicAPI.data()
          ? callingCreateNewTopicAPI.data()?.message
          : "Server error"
      );
      handleClose();
      navigate(routes.NETWORK.TOPICS);
    }
    if (
      callingCreateNewTopicAPI.hasFetched() &&
      callingCreateNewTopicAPI.hasErrors() === true
    ) {
      toastify(
        "error",
        callingCreateNewTopicAPI.data()
          ? callingCreateNewTopicAPI.data()?.message
          : "Server error"
      );
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingCreateNewTopicAPI.isFetching()]);

  useEffect(() => {
    if (
      callingEditTopicAPI.hasFetched() &&
      callingEditTopicAPI.hasErrors() === false
    ) {
      setIsSubmitting(false);
      setNewTopicDetail({
        topicName: "",
        description: "",
        file: "",
        department: {},
        topicType: true,
        selectedTagsForTopic: [],
      });
      toastify(
        "success",
        callingEditTopicAPI.data()
          ? callingEditTopicAPI.data()?.message
          : "Server error"
      );
      handleClose();
      navigate(routes.NETWORK.TOPICS);
    }
    if (
      callingEditTopicAPI.hasFetched() &&
      callingEditTopicAPI.hasErrors() === true
    ) {
      toastify(
        "error",
        callingEditTopicAPI.data()
          ? callingEditTopicAPI.data()?.message
          : "Server error"
      );
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingEditTopicAPI.isFetching()]);

  useEffect(() => {
    if (
      callingFavorite_Unfavorite_NetworksAPI.hasFetched() &&
      callingFavorite_Unfavorite_NetworksAPI.hasErrors() === false
    ) {
      toastify(
        "success",
        callingFavorite_Unfavorite_NetworksAPI.data()?.message
      );
      refreshGetFavoriteNetworksAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingFavorite_Unfavorite_NetworksAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetFavoriteNetworksAPI.hasFetched() &&
      callingGetFavoriteNetworksAPI.hasErrors() === false
    ) {
      const { favourite_list = [] } =
        callingGetFavoriteNetworksAPI.data()?.data;
      setFavoriteUsers(favourite_list?.map(({ id }) => id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetFavoriteNetworksAPI.isFetching()]);

  const addUserToGroup = (user) => {
    setUserArray([...userArray, Number(user.id)]);
    setSuggestedTopicUsersList(
      suggestedTopicUsersList?.map((rec) => {
        return {
          ...rec,
          ...(Number(rec.id) === Number(user.id) ? { isSelected: true } : {}),
        };
      })
    );

    let obj = {
      id: user.id.toString(),
      text: user.full_name,
      user_profile: user.user_profile,
    };
    let isTagExist = find(
      selectedTagsForTopic,
      (o) => o.id === user.id.toString()
    );
    if (!isTagExist) {
      setNewTopicDetail({
        selectedTagsForTopic: [...selectedTagsForTopic, obj],
        file,
        topicProfile,
        topicName,
        description,
        department,
        topicType,
      });
    }
  };

  const removeMember = (deleteId) => {
    const _tags = selectedTagsForTopic.filter(
      (user) => Number(user.id) !== Number(deleteId)
    );
    setSuggestedTopicUsersList((current) =>
      current?.map((user) => ({
        ...user,
        ...(Number(user.id) === Number(deleteId) ? { isSelected: false } : {}),
      }))
    );
    setNewTopicDetail({
      selectedTagsForTopic: _tags,
      file,
      topicProfile,
      topicName,
      description,
      department,
      topicType,
    });
  };

  const createTopic = () => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("topic[name]", newTopicDetail.topicName);
    formData.append("topic[description]", newTopicDetail.description);
    newTopicDetail?.topicProfile &&
      formData.append("topic[icon]", newTopicDetail.topicProfile);
    formData.append(
      "topic[topic_type]",
      newTopicDetail.department.value === "all"
        ? "public"
        : newTopicDetail.topicType
        ? "public"
        : "private"
    ); // public or private

    newTopicDetail.selectedTagsForTopic.forEach((user, i) => {
      formData.append("topic[user_ids][]", parseInt(user.id));
    });

    if (editTopicId) {
      refreshEditTopicAPI(formData, editTopicId);
    } else {
      refreshCreateNewTopicAPI(formData);
    }
  };

  const handleSearchChange = debounce((value) => {
    refreshGetSuggestedTopicUsersAPI({ user_name: value });
  }, 500);

  const handleFavorite = (e, userId) => {
    if (e) e.stopPropagation();
    refreshFavorite_Unfavorite_NetworksAPI(userId);
  };

  return (
    <div className="step3">
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <h2 className="title-primary mb-15">Search & filter</h2>
          <CardCustom className="new-message-filter">
            <div className="form-field-group">
              <label className="textbox-label">Search network</label>
              <TextboxCustom
                name="search"
                type="text"
                placeholder="Type a name"
                label=""
                lableaignment="left"
                value={searchValue}
                onChange={(obj) => setSearchValue(obj.target.value)}
              />
            </div>
            <ButtonCustom
              onClick={() => handleSearchChange(searchValue)}
              width={"100%"}
            >
              Search
            </ButtonCustom>
          </CardCustom>
          <div className="ShowResponsiveDiv">
            {selectedTagsForTopic.length > 0 && (
              <div className="group-members">
                <h2 className="title-primary mb-15">Participants</h2>
                <CardCustom className="new-message-group-card">
                  <ul>
                    {selectedTagsForTopic?.map((item) => {
                      return (
                        <li key={item.id}>
                          <div className="user-avatar">
                            <CircularAvatar
                              src={item.user_profile}
                              name={item.text}
                              round={true}
                              size={55}
                            />
                          </div>
                          <div className="user-name">{item.text}</div>
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              removeMember(item.id);
                            }}
                            className="user-action"
                          >
                            <img src={deleteIcon} alt="Delete Icon" />
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                  <ButtonCustom
                    width={248}
                    onClick={createTopic}
                    className="focus-white"
                    isSubmitting={isSubmitting}
                    keepLabelCase={true}
                  >
                    {editTopicId ? "Edit Community" : "Create Community"}
                  </ButtonCustom>
                </CardCustom>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="savedposts-content">
            <div className="saved-posts-wrapper">
              <h2 className="title-primary mb-15">People</h2>
              {callingGetSuggestedTopicUsersAPI.isFetching() ? (
                <Grid spacing={2} container>
                  <JobsListingEffect count={8} />
                </Grid>
              ) : suggestedTopicUsersList.length > 0 ? (
                <>
                  {suggestedTopicUsersList?.map((item, index) => (
                    <InfoPanel
                      key={index}
                      index={index}
                      avatar={item.user_profile}
                      userAvatar={true}
                      title={item.full_name}
                      subTitle={[
                        `${item.major}`,
                        `Class of ${item.graduation_year}`,
                      ]}
                      tags={item?.user_types || []}
                      isFavorites={
                        favoriteUsers.includes(item.id) ? true : false
                      }
                      onClick={(e) => handleFavorite(e, item.id)}
                      variant={
                        item.isSelected ? "actions-variant" : "discover-network"
                      }
                      action={
                        <>
                          {item.isSelected === true ? (
                            <ButtonCustom
                              height={30}
                              width={100}
                              color="secondary"
                              className="bg-gray"
                              onClick={() => {
                                removeMember(item.id);
                              }}
                              aria-label={`Add ${item.full_name}`}
                            >
                              Added
                              <img src={checkmarkTrue} alt="check" />
                            </ButtonCustom>
                          ) : (
                            <ButtonCustom
                              height={30}
                              width={100}
                              color="secondary"
                              onClick={() => {
                                addUserToGroup(item);
                              }}
                              aria-label={`Add ${item.full_name}`}
                            >
                              Add
                            </ButtonCustom>
                          )}
                        </>
                      }
                    />
                  ))}
                </>
              ) : (
                <EmptyListWithImage
                  Image={FallbackImage}
                  title={"Looks like there are no people in this search."}
                  subTitle={
                    "Try removing some filters to expand your search and discover other people!"
                  }
                  containerClassname={"empty-discover-people"}
                />
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          {selectedTagsForTopic.length > 0 && (
            <div className="group-members">
              <h2 className="title-primary mb-15">Participants</h2>
              <CardCustom className="new-message-group-card">
                <ul>
                  {selectedTagsForTopic?.map((item) => {
                    return (
                      <li key={item.id}>
                        <div className="user-avatar">
                          <CircularAvatar
                            src={item.user_profile}
                            name={item.text}
                            round={true}
                            size={55}
                          />
                        </div>
                        <div className="user-name">{item.text}</div>
                        <a
                          href="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            removeMember(item.id);
                          }}
                          className="user-action"
                        >
                          <img src={deleteIcon} alt="Delete Icon" />
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <ButtonCustom
                  width={248}
                  onClick={createTopic}
                  className="focus-white"
                  isSubmitting={isSubmitting}
                  keepLabelCase={true}
                >
                  {editTopicId ? "Edit Community" : "Create Community"}
                </ButtonCustom>
              </CardCustom>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default Step2;

import React, { useEffect, useState } from "react";
import { Grid, Tooltip } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

import usePersistState from "../../../state/usePersistState";
import usePromise from "../../../hooks/usePromise/usePromise";
import {
  getFavoriteNetworksAPI,
  favorite_Unfavorite_NetworksAPI,
} from "../../../services/networkServices";
import {
  getTopicParticipantsDetailsAPI,
  getTopicParticipantsFilters,
} from "../../../services/topicServices";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ParticipantsListV2.module.scss";
import { routes } from "../../../routes";
import CardCustom from "../../../components/CardCustom/CardCustom";
import TextboxCustom from "../../../components/Textbox/TextboxCustom";
import {
  INITIAL_FILTERS,
  INITIAL_FILTERS_OPTIONS,
  INITIAL_SEARCH_STATE,
  INITIAL_PAGINATION_DATA,
} from "./constants";
import DropdownCustom from "../../../components/DropdownCustom/DropdownCustom";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import JobsListingEffect from "../../../components/effects/JobsListingEffect/JobsListingEffect";
import { InfoPanel } from "../../../components/InfoPanel/InfoPanel";
import { toastify } from "../../../helper/helper";
import { useAppState } from "../../../context";
import { createFreshChatPreview } from "../../../utils/common";
import RecommendationsDetails from "../../../components/NetworkDashboard/RecommendationsDetails/RecommendationsDetails";
import EmptyListWithImage from "../../../components/EmptyListWithImage/EmptyListWithImage";

import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";
import Image from "../../../assets/svg/discover_no_people.svg";

const cx = createModuleStyleExtractor(styles);

export default function ParticipantsListV2() {
  const navigate = useNavigate();
  // Component states
  const [showLoader, setShowLoader] = useState(true);
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [filterOptions, setFilterOptions] = useState(INITIAL_FILTERS_OPTIONS);
  const [hideFilters, setHideFilters] = useState(true);
  const [search, setSearch] = useState(INITIAL_SEARCH_STATE);
  const [paginationData, setPaginationData] = useState(INITIAL_PAGINATION_DATA);
  const [participantsList, setParticipantsList] = useState([]);
  const [topicTitle, setTopicTitle] = useState(null);
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  // Stores if filter is applied - used to render clear all tag
  const isFilterApplied =
    search.appliedText?.length > 0 ||
    filters.country.value !== null ||
    filters.account_type.value !== null ||
    filters.degree.value !== null ||
    filters.major.value !== null ||
    filters.graduation_year.value !== null ||
    filters.department.value !== null;

  // Path params extract
  const topicId = new URLSearchParams(window.location.search)?.get("topicId");

  // Local storage
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const {
    users = {},
    favoriteUsers = [],
    setFavoriteUsers,
  } = usePersistState();
  const {
    getChatPreviewByReceiverId,
    chats,
    setChats,
    setConversationInformation,
    setCurrentConversationId,
    createFreshChatRecord,
  } = useAppState("chat");

  // API states
  const [callingGetTopicDetailsAPI, refreshGetTopicDetailsAPI] = usePromise(
    getTopicParticipantsDetailsAPI
  );
  const [
    callingGetTopicParticipantsFilterAPI,
    refreshGetTopicParticipantsFilterAPI,
  ] = usePromise(getTopicParticipantsFilters);
  const [
    callingFavorite_Unfavorite_NetworksAPI,
    refreshFavorite_Unfavorite_NetworksAPI,
  ] = usePromise(favorite_Unfavorite_NetworksAPI);
  const [callingGetFavoriteNetworksAPI, refreshGetFavoriteNetworksAPI] =
    usePromise(getFavoriteNetworksAPI);

  // Effects
  useEffect(() => {
    setShowLoader(true);
    refreshGetTopicDetailsAPI(topicId);
    refreshGetTopicParticipantsFilterAPI(topicId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callingGetTopicDetailsAPI.hasFetched() &&
      callingGetTopicDetailsAPI.hasErrors() === false
    ) {
      const data = callingGetTopicDetailsAPI.data().data;
      if (paginationData.current_page === 1) {
        setParticipantsList(data?.participants_list);
      } else {
        setParticipantsList((current) => [
          ...current,
          ...(data?.participants_list || []),
        ]);
      }
      // Set pagination data
      setPaginationData((current) => ({
        ...current,
        current_page: parseInt(data?.current_page),
        total_pages: data?.total_pages,
        total_participants: data?.total_participants,
      }));
      // Update Community title
      if (topicTitle === null) setTopicTitle(data.title);
      setShowLoader(false);
    } else if (
      callingGetTopicDetailsAPI.hasFetched() &&
      callingGetTopicDetailsAPI.hasErrors() === true
    ) {
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetTopicDetailsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetTopicParticipantsFilterAPI.hasFetched() &&
      callingGetTopicParticipantsFilterAPI.hasErrors() === false
    ) {
      const data = callingGetTopicParticipantsFilterAPI.data().data;
      // Process data and update state
      // Remove duplicates in department data
      let uniqueDepartments = [];
      let nonUniqueDepartments = data?.departments?.map((element) => ({
        value: element?.id,
        label: element?.name + " | " + element?.school_name,
        school_name: element?.school_name,
      }));
      nonUniqueDepartments.forEach((department) => {
        if (
          uniqueDepartments.find(
            (item) =>
              item?.value === department?.value &&
              item?.school_name === department?.school_name
          ) === undefined
        ) {
          uniqueDepartments.push(department);
        }
      });
      setFilterOptions({
        account_type: data?.account_type?.map((element) => ({
          value: element,
          label:
            element === "super_admin_user"
              ? "Super Admin"
              : element === "school_admin_user"
              ? "School Admin"
              : element === "prospect_user"
              ? "Prospect"
              : element === "student"
              ? "Student"
              : element === "alumni"
              ? "Alumni"
              : element === "staff"
              ? "Staff"
              : element === "parent_user"
              ? "Parent"
              : element,
        })),
        degree: data?.degree?.map((element) => ({
          value: element,
          label: element,
        })),
        major: data?.major?.map((element) => ({
          value: element,
          label: element,
        })),
        graduation_year: data?.gradutaion_year?.map((element) => ({
          value: element,
          label: element,
        })),
        country: data?.country?.map((element) => ({
          value: element,
          label: element,
        })),
        departments: uniqueDepartments,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetTopicParticipantsFilterAPI.isFetching()]);

  useEffect(() => {
    if (
      callingFavorite_Unfavorite_NetworksAPI.hasFetched() &&
      callingFavorite_Unfavorite_NetworksAPI.hasErrors() === false
    ) {
      toastify(
        "success",
        callingFavorite_Unfavorite_NetworksAPI.data().message
      );
      refreshGetFavoriteNetworksAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingFavorite_Unfavorite_NetworksAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetFavoriteNetworksAPI.hasFetched() &&
      callingGetFavoriteNetworksAPI.hasErrors() === false
    ) {
      const { favourite_list = [] } = callingGetFavoriteNetworksAPI.data().data;
      setFavoriteUsers(favourite_list.map(({ id }) => id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetFavoriteNetworksAPI.isFetching()]);

  // Component methods
  const applyCurrentFilters = () => {
    setShowLoader(true);
    setSelectedParticipant(null);
    refreshGetTopicDetailsAPI(topicId, 1, filters, search.appliedText);
  };

  const clearAllFilters = () => {
    setFilters(INITIAL_FILTERS);
    setSearch(INITIAL_SEARCH_STATE);
    setSelectedParticipant(null);
    setShowLoader(true);
    refreshGetTopicDetailsAPI(topicId, 1, INITIAL_FILTERS, "");
  };

  const fetchMoreData = () => {
    const next_page = paginationData.current_page + 1;
    setPaginationData((current) => ({
      ...current,
      current_page: next_page,
    }));
    refreshGetTopicDetailsAPI(topicId, next_page, filters, search.appliedText);
  };

  const handleMessage = (item) => {
    try {
      const { user_id } = item;
      const chat_preview = getChatPreviewByReceiverId(user_id);

      if (chat_preview) {
        // If chat already present
        setCurrentConversationId(chat_preview.conversation_id);
        navigate("/network/messenger", { state: { userid: user_id } });
      } else {
        // If Fresh Chat
        const randomConversationId = new Date().getTime();
        const { preview, conversation } = createFreshChatPreview(
          {
            full_name: item?.user_name,
            user_profile: item?.user_image,
            id: item?.user_id,
          },
          randomConversationId
        );
        setChats([preview, ...chats]);
        setConversationInformation(randomConversationId, conversation);
        setCurrentConversationId(randomConversationId);
        createFreshChatRecord(randomConversationId);
        navigate("/network/messenger", { state: { userid: user_id } });
      }
    } catch (error) {
      console.error(error);
      toastify(
        "error",
        "Something went wrong. Please try again later or contact us for support."
      );
    }
  };

  const handleFavorite = (e, userId) => {
    if (e) e.stopPropagation();
    refreshFavorite_Unfavorite_NetworksAPI(userId);
  };

  // Reusable JSX component
  const renderParticipantsList = participantsList?.map((participant, index) => {
    let introLineOneData = [];
    participant?.major && introLineOneData.push(participant.major);
    participant.graduation_year &&
      introLineOneData.push("Year " + participant.graduation_year);
    participant.external &&
      participant.user_school_name &&
      introLineOneData.push(participant.user_school_name);

    return (
      <InfoPanel
        key={index}
        variant="discover-network"
        index={index}
        avatar={participant.user_image}
        userAvatar={true}
        title={participant.user_name}
        tags={[
          participant?.is_admin ? "Community Creator" : "",
          participant.external === true ? "External" : "",
          ...participant.user_types,
        ]}
        onPanelClick={() => setSelectedParticipant(participant)}
        isActive={selectedParticipant?.user_id === participant.user_id}
        subTitle={introLineOneData.join(" | ")}
        intro={
          <div
            className={cx([
              "participants-list__middle__info-panel-intro",
              selectedParticipant?.user_id === participant.user_id
                ? "participants-list__middle__info-panel-intro--active"
                : "",
            ])}
          >
            {participant.country_image && (
              <div
                className={cx(
                  "participants-list__middle__info-panel-intro__flag"
                )}
              >
                <img src={participant.country_image} alt="country" />
              </div>
            )}
            {participant.country && <span>{participant.country}</span>}
          </div>
        }
        marginTop="0px"
        isFavorites={!!favoriteUsers.includes(participant.user_id)}
        onClick={(e) => handleFavorite(e, participant.user_id)}
        action={
          <>
            {users?.id !== participant.user_id && !participant.external && (
              <ButtonCustom
                height={30}
                width={100}
                color="secondary"
                onClick={() => {
                  handleMessage(participant);
                }}
                aria-label={`Message ${participant.user_name}`}
              >
                Message
              </ButtonCustom>
            )}
          </>
        }
      />
    );
  });

  return (
    <div className={cx("participants-list")}>
      <div className={cx("participants-list__header")}>
        <div className={cx("participants-list__header__back-wrapper")}>
          <button
            onClick={() =>
              navigate(routes.NETWORK.TOPICS + `?topicId=${topicId}`)
            }
          >
            <div>
              <img src={BackArrow} alt="Back arrow" />
              <span>Back to My Community</span>
            </div>
          </button>
        </div>
        {topicTitle && (
          <Tooltip title={topicTitle}>
            <h2>Participants in {topicTitle}</h2>
          </Tooltip>
        )}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <div className={cx("participants-list__filters")}>
            <div className={cx("participants-list__filters__header")}>
              <span className={cx("participants-list__filters__header__title")}>
                Search & filter
              </span>
              {isFilterApplied && (
                <span
                  className={cx(
                    "participants-list__filters__header__clear-all"
                  )}
                  onClick={clearAllFilters}
                >
                  Clear all
                </span>
              )}
            </div>
            <CardCustom className={cx("participants-list__filters__card")}>
              <div className="form-field-group">
                <label className="form-label textbox-label">
                  Search a person
                </label>
                <TextboxCustom
                  name="search-person"
                  type="text"
                  placeholder="Enter a name"
                  label=""
                  lableaignment="left"
                  value={search.text}
                  onChange={(e) =>
                    setSearch({
                      text: e.target.value,
                      appliedText: e.target.value?.trim(),
                    })
                  }
                  onEnterKey={applyCurrentFilters}
                />
              </div>
              <div className="form-field-group">
                <DropdownCustom
                  name="country"
                  label="Select country"
                  placeholder="Country"
                  justifyContent="left"
                  options={filterOptions.country}
                  value={filters.country}
                  onChange={(e) =>
                    setFilters((current) => ({
                      ...current,
                      country: filterOptions?.country?.find(
                        (item) => item.value === e.value
                      ),
                    }))
                  }
                />
              </div>
              <div className="form-field-group">
                <DropdownCustom
                  name="user-type"
                  label="Select account type"
                  placeholder="Any"
                  justifyContent="left"
                  options={filterOptions.account_type}
                  value={filters.account_type}
                  onChange={(e) =>
                    setFilters((current) => ({
                      ...current,
                      account_type: filterOptions?.account_type?.find(
                        (item) => item.value === e.value
                      ),
                    }))
                  }
                />
              </div>
              {!hideFilters && (
                <>
                  {filterOptions.departments?.length > 0 && (
                    <div className="form-field-group">
                      <DropdownCustom
                        name="department"
                        label="Select department"
                        placeholder="School"
                        justifyContent="left"
                        options={filterOptions.departments}
                        value={filters.department}
                        onChange={(e) =>
                          setFilters((current) => ({
                            ...current,
                            department: filterOptions?.departments?.find(
                              (item) => item.value === e.value
                            ),
                          }))
                        }
                      />
                    </div>
                  )}
                  <div className="form-field-group">
                    <DropdownCustom
                      name="degree"
                      label="Select degree"
                      placeholder="Any"
                      justifyContent="left"
                      options={filterOptions.degree}
                      value={filters.degree}
                      onChange={(e) =>
                        setFilters((current) => ({
                          ...current,
                          degree: filterOptions?.degree?.find(
                            (item) => item.value === e.value
                          ),
                        }))
                      }
                    />
                  </div>
                  <div className="form-field-group">
                    <DropdownCustom
                      name="major"
                      label="Select major"
                      placeholder="Any"
                      justifyContent="left"
                      options={filterOptions.major}
                      value={filters.major}
                      onChange={(e) =>
                        setFilters((current) => ({
                          ...current,
                          major: filterOptions?.major?.find(
                            (item) => item.value === e.value
                          ),
                        }))
                      }
                    />
                  </div>
                  <div className="form-field-group">
                    <DropdownCustom
                      name="graduation_year"
                      label="Select graduation year"
                      placeholder="Any"
                      justifyContent="left"
                      options={filterOptions.graduation_year}
                      value={filters.graduation_year}
                      onChange={(e) =>
                        setFilters((current) => ({
                          ...current,
                          graduation_year: filterOptions?.graduation_year?.find(
                            (item) => item.value === e.value
                          ),
                        }))
                      }
                    />
                  </div>
                </>
              )}
              <div
                className={cx("participants-list__filters__card__hide-filters")}
                onClick={() => setHideFilters((current) => !current)}
              >
                <h4>{hideFilters ? "Filters" : "Hide filters"}</h4>
              </div>
              <div className={cx("participants-list__filters__card__button")}>
                <ButtonCustom onClick={applyCurrentFilters} width="250">
                  Search
                </ButtonCustom>
              </div>
            </CardCustom>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={cx("participants-list__middle")}>
            <div className={cx("participants-list__middle__title")}>
              <span>People</span>
            </div>
            {showLoader ? (
              <JobsListingEffect count={6} />
            ) : !showLoader && participantsList?.length === 0 ? (
              <EmptyListWithImage
                Image={Image}
                title={"Looks like there are no people in this search."}
                subTitle={
                  "Try removing some filters to expand your search and discover other people!"
                }
                containerClassname={"empty-discover-people"}
              />
            ) : accessibilityHelp === "true" ? (
              <div>
                {renderParticipantsList}
                {paginationData.current_page !== paginationData.total_pages &&
                  paginationData.total_pages !== 0 &&
                  participantsList?.length !== 0 && (
                    <center>
                      <div style={{ margin: "20px 0 0 0" }}>
                        {paginationData.pagination_loader ? (
                          <JobsListingEffect count={3} />
                        ) : (
                          <ButtonCustom onClick={fetchMoreData}>
                            Load More
                          </ButtonCustom>
                        )}
                      </div>
                    </center>
                  )}
              </div>
            ) : (
              <InfiniteScroll
                dataLength={participantsList.length}
                next={fetchMoreData}
                hasMore={
                  paginationData.current_page !== paginationData.total_pages &&
                  paginationData.total_pages !== 0 &&
                  participantsList.length !== 0
                }
                loader={
                  <div style={{ margin: "20px 0 0 0" }}>
                    <JobsListingEffect count={3} />
                  </div>
                }
                style={{ overflow: "none" }}
              >
                {renderParticipantsList}
              </InfiniteScroll>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          {selectedParticipant !== null && (
            <RecommendationsDetails
              details={{
                topicParticipant: selectedParticipant,
              }}
              handleClose={() => setSelectedParticipant(null)}
              handleFavorite={handleFavorite}
              showMessageButton={
                users?.id !== selectedParticipant?.user_id &&
                !selectedParticipant.external
              }
              showMessageButtonText="Message privately"
              handleMessage={() => handleMessage(selectedParticipant)}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

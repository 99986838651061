import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import _findIndex from "lodash/findIndex";

import BoostProfileStyles from "./BoostProfileStyles";
import { useAppState } from "../../context";
import { routes } from "../../routes";
import usePromise from "../../hooks/usePromise/usePromise";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import BoostProfileProgressBar from "../../components/BoostProfile/BoostProfileProgressBar/BoostProfileProgressBar";
import BoostProfileLeftSidebar from "../../components/BoostProfile/BoostProfileLeftSidebar/BoostProfileLeftSidebar";
import BoostProfileMiddle from "../../components/BoostProfile/BoostProfileMiddle/BoostProfileMiddle";
import BoostProfileRightSidebar from "../../components/BoostProfile/BoostProfileRightSidebar/BoostProfileRightSidebar";
import { getBootProfileAPI } from "../../services/boostProfileService";
import usePersistState from "../../state/usePersistState";

const BoostProfile = () => {
  const { statusList, setStatusList, totalCompletedStatus } =
    useAppState("boostProfile");
  const [currentIndex, setCurrentIndex] = useState(0);

  const { users } = usePersistState();
  const { id: userId } = users || {};

  const [callGetBootProfileAPI, refreshGetBootProfileAPI] =
    usePromise(getBootProfileAPI);

  useEffect(() => {
    let onloadIndex = _findIndex(statusList, ["isCompleted", false]);
    if (onloadIndex >= 0) {
      setCurrentIndex(onloadIndex);
    }
  }, []);

  useEffect(() => {
    if (totalCompletedStatus < 1) {
      refreshGetBootProfileAPI(userId);
    }
  }, []);

  useEffect(() => {
    if (
      callGetBootProfileAPI.data() &&
      callGetBootProfileAPI.data().boost_profile
    ) {
      setStatusList(callGetBootProfileAPI.data().boost_profile);
    }
  }, [callGetBootProfileAPI.data()]);

  const handleClickStatus = (index) => {
    setCurrentIndex(index);
  };

  return (
    <BoostProfileStyles>
      <MainLayout
        title="Boost Profile"
        breadCrumb={[
          { title: "Home", path: routes.DASHBOARD },
          { title: "Boost Profile", path: routes.BOOST_PROFILE },
        ]}
        viewOnlyNavbar={true}
      >
        <BoostProfileProgressBar currentIndex={currentIndex} />
        <div className="custom-container">
          <div className="boost-profile-content">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <BoostProfileLeftSidebar
                  currentIndex={currentIndex}
                  statusList={statusList}
                  handleClickStatus={handleClickStatus}
                />
              </Grid>
              <Grid item xs={6}>
                <BoostProfileMiddle
                  selectedTab={statusList[currentIndex].key}
                  title={statusList[currentIndex].title}
                />
              </Grid>
              <Grid item xs={3}>
                <BoostProfileRightSidebar status={statusList[currentIndex]} />
              </Grid>
            </Grid>
          </div>
        </div>
      </MainLayout>
    </BoostProfileStyles>
  );
};

export default BoostProfile;

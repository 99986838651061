import React, { useEffect, useState } from "react";

import { useAppState } from "../../../../context";
import usePersistState from "../../../../state/usePersistState";
import { createModuleStyleExtractor } from "../../../../utils/css";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";
import styles from "./SingleMessageItemV2.module.scss";
import { deliveryTime } from "../../../../helper/helper";

import redCheckedIcon from "../../../../assets/svg/red-check-mark.svg";

const cx = createModuleStyleExtractor(styles);

export const SingleMessageItemV2 = ({ item, isRecent, conversation }) => {
  const { currentSearchMessageItem } = useAppState("chat");
  const {
    message,
    sender_image,
    sender_name,
    send_at,
    shared_file,
    file_name,
    id,
    sender_id,
    conversation_type,
  } = item;
  const { users } = usePersistState();
  const { id: userId, admission_user_type } = users || {};
  const [isOwnMessage] = useState(sender_id === userId);
  const isOwnMessageAmbassador = userId === sender_id && admission_user_type;
  const isOtherMessageAmbassador =
    conversation?.user_info?.user_type?.includes("Ambassador");

  // Scroll into view the message upon search
  useEffect(() => {
    if (currentSearchMessageItem?.id === id) {
      const messageElement = document.getElementById(id);
      messageElement.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [currentSearchMessageItem, id]);

  return (
    <div className={cx("message")} id={id}>
      {isOwnMessage ? ( // Own Message
        <div
          className={cx("message__own")}
          style={{ marginTop: isRecent ? "-20px" : "0px" }}
        >
          <div className={cx("message__own__content")}>
            {!isRecent && (
              <div className={cx("message__own__content__title")}>
                <span className={cx("message__own__content__title__you")}>
                  You
                </span>
                {isOwnMessageAmbassador && (
                  <div className={cx("message__own__content__title__amb-tag")}>
                    <img src={redCheckedIcon} alt="red-check" />
                    <span>Ambassador</span>
                  </div>
                )}
                <span className={cx("message__own__content__title__delivery")}>
                  at {deliveryTime(send_at)}
                </span>
              </div>
            )}
            <div
              className={cx([
                "message__own__content__item",
                conversation_type === "system_generated"
                  ? "message__own__content__item__system"
                  : "",
              ])}
            >
              <span className={cx("message__own__content__item__message")}>
                {message}
              </span>
              {message === "" && shared_file && file_name && (
                <em
                  className={cx(
                    "message__own__content__item__no-message-attachment"
                  )}
                >
                  New attachment
                </em>
              )}
              {shared_file && file_name && (
                <a
                  className={cx("message__own__content__item__attachment")}
                  href={shared_file}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <span>{file_name}</span>
                </a>
              )}
            </div>
          </div>
          <div className={cx("message__own__info")}>
            <div
              className={cx("message__own__info__image")}
              style={{ visibility: isRecent ? "hidden" : "visible" }}
            >
              <CircularAvatar
                src={sender_image}
                round
                size={31}
                name={sender_name}
              />
            </div>
          </div>
        </div>
      ) : (
        // other message
        <div
          className={cx("message__other")}
          style={{ marginTop: isRecent ? "-20px" : "0px" }}
        >
          <div className={cx("message__other__info")}>
            <div
              className={cx("message__other__info__image")}
              style={{ visibility: isRecent ? "hidden" : "visible" }}
            >
              <CircularAvatar
                src={sender_image}
                round
                size={31}
                name={sender_name}
              />
            </div>
          </div>
          <div className={cx("message__other__content")}>
            {!isRecent && (
              <div className={cx("message__other__content__title")}>
                <span className={cx("message__other__content__title__name")}>
                  {sender_name?.split(" ")[0]}
                </span>
                {isOtherMessageAmbassador && (
                  <div
                    className={cx("message__other__content__title__amb-tag")}
                  >
                    <img src={redCheckedIcon} alt="red-check" />
                    <span>Ambassador</span>
                  </div>
                )}
                <span
                  className={cx("message__other__content__title__delivery")}
                >
                  at {deliveryTime(send_at)}
                </span>
              </div>
            )}
            <div
              className={cx([
                "message__other__content__item",
                conversation_type === "system_generated"
                  ? "message__other__content__item__system"
                  : "",
              ])}
            >
              <span className={cx("message__other__content__item__message")}>
                {message}
              </span>
              {message === "" && shared_file && file_name && (
                <em
                  className={cx(
                    "message__other__content__item__no-message-attachment"
                  )}
                >
                  New attachment
                </em>
              )}
              {shared_file && file_name && (
                <a
                  className={cx("message__other__content__item__attachment")}
                  href={shared_file}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <span>{file_name}</span>
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import isEmpty from "underscore/modules/isEmpty";
import { useNavigate } from "react-router-dom";

import CalendarStyles from "./CalendarStyles";
import CalendarLeftSidebar from "../../../components/Tools/Calendar/CalendarLeftSidebar/CalendarLeftSidebar";
import CustomCalendarDemo from "../../../components/CustomCalendar/CustomCalendarDemo";
import CalendarEventDetails from "../../../components/Tools/Calendar/CalendarEventDetails/CalendarEventDetails";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import {
  getEventList,
  getEventById,
  getAppointments,
} from "../../../services/calendarServices";
import usePersistState from "../../../state/usePersistState";
import usePromise from "../../../hooks/usePromise/usePromise";
import { useAppState } from "../../../context";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import { getAppointmentById } from "../../../services/appointmentServices";

const Calendar = () => {
  const navigate = useNavigate();
  const { users = {} } = usePersistState();
  const [callgetEventList, refreshgetEventList] = usePromise(getEventList);
  const [callgetAppointment, refreshgetAppointmentList] =
    usePromise(getAppointments);
  const [callgetgetEventById, refreshgetEventById] = usePromise(getEventById);
  const [callgetAppointmentById, refreshgetAppointmentById] =
    usePromise(getAppointmentById);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedAppoitment, setSelectedAppointment] = useState([]);
  const {
    setCalendarEvents,
    setCalendarAppointments,
    upCommingEvents,
    pastEvents,
    selectedEvent = {},
    calendarEvents,
    setSelectedEvent,
  } = useAppState("tools");
  let eventsArray = upCommingEvents.concat(pastEvents).concat(calendarEvents);

  useEffect(() => {
    if (!isEmpty(selectedEvent) && selectedEvent !== null) {
      setShowDetailsPopup(true);
    }
  }, [selectedEvent]);

  useEffect(() => {
    getCalendarEvent();
    getCalendarAppointments();
  }, [selectedMonths]);

  const getCalendarEvent = () => {
    refreshgetEventList();
  };

  const getCalendarAppointments = () => {
    if (selectedMonths.length !== 0) {
      refreshgetAppointmentList(selectedMonths.month, selectedMonths.year);
    }
  };

  const handleAppointmentClick = (info) => {
    setSelectedAppointment(info);
    setSelectedEvent(info);
    if (info && typeof info.location !== "undefined") {
      refreshgetAppointmentById(
        parseInt(info.id),
        users.time_zone,
        users.timezone_id
      );
    }
  };

  const handleEventClick = (info) => {
    const id = parseInt(info.event._def.publicId);
    let event = eventsArray.find((i) => i.id === id);
    let isPastEvent = pastEvents.find((i) => i.id === id);
    if (typeof isPastEvent !== "undefined") {
      setSelectedAppointment({ ...event, hideRescheduleButton: true });
    } else {
      setSelectedAppointment({ ...event, hideRescheduleButton: false });
    }
    setSelectedEvent(info.event);

    if (event && typeof event.location !== "undefined") {
      refreshgetAppointmentById(
        parseInt(info.event._def.publicId),
        users.time_zone,
        users.timezone_id
      );
    } else {
      refreshgetEventById(parseInt(info.event._def.publicId));
    }
  };

  useEffect(() => {
    if (
      callgetEventList.hasFetched() &&
      callgetEventList.hasErrors() === false &&
      callgetEventList.data() &&
      callgetEventList.data().data
    ) {
      setCalendarEvents(callgetEventList.data().data);
    }
  }, [callgetEventList.isFetching()]);

  useEffect(() => {
    if (
      callgetAppointment.hasFetched() &&
      callgetAppointment.hasErrors() === false
    ) {
      setCalendarAppointments(callgetAppointment.data());
    }
  }, [callgetAppointment.isFetching()]);

  useEffect(() => {
    if (
      callgetgetEventById.hasFetched() &&
      callgetgetEventById.hasErrors() === false &&
      callgetgetEventById.data() &&
      callgetgetEventById.data().data
    ) {
      setSelectedEvent(callgetgetEventById.data().data.network_tracker);
    }
  }, [callgetgetEventById.isFetching()]);

  useEffect(() => {
    if (
      callgetAppointmentById.hasFetched() &&
      callgetAppointmentById.hasErrors() === false &&
      callgetAppointmentById.data() &&
      callgetAppointmentById.data().data
    ) {
      setSelectedEvent(callgetAppointmentById.data().data);
    }
  }, [callgetAppointmentById.isFetching()]);

  return (
    <CalendarStyles>
      <div className="tools-calendar">
        <div className="book-meeting-btn-wrap">
          <ButtonCustom
            width={150}
            height={50}
            className="appointment-btn"
            onClick={() => navigate("/tools/book-meeting")}
          >
            Book meeting
          </ButtonCustom>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={5} className="calender-side-events">
            <div role="region" aria-label="List view of events">
              <div className="part-seperation">
                <CalendarLeftSidebar
                  events={upCommingEvents}
                  hideRescheduleButton={false}
                  handleEventClick={handleAppointmentClick}
                />
              </div>
              <h2 className="title-primary mb-15">Past Events</h2>
              <CalendarLeftSidebar
                events={pastEvents}
                hideRescheduleButton={true}
                handleEventClick={handleAppointmentClick}
              />
            </div>
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div
                  role="region"
                  aria-label="Calendar View of events - Warning: Calendar view is not accessible properly, use list view from List view region"
                >
                  <CustomCalendarDemo
                    events={calendarEvents}
                    handleEventClick={handleEventClick}
                    selectedEvent={selectedEvent !== null && selectedEvent.id}
                    setSelectedMonths={setSelectedMonths}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {showDetailsPopup && (
          <CustomDialog
            open={showDetailsPopup}
            handleClose={() => {
              setSelectedEvent(null);
              setShowDetailsPopup(false);
            }}
            isDisplayCloseButton={false}
            className="mobile-calendar-event-popup"
            dialogTitleClass="modal-title"
            dialogSubTitleClass="text-para"
            padding={0}
          >
            <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
              <CalendarEventDetails
                event={selectedAppoitment}
                setShowDetailsPopup={setShowDetailsPopup}
              />
            </div>
          </CustomDialog>
        )}
      </div>
    </CalendarStyles>
  );
};

export default Calendar;

import React, { useState, useEffect, useRef } from "react";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

import SelectiveSavedCompaniesStyles from "./SelectiveSavedCompaniesStyles";
import BackArrow from "../../../../assets/svg/f-btn-arrow-gray.svg";
import ArrowIcon from "../../../../assets/svg/down-arrow.svg";
import { routes } from "../../../../routes";
import StarIcon from "../../../StarIcon/StarIcon";
import usePersistState from "../../../../state/usePersistState";
import CardCustom from "../../../CardCustom/CardCustom";
import InitialAvatar from "../../../InitialAvatar/InitialAvatar";

const SelectiveSavedCompanies = (props) => {
  const navigate = useNavigate();
  const { savedCompaniesList = [] } = props;
  const { selectedCompany, setSelectedCompany } = usePersistState();

  const [resultId, setResultId] = useState(null);
  const [openFilter, toggleFilter] = useState(false);

  const firstLoad = useRef();
  useEffect(() => {
    if (!firstLoad.current) {
      firstLoad.current = true;
    } else {
      if (isEmpty(savedCompaniesList)) {
        navigate(routes.JOBS.VISA, {
          state: { preserveFilters: true },
        });
      }
    }

    if (savedCompaniesList && savedCompaniesList.length > 0) {
      props.openMap
        ? sendDataOnMap(
            selectedCompany !== null ? selectedCompany : savedCompaniesList[0]
          )
        : openCompanyDetail(
            selectedCompany !== null ? selectedCompany : savedCompaniesList[0]
          );
      let isCompanyPresent = savedCompaniesList.find((i, k) => {
        if (selectedCompany !== null) {
          return i.id === selectedCompany.id;
        }
      });
      if (isCompanyPresent === undefined) {
        openCompanyDetail(savedCompaniesList[0]);
      }
    }
  }, [savedCompaniesList]);
  useEffect(() => {
    if (selectedCompany !== null) {
      setResultId(selectedCompany.company_id);
      toggleFilter(false);
    }
  }, [selectedCompany]);

  const sendDataOnMap = (data) => {
    setSelectedCompany(data);
    setResultId(data.company_id);
  };

  const openCompanyDetail = (data) => {
    setSelectedCompany(data);
    setResultId(data.company_id);
  };

  const onStarClick = (data) => {
    props.updateFavourites({
      ...data,
      like: "unlike",
    });
  };

  const renderSearchResult = (data, i) => {
    return (
      <CardCustom
        className={`search-card cursor-pointer mb-15 ${
          resultId === data.company_id && "active"
        }`}
        onClick={() =>
          props.back === "searchList"
            ? openCompanyDetail(data)
            : sendDataOnMap(data)
        }
      >
        <div className="search-item">
          <div className="avtar">
            {data.logo ? (
              <Avatar
                alt={
                  data.company_name
                    ? `${data.company_name}'s logo`
                    : "default logo"
                }
                src={data.logo}
                className="white-background"
              />
            ) : (
              <InitialAvatar
                variant="square"
                title={data.company_name}
                index={i}
                initialCount={1}
              />
            )}
          </div>
          <div className="content">
            <div className="header-primary">
              {data.company_name}
              <StarIcon
                aria-label={`Remove ${data.company_name} from saved companies`}
                variant="filled"
                onClick={() => onStarClick(data)}
              />
            </div>
          </div>
        </div>
      </CardCustom>
    );
  };

  return (
    <SelectiveSavedCompaniesStyles>
      <div className="back-btn-wrapper">
        <a
          className="shadow-btn"
          onClick={(e) => {
            e.preventDefault();
            props.chageViewMode && props.chageViewMode();
            props.back
              ? props.back(true)
              : props.back2 &&
                navigate(routes.JOBS.VISA, {
                  state: { preserveFilters: true },
                });
          }}
          href="/#"
          aria-label="Back"
        >
          <img src={BackArrow} id="BackArrow" width="18px" alt="Back arrow" />
          <span>Back to Search</span>
        </a>
      </div>
      <div
        className={`search-list collapsible-view ${openFilter ? "open" : ""}`}
      >
        <div className="search-title">
          <div className="title-primary mb-15">
            Saved Companies
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                toggleFilter(!openFilter);
              }}
              className="collapse-icon arrow"
            >
              <img src={ArrowIcon} alt="Arrow Icon" />
            </a>
          </div>
        </div>
        {savedCompaniesList.map((data, i) => renderSearchResult(data, i))}
      </div>
    </SelectiveSavedCompaniesStyles>
  );
};
export default SelectiveSavedCompanies;

import React, { useState, useEffect } from "react";
import cls from "classnames";

import NotificationCategoriesStyles from "./NotificationCategoriesStyles";
import usePersistState from "../../../../state/usePersistState";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { getMeetingPurposes } from "../../../../services/meetingPurposeServices";

import notificationsNetworkingIcon from "../../../../assets/svg/Notification_Settings_Networking.svg";
import notificationsTopicsIcon from "../../../../assets/svg/Notification_Settings_Topics.svg";
import notificationsWebinarsIcon from "../../../../assets/svg/Notification_Settings_Webinars.svg";
import notificationsJobsIcon from "../../../../assets/svg/Notification_Settings_Jobs.svg";
import notificationsCalendarIcon from "../../../../assets/svg/Notification_Settings_Calendar.svg";

const NotificationCategories = ({ activeTab, setActiveTab }) => {
  const { permissions = {} } = usePersistState();
  const [showBookAppointment, setShowBookAppointment] = useState(false);
  const [callGetMeetingPurposes, refreshGetMeetingPurposes] =
    usePromise(getMeetingPurposes);
  useEffect(() => {
    if (
      callGetMeetingPurposes.hasFetched() &&
      !callGetMeetingPurposes.hasErrors()
    ) {
      let appointmentSettings =
        callGetMeetingPurposes.data()?.data?.appointment_settings;
      if (appointmentSettings && appointmentSettings.length > 0) {
        setShowBookAppointment(true);
      }
    }
  }, [callGetMeetingPurposes.isFetching()]);

  useEffect(() => {
    refreshGetMeetingPurposes();
  }, []);

  const NOTIFICATION_CATEGORIES = [
    ...(permissions["chat"]
      ? [
          {
            value: "chat",
            title: "Networking",
            image: notificationsNetworkingIcon,
            checked: false,
          },
        ]
      : []),

    ...(permissions["topics"]
      ? [
          {
            value: "topics",
            title: "Communities",
            image: notificationsTopicsIcon,
            checked: false,
          },
        ]
      : []),

    {
      value: "jobs",
      title: "Jobs",
      image: notificationsJobsIcon,
      checked: false,
    },

    {
      value: "others",
      title:
        permissions["webinar"] ||
        permissions["resources"] ||
        permissions["student_services"]
          ? permissions["webinar"] && permissions["resources"]
            ? "Webinar & Resources"
            : permissions["webinar"] && permissions["student_services"]
            ? "Webinar & Student services"
            : permissions["resources"] && permissions["student_services"]
            ? "Resource & Student services"
            : permissions["webinar"]
            ? "Webinar & Posts"
            : permissions["resources"]
            ? "Resources & Posts"
            : "Student services & Posts"
          : "Posts",
      image: notificationsWebinarsIcon,
      checked: false,
    },

    ...(permissions["appointment"] && showBookAppointment
      ? [
          {
            value: "appointment",
            title: "Book Appointment",
            image: notificationsCalendarIcon,
            checked: false,
          },
        ]
      : []),
  ];

  return (
    <NotificationCategoriesStyles>
      <div className="category-card">
        <ul className="category-list">
          {NOTIFICATION_CATEGORIES.map((item, i) => (
            <li
              key={i}
              className={cls("cursor-pointer", {
                active: activeTab === item.value,
              })}
              onClick={() => setActiveTab(item.value)}
            >
              <a
                className="link"
                href="/#"
                aria-label={`${item.title} Settings`}
                onClick={(e) => e.preventDefault()}
              >
                <img
                  src={item.image}
                  alt={`${item.title} Settings`}
                  width="30px"
                  className={
                    item.value === "appointment" && activeTab === item.value
                      ? "br-50 bg-white"
                      : item.value === "appointment"
                      ? "bg-offwhite br-50"
                      : ""
                  }
                />
                <span>{item.title}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </NotificationCategoriesStyles>
  );
};

export default NotificationCategories;

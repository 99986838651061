import React from "react";
import { isNull } from "lodash";

import ButtonCustom from "../../../../components/ButtonCustom/ButtonCustom";
import CustomOptionTabs from "../../../../components/CustomOptionTabs/CustomOptionTabs";
import usePersistState from "../../../../state/usePersistState";
import btnArrow from "../../../../assets/svg/f-btn-arrow-gray.svg";

const TestTypeSelection = ({ onNext = () => {}, onBack = () => {} }) => {
  const { testType = null, setTestType } = usePersistState();

  return (
    <div className="game-start-selection text-center">
      <h3 className="test-title">
        Career Readiness Pre-Assessment Self-Rating
      </h3>

      <p className="test-sub-title ">
        Take this assessment to identify your level of career readiness. For
        each competency area, there are two sets of questions that measure
        career readiness. Please respond to the assessment honestly. This
        assessment has been intentionally created for international students in
        the United States.
      </p>

      <div className="test-controls ml-auto mr-auto">
        <div>
          <CustomOptionTabs
            keyField={"value"}
            valueField={"label"}
            value={testType}
            label="What kind of student are you?"
            options={[
              { value: "domestic", label: "I'm a domestic student" },
              {
                value: "international",
                label: "I'm an international student",
              },
            ]}
            onClick={(value) => setTestType(value)}
          />
        </div>
      </div>
      <div className="test-action-btns-wrapper">
        <ButtonCustom
          width={248}
          className="shadow-btn"
          onClick={(e) => {
            e.preventDefault();
            onBack();
          }}
        >
          <img src={btnArrow} alt="btn-arrow" />
          Previous
        </ButtonCustom>
        <ButtonCustom
          width={248}
          isDisabled={isNull(testType)}
          onClick={() => {
            onNext();
          }}
        >
          Next
        </ButtonCustom>
      </div>
    </div>
  );
};

export default TestTypeSelection;

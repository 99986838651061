import { useReducer } from "react";
import { size, filter } from "lodash";

import usePersistState from "./usePersistState";
import {
  BOOST_PROFILE_STATUS_LIST,
  BOOST_PROFILE_STATUS_LIST_WITHOUT_NETWORK,
  BOOST_PROFILE_STATUS_LIST_WITHOUT_WEBINAR,
  BOOST_PROFILE_STATUS_LIST_WITHOUT_NETWORK_WEBINAR,
} from "../constant/mockdata";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  totalCompletedStatus: 0,
  statusList: BOOST_PROFILE_STATUS_LIST,
  statusesLoaded: false,
};

const useBoostProfile = (appState) => {
  const { permissions = {} } = usePersistState();

  const [state, setState] = useReducer(reducer, {
    ...initialArgs,
    statusList:
      !permissions["chat"] && !permissions["webinar"]
        ? BOOST_PROFILE_STATUS_LIST_WITHOUT_NETWORK_WEBINAR
        : !permissions["chat"]
        ? BOOST_PROFILE_STATUS_LIST_WITHOUT_NETWORK
        : !permissions["webinar"]
        ? BOOST_PROFILE_STATUS_LIST_WITHOUT_WEBINAR
        : BOOST_PROFILE_STATUS_LIST,
  });

  const updateStatusList = (updateIndexNumber) => {
    let newStatusList = Object.assign([], state.statusList);
    newStatusList[updateIndexNumber].isCompleted = true;
    setState({ statusList: newStatusList });
    let total = size(filter(state.statusList, { isCompleted: true }));
    setState({ totalCompletedStatus: total });
  };

  const setStatusList = (data) => {
    const keys = Object.keys(data);

    const newStatusList = BOOST_PROFILE_STATUS_LIST.filter((step) =>
      keys.includes(step.key)
    ).map((boostStep) => {
      boostStep.isCompleted = data[boostStep.key];
      return boostStep;
    });

    setState({ statusList: [...newStatusList], statusesLoaded: true });
    let total = size(filter(newStatusList, { isCompleted: true }));
    setState({ totalCompletedStatus: total });
  };

  const resetBoostStep = () => {
    state.statusList.map((key, index) => {
      state.statusList[index].isCompleted = false;
    });

    setState({
      totalCompletedStatus: 0,
      statusList: state.statusList,
    });
  };

  return {
    updateStatusList,
    setStatusList,
    resetBoostStep,
    ...state,
  };
};

export default useBoostProfile;

import React, { useState } from "react";
import moment from "moment";
import { Tooltip } from "@mui/material";

import StudentProfileStyles from "./StudentProfileStyles";
import { validateAttribute } from "../../helper/helper";
import { CircularAvatar } from "../atoms/CircularAvatar/CircularAvatar";
import usePersistState from "../../state/usePersistState";
import TextTruncate from "../atoms/TextTruncate/TextTruncate";

import StarIcon from "../StarIcon/StarIcon";
import Briefcase from "../../assets/svg/briefcase.svg";
import Location from "../../assets/svg/location.svg";
import House from "../../assets/svg/house.svg";
import LinkedIn from "../../assets/svg/dashboard-linkedin-notconnected.svg";

export default function CardCustom({
  user = {},
  showFavourite = false,
  handleFavorite,
  isTopicUser = false,
  discoverNetwork = false,
  showUniversityName = false,
}) {
  const { favoriteUsers = [] } = usePersistState();
  const [seeMore, setSeeMore] = useState(false);
  const { topicParticipant = {}, is_network_popup_shown = true } = user || {};

  let introLineTopicParticipant = [];
  topicParticipant?.major &&
    introLineTopicParticipant.push(topicParticipant?.major);
  topicParticipant?.graduation_year &&
    introLineTopicParticipant.push("Year " + topicParticipant?.graduation_year);
  topicParticipant?.external &&
    topicParticipant?.user_school_name &&
    introLineTopicParticipant.push(topicParticipant?.user_school_name);

  const users_full_name = validateAttribute(user?.full_name)
    ? user?.full_name
    : validateAttribute(user?.name)
    ? user?.name
    : validateAttribute(topicParticipant?.user_name)
    ? topicParticipant?.user_name
    : "";

  const isFavoriteUser = favoriteUsers.includes(
    user.id || topicParticipant?.user_id
  )
    ? true
    : false;
  return (
    <StudentProfileStyles>
      <div className="student-profile-card">
        <div className="student-profile-card__container">
          <div className="student-profile-card__profile_wrapper">
            <CircularAvatar
              src={
                user?.user_profile ||
                user?.user_profile_url ||
                topicParticipant?.user_image
              }
              round
              alt={user?.full_name || user?.name || topicParticipant?.user_name}
              name={
                user?.full_name || user?.name || topicParticipant?.user_name
              }
            />
          </div>

          {validateAttribute(users_full_name) && (
            <div className="student-profile-card__name_wrapper">
              <p>{users_full_name}</p>
              {showFavourite && is_network_popup_shown && (
                <>
                  {isFavoriteUser ? (
                    <StarIcon
                      aria-label={`Remove ${users_full_name}
                   from favourites`}
                      variant="filled"
                      onClick={(e) =>
                        handleFavorite(e, user.id || topicParticipant?.user_id)
                      }
                    />
                  ) : (
                    <StarIcon
                      aria-label={`Add ${users_full_name} to favourites`}
                      onClick={(e) =>
                        handleFavorite(e, user.id || topicParticipant?.user_id)
                      }
                    />
                  )}
                </>
              )}
            </div>
          )}
          {user?.account_type !== "super_admin_user" &&
            !discoverNetwork &&
            is_network_popup_shown && (
              <p className="student-profile-card__school_name">
                {user?.school_name}
              </p>
            )}

          {is_network_popup_shown && (
            <div className="student-profile-card__tags_wrapper">
              {validateAttribute(
                user?.account_type || topicParticipant?.user_type
              ) &&
                user?.account_type !== "Student" &&
                topicParticipant?.user_type !== "Ambassador" && (
                  <p
                    className={`student-profile-card__tag ${
                      user?.account_type === "prospect_user" ||
                      topicParticipant?.user_type === "propsect"
                        ? "student-profile-card__tag--admissions"
                        : ""
                    }`}
                  >
                    {user?.account_type === "school_admin_user"
                      ? "Admin"
                      : user?.account_type === "prospect_user"
                      ? "Prospect"
                      : user?.account_type === "parent_user"
                      ? "Parent"
                      : user?.account_type === "super_admin_user"
                      ? "Interstride Admin"
                      : user?.account_type
                      ? user?.account_type
                      : topicParticipant?.user_type === "propsect"
                      ? "Prospect"
                      : topicParticipant?.user_type === "super_admin"
                      ? "Super Admin"
                      : topicParticipant?.user_type === "staff"
                      ? "Staff"
                      : topicParticipant?.user_type}
                  </p>
                )}
              {(user?.user_type?.includes("Ambassador") ||
                topicParticipant?.user_type === "Ambassador") && (
                <Tooltip
                  title={
                    "Contact your admissions office to become an Ambassador."
                  }
                  placement="bottom"
                  enterTouchDelay={0}
                  leaveTouchDelay={2000}
                >
                  <p className="student-profile-card__tag student-profile-card__tag--admissions">
                    Ambassador
                  </p>
                </Tooltip>
              )}
              {topicParticipant?.external && (
                <p className="student-profile-card__tag">External</p>
              )}
              {user?.mentor && (
                <Tooltip
                  title={
                    "Contact your career services office to become a Mentor."
                  }
                  placement="bottom"
                  enterTouchDelay={0}
                  leaveTouchDelay={2000}
                >
                  <p className="student-profile-card__tag">Mentor</p>
                </Tooltip>
              )}
            </div>
          )}

          {is_network_popup_shown &&
            !isTopicUser &&
            user?.professional_experiences &&
            user?.professional_experiences[0]?.role &&
            user?.professional_experiences[0]?.organization && (
              <div className="student-profile-card__education_wrapper">
                <p>
                  <img src={Briefcase} alt="Briefcase" />
                  {user.professional_experiences[0]?.role} at{" "}
                  {user.professional_experiences[0]?.organization}
                </p>
              </div>
            )}

          {is_network_popup_shown &&
          user?.educations &&
          user?.educations[0]?.major &&
          user?.educations[0]?.graduation_date ? (
            <div className="student-profile-card__qualification_wrapper">
              <p>
                {validateAttribute(user.educations[0]?.major) &&
                  user.educations[0].major}
                {validateAttribute(user.educations[0]?.second_major) &&
                  ` ,${user.educations[0].second_major}`}
                <br />
                {validateAttribute(user.educations[0]?.graduation_date) &&
                  `Class of ${moment(user.educations[0].graduation_date).format(
                    "YYYY"
                  )}`}
              </p>
            </div>
          ) : user &&
            user.degree &&
            user.major &&
            user.graduation_year &&
            is_network_popup_shown ? (
            <div className="student-profile-card__qualification_wrapper">
              <p>
                {user.degree}
                <br />
                {user.major}, {user.graduation_year}
              </p>
            </div>
          ) : topicParticipant?.user_id &&
            introLineTopicParticipant?.length > 0 &&
            is_network_popup_shown ? (
            <div className="student-profile-card__topic-intro-line">
              <p>{introLineTopicParticipant.join(" | ")}</p>
            </div>
          ) : (
            <></>
          )}

          {showUniversityName &&
            user?.school_name &&
            is_network_popup_shown && (
              <div className="student-profile-card__school-name">
                <p>{user?.school_name}</p>
              </div>
            )}

          {(validateAttribute(user?.country_of_origin) ||
            validateAttribute(user?.current_city)) &&
            is_network_popup_shown && (
              <div className="student-profile-card__location_wrapper">
                {validateAttribute(user?.country_of_origin) && (
                  <div>
                    <img src={user?.country_image || House} alt="House" />
                    <p>{user.country_of_origin}</p>
                  </div>
                )}
                {validateAttribute(user?.current_city) && (
                  <div>
                    <img src={Location} alt="Location" />
                    <p>{user.current_city}</p>
                  </div>
                )}
              </div>
            )}
          {topicParticipant?.user_id && is_network_popup_shown && (
            <div className="student-profile-card__location_wrapper">
              {validateAttribute(topicParticipant?.country) && (
                <div>
                  <img
                    src={topicParticipant?.country_image || House}
                    alt="House"
                  />
                  <p>{topicParticipant?.country}</p>
                </div>
              )}
            </div>
          )}
          {isTopicUser &&
            validateAttribute(user?.intro) &&
            is_network_popup_shown && (
              <div className="student-profile-card__bio_wrapper">
                <TextTruncate text={user?.intro} />
              </div>
            )}

          {topicParticipant?.user_id &&
            is_network_popup_shown &&
            validateAttribute(topicParticipant?.intro) && (
              <div className="student-profile-card__bio_wrapper">
                <p>
                  {seeMore
                    ? topicParticipant?.intro
                    : topicParticipant?.intro.slice(0, 100)}
                  {seeMore || topicParticipant?.intro?.length < 150
                    ? " "
                    : "... "}
                  {seeMore ? (
                    <a
                      href="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setSeeMore(false);
                      }}
                      aria-label="See less"
                    >
                      See less
                    </a>
                  ) : (
                    topicParticipant?.intro?.length > 150 && (
                      <a
                        href="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          setSeeMore(true);
                        }}
                        aria-label="See more"
                      >
                        See more
                      </a>
                    )
                  )}
                </p>
              </div>
            )}

          {!isTopicUser &&
            validateAttribute(user?.user_intro) &&
            is_network_popup_shown && (
              <div className="student-profile-card__bio_wrapper">
                <TextTruncate text={user?.user_intro} />
              </div>
            )}

          {is_network_popup_shown &&
            validateAttribute(
              user?.linkedin_url || user?.linkedin_public_url
            ) && (
              <div className="student-profile-card__linkedin_wrapper">
                <a
                  href={user.linkedin_url || user?.linkedin_public_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="linkedIn"
                >
                  <span className="linkedin">
                    <img src={LinkedIn} alt="LinkedIn" />
                  </span>
                  LinkedIn
                </a>
              </div>
            )}
        </div>
      </div>
    </StudentProfileStyles>
  );
}

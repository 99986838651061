import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import { useAppState } from "../../context";
import {
  fetchUserAmbassadorProfile,
  updateUserAmbassadorProfile,
  createUserAmbassadorProfile,
} from "../../services/profile.ambassador.service";
import usePersistState from "../../state/usePersistState";
import { toastify } from "../../helper/helper";

// Hook to fetch student ambassador profile and update local app state
export function useFetchAndUpdateAmbassadorProfile() {
  const [loading, setLoading] = useState(true);

  const { updateAmbassadorProfileState } = useAppState("ambassadorProfile");
  const { users } = usePersistState();
  const { admission_user_type } = users || {};

  useEffect(() => {
    if (!admission_user_type) return;

    (async () => {
      const response = await fetchUserAmbassadorProfile();
      if (response?.status === 200 && response?.data) {
        const hobbies = response?.data?.hobbies_and_interests || [];
        const clubs = response?.data?.clubs || [];

        updateAmbassadorProfileState({
          ambassadorBio: response?.data?.summary || "",
          ambassadorHobbies:
            typeof hobbies === "object" ? hobbies : hobbies?.split(","),
          ambassadorClubs:
            typeof clubs === "object" ? clubs : clubs?.split(","),
        });
      }
      setLoading(false);
    })();
  }, []);

  return { loadingAmbassadorGETAPI: loading };
}

// Function takes the local ambassador state and updates the BE via PATCH API
export function useUpdateAmbassadorProfile() {
  const [loading, setLoading] = useState(false);

  const { ambassadorBio, ambassadorHobbies, ambassadorClubs } =
    useAppState("ambassadorProfile");
  const { users } = usePersistState();
  const { id: userId } = users || {};

  const handleAmbassadorProfileUpdate = async () => {
    if (loading || !userId) return;
    setLoading(true);

    // Validations start
    if (ambassadorBio?.length === 0) {
      toastify("error", "Please enter a summary");
      setLoading(false);
      return;
    }

    if (ambassadorHobbies?.length === 0) {
      toastify("error", "Please add a hobby");
      setLoading(false);
      return;
    }

    if (ambassadorClubs?.length === 0) {
      toastify("error", "Please add a club");
      setLoading(false);
      return;
    }
    // Validations end

    let formData = new FormData();
    formData.append("ambassador_detail[summary]", ambassadorBio.trim());
    formData.append(
      "ambassador_detail[hobbies_and_interests]",
      ambassadorHobbies?.join()
    );
    formData.append("ambassador_detail[clubs]", ambassadorClubs?.join());

    const response = await updateUserAmbassadorProfile(userId, formData);
    if (response?.status === 200) {
      toastify("success", "Profile updated successfully");
    } else {
      toastify("error", "Oops! something went wrong");
    }
    setLoading(false);
  };

  return { loadingAmbassadorPatchAPI: loading, handleAmbassadorProfileUpdate };
}

// Function takes the local ambassador state from popup and updates the BE via POST API
export function useCreateAmbassadorProfile() {
  const [loading, setLoading] = useState(false);
  const { updateUsersPersistState } = usePersistState();
  const {
    ambassadorBio,
    ambassadorHobbies,
    ambassadorClubs,
    updateAmbassadorProfileState,
  } = useAppState("ambassadorProfile");
  const { bulkUpdateAmbassadorTutorial } = useAppState("ambassadorTutorial");

  const handleAmbassadorProfileCreate = async (
    usersFields = {},
    users = {}
  ) => {
    if (loading) return;
    setLoading(true);

    let formData = new FormData();
    formData.append("ambassador_detail[summary]", ambassadorBio.trim());
    formData.append(
      "ambassador_detail[hobbies_and_interests]",
      ambassadorHobbies?.join()
    );
    formData.append("ambassador_detail[clubs]", ambassadorClubs?.join());

    // Append user state fields related to student account
    if (!isEmpty(usersFields)) {
      [...Object.keys(usersFields)].forEach((key) =>
        formData.append(`ambassador_detail[${key}]`, usersFields[key])
      );
    }

    const response = await createUserAmbassadorProfile(formData);
    if (response?.status === 200) {
      // Update persist state on users field update
      if (!isEmpty(usersFields)) {
        updateUsersPersistState({ ...usersFields, ...users });
      }
      toastify("success", "Profile updated successfully");
      updateAmbassadorProfileState({ ambassadorPopup: false }); // Close dialog
      bulkUpdateAmbassadorTutorial({ run: true }); // Run the tutorial from the start
    } else {
      toastify("error", "Oops! something went wrong");
    }
    setLoading(false);
  };

  return { loadingAmbassadorPostAPI: loading, handleAmbassadorProfileCreate };
}

import { useEffect, useState } from "react";
import { useAppState } from "../../context";
import { fetchUserProfile } from "../../services/profile.service";
import usePersistState from "../../state/usePersistState";
export const useFetchProfile = () => {
  const { profile, updateProfileState } = useAppState("profile");
  const [loading, setIsLoading] = useState(false);
  const [loaded, setIsLoaded] = useState(false);
  const { users, updatePersistState } = usePersistState();

  const syncData = (response) => {
    if (response) {
      if (
        response.personal.first_name !== users.first_name ||
        response.personal.last_name !== users.last_name ||
        response.personal.nationality !== users.nationality ||
        response.personal.current_city !== users.current_city ||
        response.personal.secondary_email !== users.secondary_email ||
        response.personal.user_profile_url !== users.user_profile_url ||
        response.personal.profile_image_url !== users.profile_image_url ||
        response.personal.phone_number !== users.phone_number ||
        response.personal.country_code !== users.country_code
      ) {
        updatePersistState({
          users: {
            ...response.personal,
            educations: response.educations,
          },
        });
      }
      return response;
    }
  };

  const fetchProfile = async () => {
    try {
      setIsLoading(true);
      const response = await fetchUserProfile();
      let data = syncData(response?.data);
      const { personal, educations, experience, career } = data || {};

      updateProfileState({
        profile: { ...data },
        personalForm: personal,
        educationsForm: educations,
        experienceForm: { ...experience },
        careerForm: career,
      });

      setIsLoaded(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoaded(true);
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (loading) return;
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    profile,
    loading: profile ? false : loading,
    loaded: profile ? true : loaded,
  };
};

import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import _find from "lodash/find";
import MyWebinarStyles from "./MyWebinarStyles";
import CardCustom from "../../../CardCustom/CardCustom";
import { ArrowBackIos, AccessTime } from "@mui/icons-material";
import { useAppState } from "../../../../context";
import Dialog from "./Dialog";
import moment from "moment";
import {
  calculateLocalEndTimeFromEpoch,
  getLocalTzAbbr,
} from "../../../../helper/helper";
import { getWebinarDetailsAPI } from "../../../../services/webinarServices";
import usePromise from "../../../../hooks/usePromise/usePromise";
import EmptyList from "../../../EmptyList/EmptyList";
import isNull from "underscore/modules/isNull";
import { ShimmerThumbnail } from "react-shimmer-effects";
import smallPersonIcon from "../../../../assets/svg/f-small-person-icon.svg";
import usePersistState from "../../../../state/usePersistState";

export const EmptyMyWebinar = ({ className }) => {
  const demoDate = moment().add(2, "days").format("dddd, MMM Do YYYY");

  return (
    <div className="facelift-empty-boxwrap">
      <h3 className="header-primary">
        You haven't registered for any webinars yet!
      </h3>
      <p>
        Go to webinars to see all upcoming and past webinars. Click on the
        register button to sign-up.
      </p>
    </div>
  );
};

const MyWebinar = ({
  openWebinar,
  isBack,
  title,
  items = [],
  loader = false,
  showEmpty = true,
  showModalOnly,
  wb_details,
  //closeModal
}) => {
  const { getViewMode, mode } = useAppState("modeManger");
  const { myWebinars } = useAppState("learn");
  const { users = {} } = usePersistState();
  const isAdmin = users ? users.user_type === "school_admin" || "school" : null;

  const [callingGetWebinarDetailsAPI, refreshGetWebinarDetailsAPI] =
    usePromise(getWebinarDetailsAPI);
  const { setWebinarDetails, webinarDetails, webId, showWebinarRightSide } =
    useAppState("learn");

  const [showModel, setModel] = useState(false);
  const [selectedWebinar, setSelectedWebinar] = useState([]);

  const redirect = (e) => {
    e.preventDefault();
    getViewMode(!mode);
    setWebinarDetails({ webinar: null });
  };

  const showWbinar = (data) => {
    setSelectedWebinar(data);
    refreshGetWebinarDetailsAPI(data.id);
    setModel(true);
    /* if (window.innerWidth > 1366) {
      setWebinarDetails({ webinar: data }, true);
    } else {
      refreshGetWebinarDetailsAPI(data.id);
      setModel(true);
    } */
    // here in data webinar key is not available so pass explicitly
  };

  const handleModel = (e, webinarId) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    refreshGetWebinarDetailsAPI(webinarId);
    setModel(true);
  };

  const handleClose = () => {
    setModel(false);
    setWebinarDetails({ webinar: null });
    //closeModal()
  };

  const renderMyWebinars = (data) => {
    return (
      <CardCustom
        className={`webinar-cards cursor-pointer ${
          openWebinar && data.id === webId && showWebinarRightSide && "active"
        }`}
      >
        <div
          className="webinar-item"
          onClick={(e) => openWebinar && showWbinar(data)}
        >
          <div className="left">
            <h3 className="date">
              {moment
                .unix(data?.epoch_time)
                .local()
                .format("dddd, MMMM Do, YYYY")}
            </h3>
            <h3 className="time">
              {`${moment
                .unix(data?.epoch_time)
                .local()
                .format("hh:mm a")} - ${calculateLocalEndTimeFromEpoch(
                data?.epoch_time,
                data && data.duration && !isNull(data.duration)
                  ? data.duration.split(" ")[0]
                  : 0,
                "minutes"
              )}`}{" "}
              ({getLocalTzAbbr()})
            </h3>
            {data.webinar_category_name && (
              <span className="webinarCategory">
                {data.webinar_category_name}
              </span>
            )}
            {data && data.presenter && data.presenter.length > 0 && (
              <h5>
                <img src={smallPersonIcon} alt="Small person icon" />
                {data && data.presenter}
              </h5>
            )}
          </div>
          <div className="right">
            <div className="header-primary">{data.name}</div>
            <div className="decsription">
              {data.description.substring(0, 75)}
              {data.description?.length > 75 && "..."}
            </div>
          </div>
        </div>
      </CardCustom>
    );
  };

  useEffect(() => {
    if (
      callingGetWebinarDetailsAPI.hasFetched() &&
      callingGetWebinarDetailsAPI.hasErrors() === false &&
      callingGetWebinarDetailsAPI.data() &&
      callingGetWebinarDetailsAPI.data().data
    ) {
      setWebinarDetails(callingGetWebinarDetailsAPI.data().data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetWebinarDetailsAPI.isFetching()]);
  //console.log(myWebinars, wb_details)
  const btn = {};
  let type =
    webinarDetails && webinarDetails !== null
      ? moment(webinarDetails.webinar_date).isBefore(
          moment().format("YYYY-MM-DD")
        )
        ? "past"
        : "upcoming"
      : null;
  if (type !== null)
    if (type === "upcoming") {
      if (
        _find(myWebinars, (o) => {
          return o.id === webinarDetails.id;
        })
      ) {
        btn.disabled = true;
        btn.text = "Registered";
        btn.className = "bg-gray";
        btn.showIcon = true;
      } else {
        btn.disabled = false;
        btn.text = "Register";
        btn.className = "";
        btn.showIcon = false;
      }
    } else {
      btn.disabled = false;
      btn.text = "View Recording";
      btn.className = "";
    }
  //console.log(type)

  // console.log("webinarDetails =>", webinarDetails);

  return (
    <MyWebinarStyles>
      {showModalOnly ? (
        <Dialog
          isOpen={true}
          handleClose={() => handleClose()}
          details={webinarDetails}
          loader={false}
        />
      ) : (
        <div className="registered-webinar-list-wrapper">
          {loader ? (
            Array.from(Array(5).keys()).map((_, index) => (
              <div className="my-webinar-loading-item" key={index}>
                <ShimmerThumbnail
                  height={100}
                  key={index}
                  className="m-0"
                  center={true}
                />
              </div>
            ))
          ) : (
            <div className="registered-webinar-list">
              {items.length > 0 ? (
                items.map((data, i) => renderMyWebinars(data, i))
              ) : showEmpty ? (
                <EmptyMyWebinar
                  className={
                    window.location.pathname === "/learn/webinars" &&
                    `empty-web`
                  }
                />
              ) : (
                <EmptyList title="No webinars found" />
              )}
              {/* {list.map((data, i) => renderMyWebinars(data, i))} */}
              <Dialog
                isOpen={showModel}
                handleClose={() => handleClose()}
                details={webinarDetails && webinarDetails}
                loader={callingGetWebinarDetailsAPI.isFetching()}
                type={type}
                isAdmin={isAdmin}
                btn={btn}
              />
            </div>
          )}
        </div>
      )}
    </MyWebinarStyles>
  );
};

export default MyWebinar;

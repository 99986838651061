import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Link } from "@mui/material";

import FilterPositionsStyles from "./FilterPositionsStyles";
import BackArrow from "../../../../assets/svg/f-btn-arrow-gray.svg";
import FilledFilterIcon from "../../../../assets/svg/filled-filter-Icon.svg";
import FilterIcon from "../../../../assets/svg/filter-Icon.svg";
import usePersistState from "../../../../state/usePersistState";
import { routes } from "../../../../routes";
import {
  US_STATE_OPTIONS,
  JOB_POSITION_OPTIONS,
  JOB_DATE_POSTED_OPTIONS,
} from "../../../../constant/mockdata";
import CardCustom from "../../../CardCustom/CardCustom";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import Textbox from "../../../Textbox/TextboxCustom";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";

const FilterPositions = ({ filter = {}, setFilter = () => {}, ...props }) => {
  const navigate = useNavigate();
  const { jobsCountryList } = usePersistState();
  const [openFilter, toggleFilter] = useState(false);

  const { search, country, state, city, job_type, publish_date } = filter;

  const searchType = country && country === "us" ? "us" : "international";

  const applyCurrentFilters = () => {
    toggleFilter(false);
    props.applyFilters();
  };

  return (
    <FilterPositionsStyles>
      <div className="back-btn-wrapper">
        <a
          className="shadow-btn"
          onClick={(e) => {
            e.preventDefault();
            navigate(routes.JOBS.VISA, {
              state: { preserveFilters: true },
            });
          }}
          href="/#"
          aria-label="Back"
        >
          <img src={BackArrow} id="BackArrow" width="18px" alt="Back arrow" />
          <span>Back to Search</span>
        </a>
      </div>
      <div
        className={`position-filter collapsible-view ${
          openFilter ? "open" : ""
        }`}
      >
        <div className="search-title">
          <div className="title-primary mb-15">
            Filter open positions
            <Link
              to="/#"
              className="ngray-link"
              onClick={(e) => {
                e.preventDefault();

                if (props.onClearAllClick) props.onClearAllClick();
              }}
            >
              Clear all
            </Link>
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                toggleFilter(!openFilter);
              }}
              className="collapse-icon"
            >
              {openFilter ? (
                <img src={FilledFilterIcon} alt="Filter Icon" />
              ) : (
                <img src={FilterIcon} alt="Filter Icon" />
              )}
            </a>
          </div>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <CardCustom className="search-card">
              <div className="form-field-group">
                <Textbox
                  name="search"
                  type="text"
                  placeholder="Enter job title or position"
                  label="Search keyword"
                  lableaignment="left"
                  value={search}
                  onChange={(e) => setFilter({ search: e.target.value })}
                  onEnterKey={applyCurrentFilters}
                />
              </div>
              <div className="form-field-group">
                <DropdownCustom
                  name="country"
                  label="Select country"
                  placeholder="Country"
                  justifyContent="left"
                  options={jobsCountryList}
                  value={
                    country &&
                    country !== null &&
                    jobsCountryList.find((rec) => rec.value === country)
                  }
                  onChange={(e) => {
                    setFilter({ country: e.value, state: null });
                  }}
                />
              </div>
              {searchType === "us" && (
                <div className="form-field-group">
                  <DropdownCustom
                    name="state"
                    label="State"
                    justifyContent="left"
                    options={US_STATE_OPTIONS}
                    value={
                      state && state !== null
                        ? US_STATE_OPTIONS.find((rec) => rec.value === state)
                        : null
                    }
                    onChange={(e) => {
                      setFilter({ state: e.value });
                    }}
                  />
                </div>
              )}

              <div className="form-field-group">
                <Textbox
                  name="city"
                  type="text"
                  placeholder="Enter city"
                  label="City"
                  lableaignment="left"
                  value={city}
                  onChange={(e) => setFilter({ city: e.target.value })}
                  onEnterKey={applyCurrentFilters}
                />
              </div>
              <div className="form-field-group">
                <DropdownCustom
                  name="job_type"
                  label="Position"
                  justifyContent="left"
                  options={JOB_POSITION_OPTIONS}
                  value={
                    job_type &&
                    job_type !== null &&
                    JOB_POSITION_OPTIONS.find((rec) => rec.value === job_type)
                  }
                  onChange={(e) => setFilter({ job_type: e.value })}
                />
              </div>
              <div className="form-field-group">
                <DropdownCustom
                  name="publish_date"
                  label="Date posted"
                  justifyContent="left"
                  options={JOB_DATE_POSTED_OPTIONS}
                  value={
                    publish_date &&
                    publish_date !== null &&
                    JOB_DATE_POSTED_OPTIONS.find(
                      (rec) => rec.value === publish_date
                    )
                  }
                  onChange={(e) => setFilter({ publish_date: e.value })}
                />
              </div>
              <div className="searchBtn">
                <ButtonCustom
                  color="primary"
                  onClick={applyCurrentFilters}
                  width="250"
                >
                  Apply Changes
                </ButtonCustom>
              </div>
            </CardCustom>
          </Grid>
        </Grid>
      </div>
    </FilterPositionsStyles>
  );
};
export default FilterPositions;

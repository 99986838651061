import React from "react";
import { Checkbox } from "@mui/material";

import { PRIMARY_DARK_COLOR } from "../../../styles/variables";

export default function CheckboxJob({
  checked = false,
  disabled = false,
  label = "",
  count = "",
  onChange = () => {},
}) {
  return (
    <div className="a-checkbox-job">
      <div className="a-checkbox-job__input">
        <Checkbox
          checked={checked}
          disabled={disabled}
          disableRipple={true}
          onChange={onChange}
          sx={{
            "&.Mui-checked": {
              color: PRIMARY_DARK_COLOR,
            },
            padding: "0 4px 0 0",
          }}
        />
      </div>
      <div className="a-checkbox-job__description">
        <p
          className={`a-checkbox-job__description__label ${
            checked ? "a-checkbox-job__description__label--checked" : ""
          }`}
        >
          {label}
          {count && count !== "0" && (
            <span className="a-checkbox-job__description__label__count">
              {count?.toLocaleString ? count?.toLocaleString() : count}
            </span>
          )}
        </p>
      </div>
    </div>
  );
}

import React, { useState, useCallback } from "react";
import { useFormik } from "formik";

import DashboardAmbassadorInfoPopup from "../../Dashboard/DashboardLeftSidebar/DashboardAmbassadorInfoPopup/DashboardAmbassadorInfoPopup";
import CardCustom from "../CardCustom/CardCustom";
import TextboxCustom from "../../Textbox/TextboxCustom";
import { Badge } from "../Badge/Badge";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./AmbassadorProfile.module.scss";
import Textarea from "../../atoms/Textarea/Textarea";
import { useAppState } from "../../../context";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { useCreateAmbassadorProfile } from "../../../hooks/Profile/useCommonAmbassadorProfile";
import { toastify, NoUserImage } from "../../../helper/helper";
import { updateProfileImage } from "../../../services/profile.service";

import InfoIcon from "../../../assets/svg/note-alert.svg";
import CloseIcon from "../../../assets/svg/close.svg";
import { validatePhoneNumber } from "../../../utils/common";
import PhoneNumberSelect from "../../PhoneNumberSelect/PhoneNumberSelect";
import usePersistState from "../../../state/usePersistState";
import CropperPic from "../../Cropper";
import CustomDialog from "../../CustomDialog/CustomDialog";
import Loader from "../../Loader/Loader";
import CustomUpload from "../../CustomUpload/CustomUpload";
import InitialAvatar from "../../InitialAvatar/InitialAvatar";

import uploadFocusIcon from "../../../assets/FocusIcons/Common/Upload.svg";
import uploadIcon from "../../../assets/svg/uploadIcon.svg";

const cx = createModuleStyleExtractor(styles);

const MAX_LENGTH_TEXT_FIELD = 40; // MAX NUMBER OF CHARACTERS

export default function AmbassadorProfile({ variant = "standard" }) {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const {
    infoPopup,
    updateAmbassadorProfileState,
    ambassadorBio,
    ambassadorHobbies,
    ambassadorClubs,
    removeHobbyItem,
    removeClubItem,
  } = useAppState("ambassadorProfile");
  const {
    users = {},
    users_local = {},
    userProfileAttachement,
    setUserProfileAttachment,
    updateUserProfileImage,
  } = usePersistState();
  const [cropperImage, setCropperImage] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [profilePicURL, setProfilePicURL] = useState(NoUserImage);
  const [profilePhoto, setProfilePhoto] = useState(
    users?.profile_image_url || users?.user_profile_url || ""
  );
  const [showProfilePicPopup, setProfilePicPopup] = useState(false);

  const { handleAmbassadorProfileCreate } = useCreateAmbassadorProfile();

  // Validation
  const validate = (values) => {
    const errors = {};
    if (variant === "popup") {
      if (!profilePhoto && profilePhoto.length === 0) {
        errors.user_profile_url = "Profile picture is required";
      }
      if (validatePhoneNumber(values.phone_number, values.country_code)) {
        errors.phone_number = "Phone number required";
      }
    }

    if (values.ambassadorBio.length === 0) {
      errors.ambassadorBio = "Please enter a summary";
    }
    if (
      ambassadorHobbies?.length === 0 &&
      values.ambassadorHobbiesText.trim().length === 0
    ) {
      errors.ambassadorHobbiesText = "Please add a hobby";
    }
    if (
      ambassadorClubs?.length === 0 &&
      values.ambassadorClubsText.trim().length === 0
    ) {
      errors.ambassadorClubsText = "Please add a club";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      ambassadorBio: ambassadorBio,
      ambassadorHobbiesText: "",
      ambassadorClubsText: "",
      phone_number: users?.phone_number,
      country_code: users?.country_code,
    },
    validate,
    onSubmit: async (values) => {
      await handleAmbassadorProfileCreate(
        variant === "popup"
          ? {
              phone_number: values.phone_number,
              country_code: values?.country_code,
            }
          : {},
        users_local
      );
    },
  });

  const isAmbassadorProfileCompleted =
    ambassadorBio?.length > 0 &&
    ambassadorHobbies?.length > 0 &&
    ambassadorClubs?.length > 0;

  const handleItemAdd = (type = null, text = "") => {
    if ((type === "hobbies" || type === "clubs") && text.trim()?.length > 0) {
      if (type === "hobbies") {
        if (ambassadorHobbies.includes(text)) {
          // Check for duplication
          toastify("error", "Hobby already added");
        } else {
          formik.setFieldValue("ambassadorHobbiesText", "");
          updateAmbassadorProfileState({
            ambassadorHobbies: [...ambassadorHobbies, text],
          });
        }
      } else if (type === "clubs") {
        // Check for duplication
        if (ambassadorClubs.includes(text)) {
          toastify("error", "Club already added");
        } else {
          formik.setFieldValue("ambassadorClubsText", "");
          updateAmbassadorProfileState({
            ambassadorClubs: [...ambassadorClubs, text],
          });
        }
      }
    }
  };

  const handleItemRemove = (type = null, text = "") => {
    if ((type === "hobbies" || type === "clubs") && text?.length > 0) {
      if (type === "hobbies") {
        removeHobbyItem(text);
      } else {
        removeClubItem(text);
      }
    }
  };

  const handleCallback = useCallback((file) => {
    file.forEach((item) => {
      if (!["image/png", "image/jpg", "image/jpeg"].includes(item.type)) {
        toastify("error", "Please select a valid image (png, jpg, jpeg)");
      } else if (parseInt(item.size / 1000) > 5000) {
        toastify("error", "Please select a file less than 5mb");
      } else {
        if (accessibilityHelp === "true") {
          setProfilePicURL(URL.createObjectURL(item));
          setUserProfileAttachment(item);
        } else {
          setCropperImage(URL.createObjectURL(item));
        }
        setProfilePicPopup(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateProfilePic = async (file) => {
    setShowLoader(true);

    const formData = new FormData();

    formData.append("user_profile", file ? file : userProfileAttachement);
    formData.append("remove", false);

    try {
      //updateUserProfilePic
      const response = await updateProfileImage(formData);
      if (response?.success) {
        const { profile_image_url } = response || {};
        // Update useState
        setProfilePhoto(profile_image_url);
        // Update usePersistState
        updateUserProfileImage(profile_image_url);
      }
      setShowLoader(false);
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };

  const getCropData = (file, url) => {
    setProfilePicURL(url);
    setUserProfileAttachment(file);
    if (file && url) {
      updateProfilePic(file);
      setProfilePicPopup(false);
    }
  };

  const onSaveProfilePic = () => {
    updateProfilePic(userProfileAttachement);
    setProfilePicPopup(false);
  };

  return (
    <CardCustom>
      <div
        className={cx([
          "ambassador-profile",
          variant === "popup" ? "ambassador-profile--popup" : "",
        ])}
      >
        {variant === "standard" ? (
          <div className={cx("ambassador-profile__title")}>
            <h3>Ambassador</h3>
            <img
              src={InfoIcon}
              alt="Info"
              onClick={() => updateAmbassadorProfileState({ infoPopup: true })}
            />
            <Badge complete={isAmbassadorProfileCompleted} />
            {!isAmbassadorProfileCompleted && (
              <h4 className={cx("ambassador-profile__title__incomplete-text")}>
                You need to complete your profile to access the role of an
                Ambassador
              </h4>
            )}
          </div>
        ) : (
          <div className={cx("ambassador-profile__title--popup")}>
            <h3>Complete your ambassador profile to finalize</h3>
          </div>
        )}
        <form onSubmit={formik.handleSubmit}>
          {variant === "popup" && (
            <>
              <div className="form-field-group ">
                <div className={cx("ambassador-profile__profile-picture")}>
                  <label className="textbox-label">
                    Upload a profile picture*
                  </label>
                  <div
                    className={cx(
                      "ambassador-profile__profile-picture__profile-upload"
                    )}
                  >
                    {showLoader ? (
                      <Loader />
                    ) : (
                      <div>
                        <div
                          className={cx(
                            "ambassador-profile__profile-picture__profile-upload__image-wrapper"
                          )}
                        >
                          {profilePhoto && profilePhoto.length > 0 ? (
                            <img
                              className={cx(
                                "ambassador-profile__profile-picture__profile-upload__image-wrapper__image"
                              )}
                              src={
                                profilePhoto && profilePhoto.length > 0
                                  ? profilePhoto
                                  : NoUserImage
                              }
                              alt="Profile"
                            />
                          ) : (
                            <>
                              <InitialAvatar
                                title={users && users.name ? users.name : ""}
                                alt={users && users.name ? users.name : ""}
                                index={-1}
                              />
                            </>
                          )}
                        </div>

                        <div className="profile-upload-link">
                          <div className="upload-icon">
                            <CustomUpload
                              width={
                                profilePhoto && profilePhoto.length > 0
                                  ? 200
                                  : 115
                              }
                              revertIcon={true}
                              icon={uploadIcon}
                              focusIcon={uploadFocusIcon}
                              text={
                                profilePhoto && profilePhoto.length > 0
                                  ? "Upload another"
                                  : "Upload picture"
                              }
                              handleUploadFile={handleCallback}
                              vallidFiles="image/*"
                              isSsoModal={true}
                            />
                          </div>
                        </div>

                        {(!profilePhoto || profilePhoto.length === 0) &&
                          formik.errors.user_profile_url && (
                            <p className="error-text">
                              {formik.errors.user_profile_url}
                            </p>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Phone number */}
              <div className={cx("ambassador-profile__phone_number")}>
                <div className="form-field-group">
                  <PhoneNumberSelect
                    name="phone_number"
                    label="Phone number*"
                    onChange={(phone_number, country_code, e) => {
                      formik.setFieldValue(
                        "phone_number",
                        String(phone_number).replace(country_code, "")
                      );
                      formik.setFieldValue("country_code", country_code);
                      // if (e) formik.handleBlur(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      `${formik?.values?.country_code?.toString()}${formik?.values?.phone_number?.toString()}` ??
                      ""
                    }
                    placeholder="Enter phone number"
                    error={
                      formik.touched.phone_number && formik.errors.phone_number
                    }
                  />
                </div>
              </div>
            </>
          )}

          <div className={cx("ambassador-profile__summary")}>
            <span className={cx("ambassador-profile__summary__title")}>
              Ambassador summary*
            </span>
            <div className={cx("ambassador-profile__summary__textbox")}>
              <Textarea
                onBlur={formik.handleBlur}
                name="ambassadorBio"
                label="Ambassador summary"
                defaultValue={formik.values.ambassadorBio}
                placeholder="Tell prospective students more about your background and your experience, and how you can offer help!"
                onChange={(text) => {
                  formik.setFieldValue("ambassadorBio", text);
                  updateAmbassadorProfileState({ ambassadorBio: text });
                }}
                error={
                  formik.touched.ambassadorBio && formik.errors.ambassadorBio
                    ? formik.errors.ambassadorBio
                    : ""
                }
                maxLength={500}
              />
            </div>
          </div>
          <div className={cx("ambassador-profile__hobbies")}>
            <div className="form-field-group">
              <TextboxCustom
                name="ambassadorHobbiesText"
                label="Hobbies & interests*"
                placeholder="Press enter after each hobby"
                lableaignment={variant === "popup" ? "center" : "left"}
                value={formik.values.ambassadorHobbiesText}
                onChange={(e) => {
                  formik.setFieldValue("ambassadorHobbiesText", e.target.value);
                  formik.handleBlur(e);
                }}
                onEnterKey={(e) => {
                  handleItemAdd("hobbies", formik.values.ambassadorHobbiesText);
                  formik.handleBlur(e);
                }}
                onBlur={(e) => {
                  handleItemAdd("hobbies", formik.values.ambassadorHobbiesText);
                  formik.handleBlur(e);
                }}
                maxLength={MAX_LENGTH_TEXT_FIELD}
                error={
                  formik.touched.ambassadorHobbiesText &&
                  formik?.errors?.ambassadorHobbiesText
                }
                errorText={
                  formik.touched.ambassadorHobbiesText &&
                  formik?.errors?.ambassadorHobbiesText
                }
              />
            </div>
            <div className={cx("ambassador-profile__tag")}>
              {ambassadorHobbies?.map((item, index) => (
                <div
                  key={index}
                  className={cx("ambassador-profile__tag__item")}
                >
                  <span>{item}</span>
                  <img
                    src={CloseIcon}
                    alt="cross"
                    onClick={() => handleItemRemove("hobbies", item)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={cx("ambassador-profile__interests")}>
            <div className="form-field-group">
              <TextboxCustom
                name="ambassadorClubsText"
                label="Clubs you participate in*"
                placeholder="Press enter after each club"
                lableaignment="left"
                value={formik.values.ambassadorClubsText}
                onChange={(e) => {
                  formik.setFieldValue("ambassadorClubsText", e.target.value);
                  formik.handleBlur(e);
                }}
                onEnterKey={(e) => {
                  handleItemAdd("clubs", formik.values.ambassadorClubsText);
                  formik.handleBlur(e);
                }}
                onBlur={(e) => {
                  handleItemAdd("clubs", formik.values.ambassadorClubsText);
                  formik.handleBlur(e);
                }}
                maxLength={MAX_LENGTH_TEXT_FIELD}
                error={
                  formik.touched.ambassadorClubsText &&
                  formik?.errors?.ambassadorClubsText
                }
                errorText={
                  formik.touched.ambassadorClubsText &&
                  formik?.errors?.ambassadorClubsText
                }
              />
            </div>
            <div className={cx("ambassador-profile__tag")}>
              {ambassadorClubs?.map((item, index) => (
                <div
                  key={index}
                  className={cx("ambassador-profile__tag__item")}
                >
                  <span>{item}</span>
                  <img
                    src={CloseIcon}
                    alt="cross"
                    onClick={() => handleItemRemove("clubs", item)}
                  />
                </div>
              ))}
            </div>
          </div>
          {variant === "popup" && (
            <div className={cx("ambassador-profile__submit")}>
              <ButtonCustom
                width={325}
                height={50}
                onClick={() => formik.handleSubmit()}
              >
                Finalize
              </ButtonCustom>
            </div>
          )}
        </form>
      </div>
      {infoPopup && (
        <DashboardAmbassadorInfoPopup
          ambassadorInfoPopup={infoPopup}
          setAmbassadorInfoPopup={(status) =>
            updateAmbassadorProfileState({ infoPopup: status })
          }
        />
      )}
      {showProfilePicPopup && (
        <CustomDialog
          open={showProfilePicPopup}
          title={"Upload a profile picture"}
          handleClose={() => {
            setProfilePicPopup(false);
            setUserProfileAttachment(null);
          }}
          isDisplayCloseButton={true}
          className={`${
            accessibilityHelp === "true" ? "accessibility" : ""
          } profile-pic-popup`}
          dialogTitleClass="modal-title"
          dialogSubTitleClass="text-para"
        >
          <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
            {accessibilityHelp === "true" ? (
              <>
                <div className="accessibility profile-upload">
                  <div className="profile-img-wrap">
                    <img
                      className="profile-img"
                      src={profilePicURL}
                      alt="Profile"
                      height={200}
                      width={200}
                    />
                  </div>
                </div>
                <div className="customized-button">
                  <ButtonCustom
                    isDisabled={
                      !userProfileAttachement || userProfileAttachement === null
                    }
                    onClick={onSaveProfilePic}
                  >
                    Save
                  </ButtonCustom>
                </div>
                <a
                  href="/#"
                  className="text-grey cursor-pointer link-focus"
                  onClick={(e) => {
                    e.preventDefault();
                    setProfilePicPopup(false);
                    setUserProfileAttachment(null);
                  }}
                  aria-label="cancel"
                >
                  Cancel
                </a>
              </>
            ) : (
              <>
                {cropperImage && (
                  <>
                    <CropperPic
                      image={cropperImage}
                      getCropData={getCropData}
                      profileInfo={true}
                    />
                    <br />
                  </>
                )}
              </>
            )}
          </div>
        </CustomDialog>
      )}
    </CardCustom>
  );
}

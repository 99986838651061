import React, { useState, useEffect } from "react";
import { CircleSpinner } from "react-spinners-kit";
import { Bar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Button,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import { isEmpty } from "lodash";

import CompanyInfoStyles from "./CompanyInfoStyles";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { useAppState } from "../../../../context";
import usePersistState from "../../../../state/usePersistState";
import { numberWithCommas } from "../../../../helper/helper";
import { US_STATE_OPTIONS } from "../../../../constant/mockdata";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  ACCESSIBILITY_SECONDARY_COLOR,
} from "../../../../styles/variables";
import { routes } from "../../../../routes";

import CardCustom from "../../../CardCustom/CardCustom";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import CustomDialog from "../../../CustomDialog/CustomDialog";
import SearchList from "../../SearchResult/SearchList";
import CompanyRight from "../CompanyRight/CompanyRight";
import SortByCustom from "../../../../components/SortByCustom/SortByCustom";
import JobExperienceModal from "./Common/JobExperienceModal";
import JobsListingEffect from "../../../effects/JobsListingEffect/JobsListingEffect";
import StarIcon from "../../../StarIcon/StarIcon";

import {
  getCompanyDetails,
  getCompanyPetitions,
  searchJobsAPI,
  searchTopEmployerJobsAPI,
} from "../../../../services/JobServices";

const CompanyInfo = ({
  showPetition = true,
  activeTab = "petition",
  setActiveTab = () => {},
  filters = {},
  refreshJobs = 1,
  ...props
}) => {
  const navigate = useNavigate();
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const { selectedCompany = null, appliedVisaFilters = {} } = usePersistState();

  const [expanded, setExpanded] = useState(false);

  const [callingGetCompanyDetails, refreshGetCompanyDetails] =
    usePromise(getCompanyDetails);
  const [companyData, setCompanyData] = useState({});

  const { jobs = [], setJobs } = useAppState("job");
  const [savedJobsFilter, setSavedJobsFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [callSearchJobsAPI, refreshSearchJobsAPI] = usePromise(searchJobsAPI);
  const [showLoader, setLoader] = useState(false);
  const [callSearchTopEmployerJobsAPI, refreshSearchTopEmployerJobsAPI] =
    usePromise(searchTopEmployerJobsAPI);

  const [callingGetCompanyPetitions, refreshGetCompanyPetitions] =
    usePromise(getCompanyPetitions);
  const [petitionList, setPetitionList] = useState([]);
  const [currentPetitionPage, setCurrentPetitionPage] = useState(1);
  const [totalPetitionPages, setTotalPetitionPages] = useState(0);
  const [sortByPetition, setSortByPetition] = useState("no_of_petition");
  const [sortByPosition, setSortByPosition] = useState("date");
  const [companyDetaisPopup, setCompayDetailsPopup] = useState(false);
  const [showExperiencePopup, setExperiencePopup] = useState(false);
  const [jobTitleSearch, setShowJobTitleSearch] = useState(null);

  const {
    visa = "h1b",
    year = 2024,
    experience,
    job_classification,
  } = appliedVisaFilters;

  const {
    search,
    country = "us",
    state,
    city,
    job_type,
    publish_date,
  } = filters;

  const searchType = country && country === "us" ? "us" : "international";

  useEffect(() => {
    if (
      !selectedCompany ||
      selectedCompany === null ||
      Object.keys(selectedCompany).length === 0
    ) {
      navigate(routes.JOBS.VISA);
    }
    setJobs([]);

    if (activeTab === "position") {
      setCurrentPage(1);
      setLoader(true);
      applyFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCompany && selectedCompany !== null) {
      const params = {
        page: 1,
        sort: sortByPetition,
        company_id: selectedCompany?.company_id,
        tab_type: selectedCompany?.tab_type ?? visa,
        year: year,
        ...(experience && !isEmpty(experience)
          ? { wage_level: experience }
          : {}),
        ...(job_classification?.value
          ? { job_title: job_classification?.value }
          : {}),
      };

      if (selectedCompany?.tab_type) {
        refreshGetCompanyDetails(
          selectedCompany?.company_id,
          selectedCompany?.tab_type,
          year
        );
        setLoader(true);
        setCurrentPetitionPage(1);
        refreshGetCompanyPetitions(params, appliedVisaFilters);
      } else {
        refreshGetCompanyDetails(selectedCompany?.company_id, visa, year);
        setLoader(true);
        setCurrentPetitionPage(1);
        refreshGetCompanyPetitions(params, appliedVisaFilters);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    if (refreshJobs > 1) applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshJobs]);

  const applyFilters = (params = {}) => {
    setJobs([]);
    setLoader(true);
    const defaultParams = {
      job_region: searchType,
      search: search === null ? "" : search,
      country,
      city,
      job_type,
      publish_date,
      page: 1,
      ...params,
    };

    if (country && country === "us") {
      const selectedState =
        state !== null
          ? US_STATE_OPTIONS.find((rec) => rec.value === state)
          : null;

      if (
        selectedState &&
        selectedState !== null &&
        (visa === "h1b" ||
          visa === "top_500_employers" ||
          visa === "cap_exempt")
      ) {
        defaultParams.state = selectedState.value;
      } else if (selectedState && selectedState !== null) {
        delete defaultParams.city;
        defaultParams.location = `${
          city && city !== null && city.length > 0 ? city : ""
        }${
          city &&
          city !== null &&
          city.length > 0 &&
          selectedState &&
          selectedState !== null &&
          selectedState.label
            ? ", "
            : ""
        }${
          selectedState && selectedState.label ? `${selectedState.label}` : ""
        }`;
      } else {
        defaultParams.city = null;
        defaultParams.location = `${
          city && city !== null && city.length > 0 ? city : ""
        }`;
      }
    } else {
      delete defaultParams.city;
      defaultParams.location = `${
        city && city !== null && city.length > 0 ? city : ""
      }`;
    }
    setCurrentPage(1);
    if (
      defaultParams.country === "us" &&
      (visa === "h1b" || visa === "top_500_employers" || visa === "cap_exempt")
    )
      refreshSearchTopEmployerJobsAPI({
        ...defaultParams,
        sort: sortByPosition,
        company_name: selectedCompany?.employer_name,
      });
    else
      refreshSearchJobsAPI({
        ...defaultParams,
        sort: sortByPosition,
        company_name: selectedCompany?.employer_name,
      });

    setSavedJobsFilter(defaultParams);
  };

  useEffect(() => {
    if (
      callingGetCompanyDetails.hasFetched() &&
      callingGetCompanyDetails.hasErrors() === false &&
      callingGetCompanyDetails.data().data
    ) {
      setCompanyData(callingGetCompanyDetails.data().data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetCompanyDetails.isFetching()]);

  useEffect(() => {
    if (
      callingGetCompanyPetitions.hasFetched() &&
      callingGetCompanyPetitions.hasErrors() === false
    ) {
      const petitionData = callingGetCompanyPetitions.data()?.data ?? {};
      if (petitionData) setTotalPetitionPages(petitionData.total_pages ?? 0);
      if (currentPetitionPage === 1) {
        setPetitionList(petitionData);

        if (job_classification) {
          const jobTitleRecordIndex = (
            petitionData?.petition_details ?? []
          ).findIndex((rec) => rec.job_title_id === job_classification.value);
          if (jobTitleRecordIndex >= 0)
            setShowJobTitleSearch(jobTitleRecordIndex);
        } else setShowJobTitleSearch(null);
      } else
        setPetitionList({
          ...petitionData,
          petition_details: [
            ...petitionList.petition_details,
            ...(petitionData.petition_details ?? []),
          ],
        });

      if (showLoader) setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetCompanyPetitions.isFetching()]);

  useEffect(() => {
    if (
      callSearchJobsAPI.hasFetched() &&
      callSearchJobsAPI.hasErrors() === false &&
      callSearchJobsAPI.data().data.jobs
    ) {
      setTotalPages(callSearchJobsAPI.data().data.total_pages);
      setJobs([...jobs, ...callSearchJobsAPI.data().data.jobs]);
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSearchJobsAPI.isFetching()]);

  useEffect(() => {
    if (
      callSearchTopEmployerJobsAPI.hasFetched() &&
      callSearchTopEmployerJobsAPI.hasErrors() === false &&
      callSearchTopEmployerJobsAPI.data() &&
      callSearchTopEmployerJobsAPI.data().data
    ) {
      setTotalPages(callSearchTopEmployerJobsAPI.data().data.total_pages);
      const formattedJobs = callSearchTopEmployerJobsAPI
        .data()
        .data.jobs.map((rec) => {
          const formattedRec = { ...rec, ...rec.api_response };
          delete formattedRec.api_response;
          return formattedRec;
        });
      setJobs([...jobs, ...formattedJobs]);
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSearchTopEmployerJobsAPI.isFetching()]);

  const getPaginationData = () => {
    const defaultParams = {
      ...savedJobsFilter,
      page: currentPage + 1,
    };

    setCurrentPage(currentPage + 1);

    if (
      defaultParams.country === "us" &&
      (visa === "h1b" || visa === "top_500_employers" || visa == "cap_exempt")
    )
      refreshSearchTopEmployerJobsAPI({
        ...defaultParams,
        sort: sortByPosition,
        company_name: selectedCompany?.employer_name,
      });
    else
      refreshSearchJobsAPI({
        ...defaultParams,
        sort: sortByPosition,
        company_name: selectedCompany?.employer_name,
      });
  };

  const selectButton = (tab) => {
    if (tab === "position") {
      setActiveTab("position");
      setCurrentPage(1);
      setLoader(true);
      applyFilters();
    } else {
      setActiveTab("petition");
    }
  };

  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sortByPetitionOptions = [
    { value: "wage_rate_of_pay_from", label: "Average salary" },
    { value: "no_of_petition", label: "Petition numbers" },
  ];

  const barData = (job_title, no_of_petition) => {
    let entry = petitionList.wage_levels['["entry", "' + job_title + '"]'] || 0;
    let qualified =
      petitionList.wage_levels['["qualified", "' + job_title + '"]'] || 0;
    let experienced =
      petitionList.wage_levels['["experienced", "' + job_title + '"]'] || 0;
    let fully_competent =
      petitionList.wage_levels['["fully_competent", "' + job_title + '"]'] || 0;
    let others =
      (no_of_petition || 0) - entry - qualified - experienced - fully_competent;
    return {
      labels: [
        ["Entry", " " + numberWithCommas(entry)],
        ["Qualified", " " + numberWithCommas(qualified)],
        ["Experienced", " " + numberWithCommas(experienced)],
        ["Fully Competent", " " + numberWithCommas(fully_competent)],
        ["Others", " " + numberWithCommas(others)],
      ],
      datasets: [
        {
          label: "",
          backgroundColor: [
            accessibilityHelp == "true"
              ? ACCESSIBILITY_SECONDARY_COLOR
              : SECONDARY_COLOR,
            "#0030B5",
            PRIMARY_COLOR,
            "#000C3D",
            "var(--gray-text-color)",
          ],
          data: [entry, qualified, experienced, fully_competent, others],
        },
      ],
    };
  };

  const updatePositionSortBy = (newSortBy) => {
    setJobs([]);
    setLoader(true);
    const defaultParams = {
      ...savedJobsFilter,
      page: 1,
    };

    setCurrentPage(1);
    setSortByPosition(newSortBy);

    if (
      defaultParams.country === "us" &&
      (visa === "h1b" || visa === "top_500_employers" || visa == "cap_exempt")
    )
      refreshSearchTopEmployerJobsAPI({
        ...defaultParams,
        sort: newSortBy,
        company_name: selectedCompany?.employer_name,
      });
    else
      refreshSearchJobsAPI({
        ...defaultParams,
        sort: newSortBy,
        company_name: selectedCompany?.employer_name,
      });
  };

  const updatePetitionSortBy = (newSortBy) => {
    setLoader(true);
    setSortByPetition(newSortBy);
    refreshGetCompanyPetitions(
      {
        page: 1,
        sort: newSortBy,
        company_id: selectedCompany?.company_id,
        tab_type: visa,
        year: year,
        ...(experience && !isEmpty(experience)
          ? { wage_level: experience }
          : {}),
        ...(job_classification?.value
          ? { job_title: job_classification?.value }
          : {}),
      },
      appliedVisaFilters
    );
  };

  const getPetitionPaginationData = () => {
    refreshGetCompanyPetitions(
      {
        page: currentPetitionPage + 1,
        sort: sortByPetition,
        company_id: selectedCompany?.company_id,
        tab_type: selectedCompany?.tab_type,
        year: year,
        ...(experience && !isEmpty(experience)
          ? { wage_level: experience }
          : {}),
        ...(job_classification?.value
          ? { job_title: job_classification?.value }
          : {}),
      },
      appliedVisaFilters
    );
    setCurrentPetitionPage((current) => ++current);
  };

  const renderPetition = (data, i) => {
    const graphJobTitles = petitionList.job_info
      .filter((item) => item.job_title === data.job_title)
      .map(({ job_info }) => job_info)
      .join(", ");

    return (
      <div key={i} className="petition-wrapper">
        <Accordion
          expanded={expanded === `panel_${i}`}
          onChange={handleChange2(`panel_${i}`)}
          id={`Petition-Expansion-Panel-${i}`}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`Petition-Expansion-Summary-${i}`}
            aria-label={`Petition Details`}
            className="petition-header"
          >
            <div className="petition-info">
              <div className="PrimaryDiv">
                <div className="header-primary">{data.job_title}</div>
                <div className="sub-header-primary">
                  Average Salary: $
                  {numberWithCommas(data.wage_rate_of_pay_from)}
                </div>
              </div>
              <div className="header-primary">
                {numberWithCommas(data.no_of_petition)} petitions
              </div>
            </div>
          </AccordionSummary>
          {petitionList.wage_levels && (
            <div className="petition-details">
              <a
                href="/#"
                className="popup-link link-focus"
                onClick={(e) => {
                  e.preventDefault();
                  setExperiencePopup(true);
                }}
                aria-label="Petition wage levels explaination"
              >
                What's this?
              </a>
              <AccordionDetails>
                <div className="MuiGrid-grid-xs-12">
                  <Bar
                    data={barData(data.job_title, data.no_of_petition)}
                    width={500}
                    height={200}
                    options={{
                      maintainAspectRatio: true,
                      legend: { display: false },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              display: false,
                            },
                            gridLines: {
                              display: false,
                            },
                          },
                        ],
                        xAxes: [
                          {
                            gridLines: {
                              display: false,
                            },
                            ticks: {
                              fontColor: "var(--primary-dark-color)",
                              fontSize: 16,
                              fontFamily: "TTCommons-Regular",
                            },
                          },
                        ],
                      },
                      plugins: {
                        datalabels: {
                          display: false,
                        },
                      },
                    }}
                  />
                </div>
              </AccordionDetails>
              <div className="job-titles">{graphJobTitles}</div>
            </div>
          )}
        </Accordion>
      </div>
    );
  };

  const Loader = () => {
    return (
      <div className="d-flex align-items-center justify-content-center spinner-div">
        <CircleSpinner color={PRIMARY_COLOR} size={25} />
      </div>
    );
  };

  const favCompanyKeyList =
    props.savedCompaniesList && props.savedCompaniesList.length > 0
      ? props.savedCompaniesList.map(({ company_id }) => company_id)
      : [];

  return (
    <CompanyInfoStyles>
      <div className="company">
        <div className="top-section">
          <div className="company-title mb-15">
            <div className="title-primary">
              {companyData &&
                companyData.company &&
                companyData.company.company_name}
              {favCompanyKeyList.includes(companyData?.details?.company_id) ? (
                <StarIcon
                  aria-label={`Remove ${companyData?.details?.employer_name} from saved companies`}
                  variant="filled"
                  onClick={() =>
                    props.updateFavourites({
                      ...companyData?.details,
                      like: "unlike",
                    })
                  }
                />
              ) : (
                <StarIcon
                  aria-label={`Add ${companyData?.details?.employer_name} to saved companies`}
                  onClick={() =>
                    props.updateFavourites({
                      ...companyData?.details,
                      like: "like",
                    })
                  }
                />
              )}
            </div>
            <div className="ViewDetails">
              <ButtonCustom
                width="125"
                height="40"
                onClick={() => {
                  setCompayDetailsPopup(true);
                }}
                className="bg-gray"
              >
                View Details
              </ButtonCustom>
            </div>
          </div>
          <div className="custom-tab mb-15">
            <Tooltip
              title={
                "Petitions are created by employers to hire foreign employees under the H1-B1 visa."
              }
              placement="bottom-start"
            >
              <Button
                name="petition"
                className={`${activeTab === "petition" ? "active" : ""} ${
                  showPetition ? "" : "disabled"
                }`}
                onClick={() => selectButton("petition")}
                aria-label={"Petition"}
                disableRipple={true}
                disabled={!showPetition}
              >
                Petitions
              </Button>
            </Tooltip>
            <Button
              name="position"
              className={activeTab === "position" ? "active" : ""}
              onClick={() => selectButton("position")}
              aria-label={"Position"}
              disableRipple={true}
            >
              Open positions
            </Button>
            {activeTab === "petition" && (
              <div className="sort-by ml-auto">
                <SortByCustom
                  name=""
                  label=""
                  showLabelAtTop={false}
                  justifyContent="left"
                  options={sortByPetitionOptions}
                  value={
                    sortByPetition !== null
                      ? sortByPetitionOptions.find(
                          (option) => option.value === sortByPetition
                        )
                      : ""
                  }
                  onChange={(e) => updatePetitionSortBy(e.value)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="body-section">
          <CardCustom>
            <div className="company-card">
              <div className="card-left">
                {activeTab === "petition" ? (
                  <div className="visa-info">
                    {showLoader ? (
                      <JobsListingEffect
                        count={7}
                        styles={{
                          marginTop: "0px",
                          padding: "0px",
                        }}
                      />
                    ) : (
                      petitionList &&
                      petitionList.petition_details && (
                        <InfiniteScroll
                          dataLength={petitionList.petition_details.length}
                          next={getPetitionPaginationData}
                          hasMore={
                            currentPetitionPage !== totalPetitionPages &&
                            totalPetitionPages > 0
                          }
                          loader={
                            <div style={{ margin: "20px 0 0 0" }}>
                              <JobsListingEffect
                                count={3}
                                styles={{
                                  marginTop: "0px",
                                  padding: "0px",
                                }}
                              />
                            </div>
                          }
                          style={{ overflow: "none" }}
                        >
                          {jobTitleSearch !== null ? (
                            <>
                              {/* Render searched job title first */}
                              <div className="header-primary mb-10">
                                Searched Job title
                              </div>
                              {renderPetition(
                                petitionList.petition_details[jobTitleSearch],
                                0
                              )}

                              {/* Followed by other job titles */}
                              {petitionList.petition_details.length > 1 &&
                                petitionList.petition_details.map((data, i) => (
                                  <>
                                    {i === jobTitleSearch ? null : (
                                      <>
                                        {(jobTitleSearch !== 0 && i === 0) ||
                                        (jobTitleSearch === 0 && i === 1) ? (
                                          <div className="header-primary mb-10">
                                            Other Job titles
                                          </div>
                                        ) : null}
                                        {renderPetition(data, i + 1)}
                                      </>
                                    )}
                                  </>
                                ))}
                            </>
                          ) : (
                            <>
                              {petitionList.petition_details.map((data, i) =>
                                renderPetition(data, i)
                              )}
                            </>
                          )}
                        </InfiniteScroll>
                      )
                    )}
                  </div>
                ) : (
                  <div className="visa-info open-position-content">
                    {callSearchJobsAPI.isFetching() ? (
                      <Loader />
                    ) : (
                      <SearchList
                        source="company"
                        subTitle=""
                        showSortBy={false}
                        fetchMoreData={getPaginationData}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        sortBy={sortByPosition}
                        updateSortBy={updatePositionSortBy}
                        showLoader={showLoader}
                        showPaginationLoader={
                          callSearchJobsAPI.isFetching() ||
                          callSearchTopEmployerJobsAPI.isFetching()
                        }
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="card-right">
                {callingGetCompanyDetails.isFetching() ? (
                  <div>
                    <ShimmerThumbnail width={"100"} height={"100"} />
                    <ShimmerTitle />
                    <ShimmerText />
                  </div>
                ) : (
                  <CompanyRight
                    companyData={companyData && companyData.details}
                    companyDomain={companyData && companyData.company}
                    {...props}
                  />
                )}
              </div>
            </div>
          </CardCustom>
        </div>
        {companyDetaisPopup && (
          <CustomDialog
            open={companyDetaisPopup}
            title={false}
            handleClose={() => {
              setCompayDetailsPopup(false);
            }}
            className="responsive-popup company-details-popup"
            dialogTitleClass="modal-title"
            dialogSubTitleClass="text-para"
          >
            <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
              <CompanyRight
                companyData={companyData && companyData.details}
                companyDomain={companyData && companyData.company}
                {...props}
              />
            </div>
          </CustomDialog>
        )}
      </div>
      {showExperiencePopup && (
        <JobExperienceModal onClose={() => setExperiencePopup(false)} />
      )}
    </CompanyInfoStyles>
  );
};

export default CompanyInfo;

import React, { useEffect, useState } from "react";
import { Radio } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

import { useAppState } from "../../../../../context";
import usePromise from "../../../../../hooks/usePromise/usePromise";
import { changeNotificationSettingsAPI } from "../../../../../services/notificationServices";
import usePersistState from "../../../../../state/usePersistState";
import NotificationSettingPanelStyles from "./NotificationSettingPanelStyles";
import CardCustom from "../../../../CardCustom/CardCustom";
import CustomSwitch from "../../../../CustomSwitch/CustomSwitch";

const NotificationSettingPanel = ({
  title,
  subTitle,
  type,
  appPush,
  email,
  events = [],
  showEmailFrequency,
  emailFrequencyKey,
}) => {
  const EMAIL_NOTIFICATION_FREQUENCY = [
    {
      label: "Daily",
      value: "daily",
    },
    {
      label: "Weekly",
      value: "weekly",
    },
  ];

  const { users } = usePersistState();
  const { newNotificationSettings, notificationSettings } =
    useAppState("notifications");

  const [apPush, setAppPush] = useState(appPush);
  const [emailPush, setEmail] = useState(email);

  const [
    callingChangeNotificationSettingsAPI,
    refreshChangeNotificationSettingsAPI,
  ] = usePromise(changeNotificationSettingsAPI);

  useEffect(() => {
    if (
      callingChangeNotificationSettingsAPI.hasFetched() &&
      callingChangeNotificationSettingsAPI.hasErrors() === false
    ) {
      newNotificationSettings(
        callingChangeNotificationSettingsAPI.data().data.notifications
      );
    }
  }, [callingChangeNotificationSettingsAPI.isFetching()]);

  const inAppPushNotification = () => {
    setAppPush(!apPush);
    const settingObj = {
      notification_setting: {
        push_notifications: [type],
      },
    };
    refreshChangeNotificationSettingsAPI(settingObj);
  };

  const emailNotification = () => {
    setEmail(!emailPush);
    const settingObj = {
      notification_setting: {
        email_notifications: [type],
      },
    };
    refreshChangeNotificationSettingsAPI(settingObj);
  };

  // To change notification frequency
  const applyNotificationFrequency = (frequencyValue) => {
    const formData = new FormData();
    formData.append(
      `notification_setting[${emailFrequencyKey}]`,
      frequencyValue
    );

    refreshChangeNotificationSettingsAPI(formData);
  };

  return (
    <NotificationSettingPanelStyles>
      <CardCustom className="panel-card">
        <div className="panel-info">
          <div className="panel-details">
            <h3 className="panel-title">{title}</h3>
            <div className="panel-description">{subTitle}</div>
          </div>
          <div className="panel-actions">
            <div className="panel-action-label">
              <div className="switch-container">
                In app push
                <CustomSwitch
                  handleChange={() => inAppPushNotification(type)}
                  checked={apPush}
                  isDisabled={
                    users && users.unsubscribe_push_notifications ? true : false
                  }
                />
              </div>
              <div className="switch-container">
                Email
                <CustomSwitch
                  handleChange={() => emailNotification(type)}
                  checked={emailPush}
                  isDisabled={
                    users && users.unsubscribe_notifications ? true : false
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {showEmailFrequency && emailPush && (
          <div className="email-frequency">
            <div className="email-frequency-title">Choose email frequency</div>
            <ul className="email-frequency-options">
              {EMAIL_NOTIFICATION_FREQUENCY.map((option, i) => (
                <li key={i}>
                  <Radio
                    disableRipple={true}
                    type="checkbox"
                    checked={
                      notificationSettings[emailFrequencyKey] === option.value
                    }
                    checkedIcon={
                      <CheckCircle color={"primary"} fontSize="small" />
                    }
                    inputProps={{ "aria-label": option.label }}
                    color="primary"
                    size="small"
                    onChange={() => applyNotificationFrequency(option.value)}
                  />
                  <span className="email-frequency-option-name">
                    {option.label}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {events.length > 0 && (
          <div className="events-info">
            <div className="event-label">Select all that apply</div>
            <ul className="events-list">
              {events.map((item, i) => (
                <li key={i}>
                  <Radio
                    disableRipple={true}
                    type="checkbox"
                    defaultChecked={item.checked}
                    checkedIcon={
                      <CheckCircle color={"primary"} fontSize="small" />
                    }
                    inputProps={{ "aria-label": item.event }}
                    color="primary"
                    size="small"
                  />
                  <span className="event-name">{item.event}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </CardCustom>
    </NotificationSettingPanelStyles>
  );
};

export default NotificationSettingPanel;

import React, { useState, useEffect, Fragment, useRef } from "react";
import { Dialog } from "@mui/material";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment/moment";
import _filter from "lodash/filter";
import { useNavigate } from "react-router-dom";
import Media from "react-media";
import { makeStyles } from "@mui/styles";

import MainLayoutStyles from "./MainLayoutStyles";
import { useAppState } from "../../../context";
import usePersistState from "../../../state/usePersistState";
import usePromise from "../../../hooks/usePromise/usePromise";
import Header from "../Header/Header";
import NotificationDialog from "../../../components/Notification/NotificationDialog/NotificationDialog";
import SubHeader from "../SubHeader/SubHeader";
import useClickOutsideV2 from "../../../hooks/useClickOutsideV2";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import FirstLogin from "../../Profile/FirstLogin/FirstLoginv2";
import AmbassadorProfile from "../../ProfileModule/Ambassador/AmbassadorProfile";
import { useFetchAndUpdateAmbassadorProfile } from "../../../hooks/Profile/useCommonAmbassadorProfile";

import {
  notificationsListAPI,
  notificationReadCountAPI,
} from "../../../services/notificationServices";
import {
  getUserById,
  getUserPermissionsAPI,
} from "../../../services/authenticationServices";
import {
  checkCompleteStudentProfile,
  checkUsersObject,
} from "../../../utils/common";
import { routes } from "../../../routes";
import { PRIMARY_COLOR } from "../../../styles/variables";

const useStyles = makeStyles(() => ({
  pageTitle: {
    color: PRIMARY_COLOR,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    fontSize: "42px",
    fontFamily: '"TTCommons-DemiBold"',
    margin: "38px 0 46px",
    lineHeight: "38px",
    padding: 0,
  },
}));

const MainLayout = ({
  setShowSignOutPopup,
  saveChangesClick,
  activeStep,
  title,
  children,
  getStarted,
  withSubHeader = false,
  subHeaderFixed = false,
  tabs = [],
  subHeaderList = [],
  breadCrumb = [],
  location = null,
  updatedUserDetails,
  viewOnlyNavbar = false,
  containerClassName = "",
  showSubheaderHeading = false,
  isProfile = false,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const [activePath, setActivePath] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [notificatioReDirectionUrl, setNotificationReDirectionUrl] =
    useState(null);
  const [isFetching, setFetching] = useState(false);
  const [fixedHeader, setFixedHeader] = useState(null);
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const [getUser, setUser] = useState(null);

  const [callingNotificationReadCountAPI, refreshNotificationReadCountAPI] =
    usePromise(notificationReadCountAPI);
  const [callingNotificationsListAPI, refreshNotificationsListAPI] =
    usePromise(notificationsListAPI);
  const [callGetUserPermissionsAPI, refreshGetUserPermissionsAPI] = usePromise(
    getUserPermissionsAPI
  );

  const {
    ambassadorPopup,
    ambassadorBio,
    ambassadorHobbies,
    ambassadorClubs,
    updateAmbassadorProfileState,
  } = useAppState("ambassadorProfile");

  const { tab = "student" } = useAppState("profile");

  const {
    users = {},
    firstLoginProfilePopup,
    setFirstLoginProfilePopup,
    updateUserPermissions,
    permissionsExpiry,
    decrementUnreadNotificationCount,
    users_local = {},
    updatePersistState,
  } = usePersistState();

  const {
    id: userId,
    country_code = null,
    phone_number = null,
    profile_image_url = "",
    admission_user_type,
  } = users || {};

  const [callgetUserById, refreshgetUserById] = usePromise(getUserById);
  const { loadingAmbassadorGETAPI } = useFetchAndUpdateAmbassadorProfile(); // Calls ambassador_details api and updates state

  const isSSO = users?.provider; //is SSO User
  const isStaff = users?.account_type?.toLowerCase() === "staff";
  const isStateSynced = checkUsersObject(users, users_local, isStaff);

  const { setNotifications, notificationFilters } =
    useAppState("notifications");

  // Reference used to close notification drawer on click outside via custom hook
  const refNotificationDrawer = useRef();
  useClickOutsideV2(refNotificationDrawer, () =>
    setNotificationDialogOpen(false)
  );

  useEffect(() => {
    if (
      permissionsExpiry === null ||
      moment().isAfter(moment(permissionsExpiry))
    )
      refreshGetUserPermissionsAPI();
  }, []);

  useEffect(() => {
    if (
      callGetUserPermissionsAPI.hasFetched() &&
      callGetUserPermissionsAPI.hasErrors() === false
    ) {
      if (callGetUserPermissionsAPI.data()) {
        updateUserPermissions(callGetUserPermissionsAPI.data());
      }
    }
  }, [callGetUserPermissionsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingNotificationReadCountAPI.hasFetched() &&
      callingNotificationReadCountAPI.hasErrors() === false
    ) {
      // Update count
      decrementUnreadNotificationCount();
      // Redirect user
      if (selectedItemId && selectedItemId != undefined) {
        navigate(notificatioReDirectionUrl + `?item_id=${selectedItemId}`);
      } else {
        navigate(notificatioReDirectionUrl);
      }
    }
  }, [callingNotificationReadCountAPI.isFetching()]);

  useEffect(() => {
    if (
      callingNotificationsListAPI.hasFetched() &&
      callingNotificationsListAPI.hasErrors() === false &&
      callingNotificationsListAPI.data() &&
      callingNotificationsListAPI.data().data
    ) {
      setFetching(false);
      setNotifications(callingNotificationsListAPI.data().data);
    }
  }, [callingNotificationsListAPI.isFetching()]);

  useEffect(() => {
    let filterBy = _filter(notificationFilters, (data) => {
      return data.checked === true;
    });
    setFetching(true);
    refreshNotificationsListAPI(filterBy);
  }, [location]);

  const readAndRedirectNotification = (
    notiId,
    notificationReDirectUrl,
    itemId
  ) => {
    setNotificationReDirectionUrl(notificationReDirectUrl);
    setSelectedItemId(itemId);
    refreshNotificationReadCountAPI(notiId);

    let filterBy = _filter(notificationFilters, (data) => {
      return data.checked === true;
    });
    setFetching(true);
    refreshNotificationsListAPI(filterBy);
  };

  useEffect(() => {
    if (typeof window != "undefined") {
      const changeNavigation = () => {
        if (window.scrollY >= 71) {
          setFixedHeader(true);
        } else if (window.scrollY < 71) {
          setFixedHeader(false);
        }
      };
      window.addEventListener("scroll", changeNavigation);
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (
      callgetUserById.hasFetched() &&
      callgetUserById.hasErrors() === false &&
      callgetUserById.data() &&
      callgetUserById.data()?.data &&
      callgetUserById.data()?.data?.user_info
    ) {
      setUser(callgetUserById.data().data.user_info);
      updatePersistState({
        users: {
          ...callgetUserById.data().data.user_info,
          educations: callgetUserById.data().data.user_info.educations,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callgetUserById.isFetching()]);

  useEffect(() => {
    if (!isStateSynced) {
      if (isSSO && getUser) {
        const completeProfile = checkCompleteStudentProfile(getUser, isStaff);
        setFirstLoginProfilePopup(completeProfile ? false : true);
      }
      if (!getUser) {
        refreshgetUserById(userId);
      }
    } else {
      if (isSSO) {
        const completeProfile = checkCompleteStudentProfile(users, isStaff);
        setFirstLoginProfilePopup(completeProfile ? false : true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStateSynced, getUser]);

  // Trigger ambassador porfile popup //
  useEffect(() => {
    // Do not need to show popup when user is already on profile page
    if ((window?.location?.pathname ?? "").includes(routes.PROFILE.PATH))
      return;

    if (
      !firstLoginProfilePopup &&
      !loadingAmbassadorGETAPI &&
      admission_user_type &&
      (!profile_image_url ||
        !country_code ||
        !phone_number ||
        ambassadorBio?.length === 0 ||
        ambassadorHobbies?.length === 0 ||
        ambassadorClubs?.length === 0)
    ) {
      updateAmbassadorProfileState({ ambassadorPopup: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstLoginProfilePopup,
    loadingAmbassadorGETAPI,
    profile_image_url,
    country_code,
    phone_number,
    ambassadorBio,
    ambassadorHobbies,
    ambassadorClubs,
  ]);

  const subHeaderContent = (condition, matches) => {
    return (
      <div
        style={{
          position: condition ? "fixed" : "static",
          zIndex: 1000,
          top: condition ? 0 : "auto",
          left: condition ? 0 : "auto",
          width: condition ? "99vw" : "100%",
          display: matches.small ? "block" : "flex",
          alignItems: "center",
          justifyContent: condition
            ? "space-between"
            : activeStep === 5 || !getStarted
            ? "flex-start"
            : "center",
          backgroundColor: condition ? "#FFFFFF" : "",
          padding: condition ? "0 60px 0 35px" : "auto",
        }}
      >
        <div style={{ display: "flex" }}>
          {window.location.pathname === "/profile" ? (
            <h1
              style={{ width: "auto" }}
              className={`${classes.pageTitle} h1-maintitle`}
            >
              {title}
            </h1>
          ) : null}
        </div>
        <div>
          {condition ? (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                width: matches.small ? "100%" : "auto",
                marginRight: -25,
              }}
            >
              <ButtonCustom
                style={{
                  marginRight: 10,
                  fontSize: 22,
                }}
                width={matches.small ? 95 : 173}
                height={matches.small ? 40 : 63}
                isDisabled={
                  Object.keys(updatedUserDetails).length === 0 &&
                  updatedUserDetails.constructor === Object
                    ? true
                    : false
                }
                onClick={saveChangesClick}
                type="submit"
              >
                Save
              </ButtonCustom>
              <ButtonCustom
                width={matches.small ? 95 : 173}
                height={matches.small ? 40 : 63}
                style={{
                  backgroundColor: "#8094AB",
                  fontSize: 22,
                }}
                className="bg-gray"
                onClick={() => setShowSignOutPopup(true)}
              >
                Sign Out
              </ButtonCustom>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <MainLayoutStyles>
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1299px)",
          large: "(min-width: 1300px)",
        }}
      >
        {(matches) => (
          <Fragment>
            <header role="banner">
              <Header
                setShowSignOutPopup={setShowSignOutPopup}
                setActivePath={setActivePath}
                activeStep={activeStep}
                path={window.location.pathname}
                notificationDialogOpen={notificationDialogOpen}
                setNotificationDialogOpen={setNotificationDialogOpen}
              />
              <div ref={refNotificationDrawer}>
                <NotificationDialog
                  notificationDialogOpen={notificationDialogOpen}
                  setNotificationDialogOpen={setNotificationDialogOpen}
                  title="New Notifications"
                  isFetching={isFetching}
                  noTitle="Looks like you have no new notifications!"
                  isNew={true}
                  readApiCall={(notiId, notificationReDirectUrl, itemId) =>
                    readAndRedirectNotification(
                      notiId,
                      notificationReDirectUrl,
                      itemId
                    )
                  }
                />
              </div>

              {isProfile ? (
                <div className="layout-header-bar profile-header">
                  <div className="relative-div">
                    {subHeaderContent(false, matches)}
                    {subHeaderFixed && fixedHeader
                      ? subHeaderContent(true, matches)
                      : null}
                  </div>
                </div>
              ) : (
                <div className="layout-header-bar">
                  <div
                    className="relative-div"
                    style={{ display: viewOnlyNavbar ? "none" : "" }}
                  >
                    {breadCrumb.length > 0 && (
                      <div className="breadcrumb-div">
                        <nav
                          role="navigation"
                          aria-label="breadcrumb"
                          className="card-breadcrumb"
                        >
                          <ul>
                            {breadCrumb.map(({ title, path }, index) => (
                              <li key={index}>
                                {index === breadCrumb.length - 1 ? (
                                  <span aria-current="page">
                                    {title}
                                    <span className="sr-only">
                                      Current Page
                                    </span>
                                  </span>
                                ) : (
                                  <a
                                    aria-label={`Back to ${title}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate(path);
                                    }}
                                    href="/#"
                                    className="link-focus"
                                  >
                                    {title}
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                    )}
                    {showSubheaderHeading && (
                      <h1 className={`${classes.pageTitle} h1-maintitle`}>
                        {title}
                      </h1>
                    )}
                  </div>
                </div>
              )}

              {withSubHeader && (
                <>
                  <SubHeader
                    tabs={tabs}
                    subHeaderList={subHeaderList}
                    classes={classes}
                    pageTitle={title}
                    location={location}
                    activePath={activePath}
                    setActivePath={setActivePath}
                    viewOnlyNavbar={viewOnlyNavbar}
                  />
                </>
              )}
            </header>
            <main
              style={{
                ...(isProfile && {
                  marginTop: matches.small && activeStep === 5 ? 50 : "auto",
                  paddingTop:
                    window.location.pathname === "/profile" ? 0 : "auto",
                }),
              }}
              role="main"
            >
              <div
                id="main-page-content"
                className={
                  isProfile
                    ? ""
                    : `interstride-student-main-content-wrapper ${containerClassName}`
                }
              >
                {children}
                {firstLoginProfilePopup && (
                  <Media
                    queries={{
                      small: "(max-width: 599px)",
                      medium: "(min-width: 600px) and (max-width: 1299px)",
                      large: "(min-width: 1300px)",
                    }}
                  >
                    {(matches) => (
                      <FirstLogin
                        mediaQuery={matches.small}
                        open={firstLoginProfilePopup}
                        title={"You're almost there!"}
                        handleClose={() => setFirstLoginProfilePopup(false)}
                        className="nlogin-profile-popup"
                        dialogTitleClass="modal-title"
                        dialogSubTitleClass="text-para"
                      />
                    )}
                  </Media>
                )}
                {ambassadorPopup && (
                  <Dialog
                    aria-label="Complete your ambassador profile"
                    open={ambassadorPopup}
                    maxWidth="sm"
                  >
                    <Scrollbars
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={"calc(100vh - 60px)"}
                    >
                      <AmbassadorProfile variant="popup" />
                    </Scrollbars>
                  </Dialog>
                )}
              </div>
            </main>
          </Fragment>
        )}
      </Media>
    </MainLayoutStyles>
  );
};

export default MainLayout;

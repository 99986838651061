import { redirect } from "react-router-dom";
import moment from "moment";
import Bugsnag from "@bugsnag/js";
import { isEmpty, isNull } from "lodash";

import { routes } from "../routes";
import { applyAccessibilityHelp, clearLocalStorage } from "../helper/helper";
import { getTimezones } from "../services/appointmentServices";
import { store } from "../redux/store";

export const setUserState = (userObj) => {
  const authToken =
    userObj.users.authentication_token == null ||
    !userObj.users.authentication_token
      ? localStorage.getItem("authToken")
      : userObj.users.authentication_token;

  localStorage.setItem("authToken", authToken);
  localStorage.setItem("email", userObj.users.email);
  localStorage.setItem("userId", userObj.users.id);
  userObj.users.auth_token_expiration &&
    localStorage.setItem(
      "authTokenExpiration",
      userObj.users.auth_token_expiration
    );
  localStorage.setItem("accessibilityHelp", userObj.users.accessibility_help);
  applyAccessibilityHelp(userObj.users.accessibility_help);
};

export const createFriendlyTitle = (title) => {
  return title
    .split(" ")
    .map((name) => name.charAt(0).toUpperCase() + name.substring(1))
    .join(" ");
};

export const validateChatAttachment = (file) => {
  if (
    ![
      "image/png",
      "image/jpg",
      "image/jpeg",
      "video/mp4",
      "application/pdf",
      "application/msword",
      // "text/csv",
      // "application/vnd.ms-excel",
      // "text/plain",
      // "audio/mpeg",
    ].includes(file.type)
  ) {
    return "Please select a valid file (png, jpg, jpeg, video/mp4, pdf, doc)";
  }
  const size = parseFloat(file.size / (1024 * 1024)).toFixed(2);
  if (size > 5) {
    return "Can't attach file size more than 5 MB";
  }

  return 200; //ok
};

export const validProfileImage = (file) => {
  console.log(file.type);
  if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
    return "Please select file (png, jpg, jpeg)";
  }
  const size = parseFloat(file.size / (1024 * 1024)).toFixed(2);
  if (size > 5) {
    return "Can't attach file size more than 5 MB";
  }
  return 200; //ok
};

export const validateResume = (item) => {
  if (
    ![
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(item.type)
  ) {
    return "Please select a valid file (pdf, doc, docx)";
  }
  if (parseInt(item.size / 1000) > 5000) {
    return;
  }

  return 200;
};
export function generateRandomConversationId(min, max, excluded = []) {
  var num = Math.floor(Math.random() * (max - min + 1)) + min;
  return excluded.includes(num)
    ? generateRandomConversationId(min, max, excluded)
    : num;
}
export const extractMessage = (data) => {
  const {
    message,
    sender_id,
    sender_name,
    sender_image,
    shared_file,
    send_at,
    message_id,
    file_name,
    request_meeting,
  } = data;
  return {
    message,
    sender_id,
    sender_name,
    sender_image,
    send_at,
    id: message_id,
    ...(file_name && { file_name }),
    ...(shared_file && { shared_file }),
    conversation_type: request_meeting ? "request_meeting" : "simple",
  };
};

export const createFreshChatPreview = (data, conversation_id) => {
  const preview = {
    latest_message: "",
    conversation_id,
    title: data?.full_name || data?.name || "",
    time: "",
    count: 0,
    user_image: data?.user_profile || data?.profile_image_url || null,
    receiver_id: data.id,
    user_deleted: false,
    mute_chat: false,
  };

  const conversation = {
    chat: {
      conversation_id,
      conversation_window_title: data?.full_name || data?.name || "",
      mute_chat: false,
    },
    group_details: {},
    user_info: {
      ...data,
    },
    messages: [],
    current_page: 1,
    total_messsages: 0,
    total_pages: 1,
    next_page: null,
  };

  return {
    preview,
    conversation,
  };
};

export const scrollToActiveConversation = (conversation_id) => {
  const element = document.getElementById(
    `chat_contact_list-interstride--${conversation_id}`
  );
  element && element.scrollIntoView({ behavior: "smooth", block: "center" });
};

// Allow -,',_ in names with only one consecutive occurance
export const NAME_REGEX = /^[A-Za-z\s]+(([-_'][A-Za-z]{1,})+)?$/i;

export const alphabetOnly = (text) => text && NAME_REGEX.test(text.trim());
export const validateEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
export const validateGPA = (gpa) =>
  (Number(gpa) <= 10 &&
    Number(gpa) != 0 &&
    /^(?:1?\d(?:\.\d{1,2})?\.?|10(?:\.0{0,2})?)$/.test(gpa)) ||
  gpa === "";
export const validateLinkedinURL = (url) =>
  /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm.test(url);

export const validatePhoneNumber = (number = "", countryDialCode = "") => {
  // Validate number without country code
  const onlyNumber = number?.toString()?.replace(countryDialCode, "");
  return !onlyNumber || isEmpty(onlyNumber);
};

export const isStaging =
  window?.location.origin === "https://interstride-student-portal.vercel.app";

export const uniqueId = () => {
  const timestamp = +new Date();
  const randomString = Math.random().toString(18).substring(2);
  return timestamp.toString() + randomString;
};
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const visa_statuses = [
  "F-1 - still in school",
  "F-1 OPT",
  "F-1 OPT STEM-extention",
  "J1",
  "H-1B",
  "H-1B1",
  "L-1",
  "Other",
];
export const eligible_statuses = [
  "Yes",
  "No",
  "I don't know",
  "Prefer not to say",
];
export const opportunities = [
  "Actively looking",
  "Open to opportunities",
  "Not looking",
];

export const job_types = [
  "Internship",
  "Full-time job",
  "Fellowship",
  "Post-doc",
];

export const proficiencies = [
  "Native/Fluent",
  "Full working proficiency",
  "Limited working proficiency",
  "Learning",
];

export function isLetter(e) {
  let char = String.fromCharCode(e.keyCode); // Get the character
  if (/^[A-Za-z]+$/.test(char)) return true; // Match with regex
  else e.preventDefault(); // If not match, don't add to input text
}

export const validatePassword = (password) =>
  /(?=.*?[A-Z])(?=.*?[a-z])/.test(password);

export const isValidPassword = (password) => {
  if (password?.length < 8) return "Password must have at least 8 characters";
  if (!validatePassword(password))
    return "Password should have both upper and lowercase characters";
  return 200;
};
export const notEmpty = (text) => !text?.trim()?.length === 0;
export function toCapital(text) {
  if (!text) return "";
  if (text?.length === 1) return text;
  return text.charAt(0).toUpperCase() + text.substring(1);
}
export const validatePersonalForm = (data) => {
  const {
    profile_image_url,
    first_name,
    last_name,
    nationality,
    current_city,
    account_type,
    user_type,
    password,
    cpassword,
    secondary_email,
    intro,
    time_zone,
    timezone_id,
    admission_user_type,
    country_code,
    phone_number,
  } = data || {};
  if (admission_user_type && isEmpty(profile_image_url))
    return "Profile picture is required";
  if (!first_name) return "First name is required";
  if (!last_name) return "Last name is required";
  if (!intro && isStaffAccountType(account_type, user_type, false))
    return "Introduction is required";
  if (!alphabetOnly(first_name)) return "First name not valid";
  if (!alphabetOnly(last_name)) return "Last name not valid";
  if (!account_type && !user_type) return "Account Type is required";
  if (!secondary_email && isStaffAccountType(account_type, user_type, false))
    return "Secondary email required";
  if (!time_zone && !timezone_id) return "Timezone is required";
  if (isStaffAccountType(account_type, user_type, false)) {
    if (!nationality) return "Nationality is required";
    if (!current_city) return "City is required";
  }
  if (admission_user_type && validatePhoneNumber(phone_number, country_code))
    return "Phone number is required";

  if (password || cpassword) {
    const status = isValidPassword(password);
    if (status !== 200) return status;
  }

  return 200;
};

//const currentYear = new Date().getFullYear();
export const validateEducationForm = (
  educations = [],
  account_type,
  payload
) => {
  const interstrideSelectedEducation = educations[0];
  const { graduation_date, graduation_year } =
    interstrideSelectedEducation || {};
  const getCurrentYear = () => {
    if (graduation_year) {
      return Number(graduation_year);
    }
    if (moment(graduation_date).isValid()) {
      return Number(moment(graduation_date).format("YYYY"));
    }
    return new Date().getFullYear();
  };
  const currentYear = getCurrentYear();
  if (account_type === "alumni" && !graduation_year) {
    return "Please select a past date for graduation.";
  } else if (account_type === "student" && !graduation_year) {
    return "Please select a future date for graduation.";
  }

  try {
    if (Object.keys(payload["departmentList"])?.length > 0) {
      const _department = payload["departmentList"];
      const school_id = interstrideSelectedEducation?.school_id;
      if (_department[school_id]?.length > 0) {
        if (
          !_department[school_id].some((item) => {
            return item.name === interstrideSelectedEducation?.department;
          }) ||
          !interstrideSelectedEducation?.department
        ) {
          return true;
        }
      }
    }
  } catch (error) {
    console.log(error);
  }

  if (!interstrideSelectedEducation?.graduation_year) return true;
  const currentMonth = new Date().getMonth();

  return educations.some(
    ({
      draftId,
      id,
      gpa,
      second_major,
      minor,
      major,
      degree,
      school_name,
      graduation_month,
      graduation_year,
    }) => {
      if (!school_name || !degree || !major) return true;

      if (id === interstrideSelectedEducation?.id) {
        if (
          account_type === "alumni" &&
          Number(currentYear) > new Date().getFullYear()
        ) {
          return true;
        } else if (
          account_type === "student" &&
          Number(currentYear) < new Date().getFullYear()
        ) {
          return true;
        }

        //if it is current year selected i.e 2022 in 2022
        if (Number(graduation_year) === new Date().getFullYear()) {
          const selectedMonthIndex = months.indexOf(graduation_month);
          if (selectedMonthIndex !== -1) {
            if (
              account_type === "student" &&
              selectedMonthIndex < currentMonth
            ) {
              return true;
            }
            if (
              account_type === "alumni" &&
              selectedMonthIndex >= currentMonth
            ) {
              return true;
            }
            if (
              (account_type === "student" &&
                selectedMonthIndex < currentMonth) ||
              (account_type === "alumni" && selectedMonthIndex >= currentMonth)
            ) {
              return true;
            }
          }
        }
      }

      if (draftId) {
        const alpha = school_name || degree || major;
        const beta = graduation_month && graduation_year;
        if (alpha) {
          //if has any then it must have beta
          if (!beta) return true;
        }
        if (beta) {
          if (!school_name || !degree || !major) return true;
        }
        if (second_major || minor || gpa) {
          if (!beta) return true;
          if (!school_name || !degree || !major) return true;
        }
      }
    }
  );
};

export const validateExperiences = (experiences, key = "expereinces") => {
  return experiences.some(
    ({
      draftId,
      organization,
      role,
      start_month,
      start_year,
      end_month,
      end_year,
      currently_working,
      still_active_with_this_activity,
    }) => {
      if (!organization || !role) return true;
      if (draftId) {
        if (!start_month || !start_year) return true;
        if (key === "expereinces") {
          if (!currently_working) {
            if (!end_month || !end_year) return true;
          }
        } else {
          if (!still_active_with_this_activity) {
            if (!end_month || !end_year) return true;
          }
        }
      }
      if (start_month && start_year && end_month && end_year) {
        const startDate = new Date(start_month + start_year);
        const endDate = new Date(end_month + end_year);
        if (startDate > endDate) {
          return true;
        }
      }
    }
  );
};

export const range = (start, end, step = 1) => {
  let output = [];
  if (typeof end === "undefined") {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};
export const countries = [
  {
    label: "Afghanistan",
    value: "AF",
  },
  {
    label: "Åland Islands",
    value: "AX",
  },
  {
    label: "Albania",
    value: "AL",
  },
  {
    label: "Algeria",
    value: "DZ",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Andorra",
    value: "AD",
  },
  {
    label: "Angola",
    value: "AO",
  },
  {
    label: "Anguilla",
    value: "AI",
  },
  {
    label: "Antarctica",
    value: "AQ",
  },
  {
    label: "Antigua and Barbuda",
    value: "AG",
  },
  {
    label: "Argentina",
    value: "AR",
  },
  {
    label: "Armenia",
    value: "AM",
  },
  {
    label: "Aruba",
    value: "AW",
  },
  {
    label: "Australia",
    value: "AU",
  },
  {
    label: "Austria",
    value: "AT",
  },
  {
    label: "Azerbaijan",
    value: "AZ",
  },
  {
    label: "Bahamas",
    value: "BS",
  },
  {
    label: "Bahrain",
    value: "BH",
  },
  {
    label: "Bangladesh",
    value: "BD",
  },
  {
    label: "Barbados",
    value: "BB",
  },
  {
    label: "Belarus",
    value: "BY",
  },
  {
    label: "Belgium",
    value: "BE",
  },
  {
    label: "Belize",
    value: "BZ",
  },
  {
    label: "Benin",
    value: "BJ",
  },
  {
    label: "Bermuda",
    value: "BM",
  },
  {
    label: "Bhutan",
    value: "BT",
  },
  {
    label: "Bolivia",
    value: "BO",
  },
  {
    label: "Bosnia and Herzegovina",
    value: "BA",
  },
  {
    label: "Botswana",
    value: "BW",
  },
  {
    label: "Brazil",
    value: "BR",
  },
  {
    label: "Brunei",
    value: "BN",
  },
  {
    label: "Bulgaria",
    value: "BG",
  },
  {
    label: "Burkina Faso",
    value: "BF",
  },
  {
    label: "Burundi",
    value: "BI",
  },
  {
    label: "Cambodia",
    value: "KH",
  },
  {
    label: "Cameroon",
    value: "CM",
  },
  {
    label: "Canada",
    value: "CA",
  },
  {
    label: "Cape Verde",
    value: "CV",
  },
  {
    label: "Cayman Islands",
    value: "KY",
  },
  {
    label: "Central African Republic",
    value: "CF",
  },
  {
    label: "Chad",
    value: "TD",
  },
  {
    label: "Chile",
    value: "CL",
  },
  {
    label: "China",
    value: "CN",
  },
  {
    label: "Christmas Island",
    value: "CX",
  },
  {
    label: "Cocos Islands",
    value: "CC",
  },
  {
    label: "Colombia",
    value: "CO",
  },
  {
    label: "Comoros",
    value: "KM",
  },
  {
    label: "Congo",
    value: "CG",
  },
  {
    label: "DRC Congo",
    value: "CD",
  },
  {
    label: "Cook Islands",
    value: "CK",
  },
  {
    label: "Costa Rica",
    value: "CR",
  },
  {
    label: "Cote D'Ivoire",
    value: "CI",
  },
  {
    label: "Croatia",
    value: "HR",
  },
  {
    label: "Cuba",
    value: "CU",
  },
  {
    label: "Cyprus",
    value: "CY",
  },
  {
    label: "Czechia",
    value: "CZ",
  },
  {
    label: "Denmark",
    value: "DK",
  },
  {
    label: "Djibouti",
    value: "DJ",
  },
  {
    label: "Dominica",
    value: "DM",
  },
  {
    label: "Dominican Republic",
    value: "DO",
  },
  {
    label: "Ecuador",
    value: "EC",
  },
  {
    label: "Egypt",
    value: "EG",
  },
  {
    label: "El Salvador",
    value: "SV",
  },
  {
    label: "Equatorial Guinea",
    value: "GQ",
  },
  {
    label: "Eritrea",
    value: "ER",
  },
  {
    label: "Estonia",
    value: "EE",
  },
  {
    label: "Ethiopia",
    value: "ET",
  },
  {
    label: "Falkland Islands",
    value: "FK",
  },
  {
    label: "Faroe Islands",
    value: "FO",
  },
  {
    label: "Fiji",
    value: "FJ",
  },
  {
    label: "Finland",
    value: "FI",
  },
  {
    label: "France",
    value: "FR",
  },
  {
    label: "French Guiana",
    value: "GF",
  },
  {
    label: "French Polynesia",
    value: "PF",
  },
  {
    label: "Gabon",
    value: "GA",
  },
  {
    label: "Gambia",
    value: "GM",
  },
  {
    label: "Georgia",
    value: "GE",
  },
  {
    label: "Germany",
    value: "DE",
  },
  {
    label: "Ghana",
    value: "GH",
  },
  {
    label: "Gibraltar",
    value: "GI",
  },
  {
    label: "Greece",
    value: "GR",
  },
  {
    label: "Greenland",
    value: "GL",
  },
  {
    label: "Grenada",
    value: "GD",
  },
  {
    label: "Guadeloupe",
    value: "GP",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Guatemala",
    value: "GT",
  },
  {
    label: "Guernsey",
    value: "GG",
  },
  {
    label: "Guinea",
    value: "GN",
  },
  {
    label: "Guinea-Bissau",
    value: "GW",
  },
  {
    label: "Guyana",
    value: "GY",
  },
  {
    label: "Haiti",
    value: "HT",
  },
  {
    label: "Holy See",
    value: "VA",
  },
  {
    label: "Honduras",
    value: "HN",
  },
  {
    label: "Hong Kong",
    value: "HK",
  },
  {
    label: "Hungary",
    value: "HU",
  },
  {
    label: "Iceland",
    value: "IS",
  },
  {
    label: "India",
    value: "IN",
  },
  {
    label: "Indonesia",
    value: "ID",
  },
  {
    label: "Iran",
    value: "IR",
  },
  {
    label: "Iraq",
    value: "IQ",
  },
  {
    label: "Ireland",
    value: "IE",
  },
  {
    label: "Isle of Man",
    value: "IM",
  },
  {
    label: "Israel",
    value: "IL",
  },
  {
    label: "Italy",
    value: "IT",
  },
  {
    label: "Jamaica",
    value: "JM",
  },
  {
    label: "Japan",
    value: "JP",
  },
  {
    label: "Jersey",
    value: "JE",
  },
  {
    label: "Jordan",
    value: "JO",
  },
  {
    label: "Kazakhstan",
    value: "KZ",
  },
  {
    label: "Kenya",
    value: "KE",
  },
  {
    label: "Kiribati",
    value: "KI",
  },
  {
    label: "North Korea",
    value: "KP",
  },
  {
    label: "South Korea",
    value: "KR",
  },
  {
    label: "Kuwait",
    value: "KW",
  },
  {
    label: "Kyrgyzstan",
    value: "KG",
  },
  {
    label: "Kosovo",
    value: "XK",
  },
  {
    label: "Laos",
    value: "LA",
  },
  {
    label: "Latvia",
    value: "LV",
  },
  {
    label: "Lebanon",
    value: "LB",
  },
  {
    label: "Lesotho",
    value: "LS",
  },
  {
    label: "Liberia",
    value: "LR",
  },
  {
    label: "Libya",
    value: "LY",
  },
  {
    label: "Liechtenstein",
    value: "LI",
  },
  {
    label: "Lithuania",
    value: "LT",
  },
  {
    label: "Luxembourg",
    value: "LU",
  },
  {
    label: "Macao",
    value: "MO",
  },
  {
    label: "Macedonia",
    value: "MK",
  },
  {
    label: "Madagascar",
    value: "MG",
  },
  {
    label: "Malawi",
    value: "MW",
  },
  {
    label: "Malaysia",
    value: "MY",
  },
  {
    label: "Maldives",
    value: "MV",
  },
  {
    label: "Mali",
    value: "ML",
  },
  {
    label: "Malta",
    value: "MT",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Martinique",
    value: "MQ",
  },
  {
    label: "Mauritania",
    value: "MR",
  },
  {
    label: "Mauritius",
    value: "MU",
  },
  {
    label: "Mayotte",
    value: "YT",
  },
  {
    label: "Mexico",
    value: "MX",
  },
  {
    label: "Micronesia",
    value: "FM",
  },
  {
    label: "Moldova",
    value: "MD",
  },
  {
    label: "Monaco",
    value: "MC",
  },
  {
    label: "Mongolia",
    value: "MN",
  },
  {
    label: "Montserrat",
    value: "MS",
  },
  {
    label: "Morocco",
    value: "MA",
  },
  {
    label: "Mozambique",
    value: "MZ",
  },
  {
    label: "Myanmar",
    value: "MM",
  },
  {
    label: "Namibia",
    value: "NA",
  },
  {
    label: "Nauru",
    value: "NR",
  },
  {
    label: "Nepal",
    value: "NP",
  },
  {
    label: "Netherlands",
    value: "NL",
  },
  {
    label: "Netherlands Antilles",
    value: "AN",
  },
  {
    label: "New Caledonia",
    value: "NC",
  },
  {
    label: "New Zealand",
    value: "NZ",
  },
  {
    label: "Nicaragua",
    value: "NI",
  },
  {
    label: "Niger",
    value: "NE",
  },
  {
    label: "Nigeria",
    value: "NG",
  },
  {
    label: "Niue",
    value: "NU",
  },
  {
    label: "Norfolk Island",
    value: "NF",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Norway",
    value: "NO",
  },
  {
    label: "Oman",
    value: "OM",
  },
  {
    label: "Pakistan",
    value: "PK",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Palestine",
    value: "PS",
  },
  {
    label: "Panama",
    value: "PA",
  },
  {
    label: "Papua New Guinea",
    value: "PG",
  },
  {
    label: "Paraguay",
    value: "PY",
  },
  {
    label: "Peru",
    value: "PE",
  },
  {
    label: "Philippines",
    value: "PH",
  },
  {
    label: "Pitcairn",
    value: "PN",
  },
  {
    label: "Poland",
    value: "PL",
  },
  {
    label: "Portugal",
    value: "PT",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Qatar",
    value: "QA",
  },
  {
    label: "Reunion",
    value: "RE",
  },
  {
    label: "Romania",
    value: "RO",
  },
  {
    label: "Russia",
    value: "RU",
  },
  {
    label: "Rwanda",
    value: "RW",
  },
  {
    label: "Saint Helena",
    value: "SH",
  },
  {
    label: "Saint Kitts and Nevis",
    value: "KN",
  },
  {
    label: "Saint Lucia",
    value: "LC",
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "PM",
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "VC",
  },
  {
    label: "Samoa",
    value: "WS",
  },
  {
    label: "San Marino",
    value: "SM",
  },
  {
    label: "Sao Tome and Principe",
    value: "ST",
  },
  {
    label: "Saudi Arabia",
    value: "SA",
  },
  {
    label: "Senegal",
    value: "SN",
  },
  {
    label: "Serbia",
    value: "RS",
  },
  {
    label: "Montenegro",
    value: "ME",
  },
  {
    label: "Seychelles",
    value: "SC",
  },
  {
    label: "Sierra Leone",
    value: "SL",
  },
  {
    label: "Singapore",
    value: "SG",
  },
  {
    label: "Slovakia",
    value: "SK",
  },
  {
    label: "Slovenia",
    value: "SI",
  },
  {
    label: "Solomon Islands",
    value: "SB",
  },
  {
    label: "Somalia",
    value: "SO",
  },
  {
    label: "South Africa",
    value: "ZA",
  },
  {
    label: "Spain",
    value: "ES",
  },
  {
    label: "Sri Lanka",
    value: "LK",
  },
  {
    label: "Sudan",
    value: "SD",
  },
  {
    label: "South Sudan",
    value: "SS",
  },
  {
    label: "Suriname",
    value: "SR",
  },
  {
    label: "Swaziland",
    value: "SZ",
  },
  {
    label: "Sweden",
    value: "SE",
  },
  {
    label: "Switzerland",
    value: "CH",
  },
  {
    label: "Syria",
    value: "SY",
  },
  {
    label: "Taiwan",
    value: "TW",
  },
  {
    label: "Tajikistan",
    value: "TJ",
  },
  {
    label: "Tanzania",
    value: "TZ",
  },
  {
    label: "Thailand",
    value: "TH",
  },
  {
    label: "Timor-Leste",
    value: "TL",
  },
  {
    label: "Togo",
    value: "TG",
  },
  {
    label: "Tokelau",
    value: "TK",
  },
  {
    label: "Tonga",
    value: "TO",
  },
  {
    label: "Trinidad and Tobago",
    value: "TT",
  },
  {
    label: "Tunisia",
    value: "TN",
  },
  {
    label: "Turkey",
    value: "TR",
  },
  {
    label: "Turkmenistan",
    value: "TM",
  },
  {
    label: "Turks and Caicos",
    value: "TC",
  },
  {
    label: "Tuvalu",
    value: "TV",
  },
  {
    label: "Uganda",
    value: "UG",
  },
  {
    label: "Ukraine",
    value: "UA",
  },
  {
    label: "United Arab Emirates",
    value: "AE",
  },
  {
    label: "United Kingdom",
    value: "GB",
  },
  {
    label: "United States",
    value: "US",
  },
  {
    label: "Uruguay",
    value: "UY",
  },
  {
    label: "Uzbekistan",
    value: "UZ",
  },
  {
    label: "Vanuatu",
    value: "VU",
  },
  {
    label: "Venezuela",
    value: "VE",
  },
  {
    label: "Vietnam",
    value: "VN",
  },
  {
    label: "British Virgin Islands",
    value: "VG",
  },
  {
    label: "U.S Virgin Islands",
    value: "VI",
  },
  {
    label: "Wallis and Futuna",
    value: "WF",
  },
  {
    label: "Western Sahara",
    value: "EH",
  },
  {
    label: "Yemen",
    value: "YE",
  },
  {
    label: "Zambia",
    value: "ZM",
  },
  {
    label: "Zimbabwe",
    value: "ZW",
  },
];

export const years = range(
  moment().subtract(50, "year").year(),
  moment().add(10, "year").year()
)
  .map((item) => `${item}`)
  .reverse();

const currentYear = new Date().getFullYear();

export const generateYears = (start, end) => {
  return range(start, end)
    .map((item) => `${item}`)
    .reverse();
};
export const getYears = (type) => {
  if (type === "alumni") {
    return years.filter((year) => Number(year) <= currentYear);
  }
  if (type === "student") {
    return years.filter((year) => Number(year) >= currentYear).reverse();
  }
};

export const checkValidString = (value = "") => {
  return (
    value &&
    value !== undefined &&
    value !== null &&
    value.toString().length > 0
  );
};

export const checkCompleteStudentProfile = (user, isStaff) => {
  if (!isStaff) {
    const { educations = [] } = user;
    return (
      checkValidString(user.first_name) &&
      checkValidString(user.last_name) &&
      checkValidString(user.nationality) &&
      checkValidString(user.secondary_email) &&
      checkValidString(educations?.[0]?.degree) &&
      checkValidString(educations?.[0]?.major) &&
      checkValidString(educations?.[0]?.graduation_month) &&
      checkValidString(educations?.[0]?.graduation_year)
    );
  } else {
    return (
      checkValidString(user.first_name) && checkValidString(user.last_name)
    );
  }
};

export const isStaffAccountType = (account_type, user_type, equals) => {
  if (equals) {
    return (
      account_type?.toLowerCase() === "staff" ||
      account_type?.toLowerCase() === "school_admin_user" ||
      account_type?.toLowerCase() === "super_admin_user" ||
      user_type?.toLowerCase() === "school" ||
      user_type === "school_admin" ||
      user_type === "super_admin"
    );
  } else {
    return (
      account_type?.toLowerCase() !== "staff" &&
      account_type?.toLowerCase() !== "school_admin_user" &&
      account_type?.toLowerCase() !== "super_admin_user" &&
      user_type?.toLowerCase() !== "school"
    );
  }
};

export const checkUsersObject = (users, users_local, isStaff) => {
  if (isStaff) {
    return (
      users?.first_name === users_local?.first_name &&
      users?.last_name === users_local?.last_name
    );
  } else {
    return (
      users?.first_name === users_local?.first_name &&
      users?.last_name === users_local?.last_name &&
      users?.educations?.[0]?.degree === users_local?.educations?.[0]?.degree &&
      users?.educations?.[0]?.major === users_local?.educations?.[0]?.major &&
      users?.educations?.[0]?.graduation_month ===
        users_local?.educations?.[0]?.graduation_month &&
      users?.educations?.[0]?.graduation_year ===
        users_local?.educations?.[0]?.graduation_year &&
      users?.secondary_email === users_local?.secondary_email
    );
  }
};

export const accountTypes = [
  {
    label: "Student",
    value: "student",
  },
  {
    label: "Alumni",
    value: "alumni",
  },
  {
    label: "Staff",
    value: "staff",
  },
];

export const signOutCall = async (school_id) => {
  // Leave breadcrumb
  Bugsnag.leaveBreadcrumb(
    "Session storage clear",
    {
      instance: "Sign out call",
    },
    "state"
  );
  clearLocalStorage();

  applyAccessibilityHelp(false);
  try {
    window.kommunicate.logout();
  } catch (error) {
    console.log("error", JSON.stringify(error));
  }

  // Temporarily add sso logout link for lafayette
  if (school_id == 12207) {
    window.location.href = "https://cas.lafayette.edu/cas/logout";
  } else if (school_id == 40) {
    window.location.href =
      "https://idp.interstride.com/simplesaml/module.php/core/authenticate.php?as=example-userpass";
  }
  // Redirect logout link for Utah university by Dhaval Khant
  else if (school_id === 12218) {
    window.location.href = "https://incommon2.sso.utah.edu/idp/profile/Logout";
  } else {
    window.location.pathname = routes.LOGIN; // tried with redirectDocument method but it crashes due to lack of context on sign out
  }
};

export const chatFilterOptions = ["all", "student", "admissions"];
export const communitiesFilterOptions = ["all", "student", "admissions"];
export const communitiesTabOptions = [
  "followed-community",
  "discover-community",
];
export const cacheTimezone = async () => {
  const timezoneData = await getTimezones();
  if (timezoneData?.length) {
    sessionStorage.setItem(
      "get_rails_timezone",
      JSON.stringify({ timezones: timezoneData })
    );
  }
};

export const getDateDiffInDays = (a, period) => {
  if (period?.startDate && period?.endDate) {
    return a + moment(period?.endDate).diff(moment(period?.startDate), "days");
  }
  return a;
};

export const extractOPTDatesFromString = (str) => {
  const regex = /(\w+ \d{1,2}, \d{4})/g;
  const matches = String(str).match(regex);
  return matches;
};

export const hasNull = (target) => {
  for (let member in target) {
    if (target[member] === null) return true;
  }
  return false;
};

export const isFormattedDateValid = (date, dateFormat) =>
  moment(moment(date).format(dateFormat), dateFormat, true).isValid();

/**
 * Create query string url
 * @param {*} url
 * @param {*} options
 * @returns
 */
export const createQueryStringURL = (url, options = {}) => {
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  return `${url}?${queryString}`;
};

export const widgetRedirection = (route_type = "", extraParams = {}) => {
  if (route_type === "course") {
    window.open("https://courses.interstride.com", "_blank");
  } else {
    const params = {
      ...extraParams,
      utm_source: "career-widget",
      ...(route_type ? { route_type } : {}),
    };
    window.open(`${createQueryStringURL(routes.LOGIN, params)}`, "_blank");
  }
};

/**
 * These route types can be used to redirect user after login/signup
 * When it is mandatory to have fixed path to load onboarding screen like widget signup/invited signup
 */
export const ROUTE_TYPES = {
  DASHBOARD: "dashboard",
  JOBS: "jobs",
  NETWORK: "network",
  WEBINAR: "webinars",
  RESOURCES: "resources",
  COURSES: "courses",
  EMPLOYMENT: "employment_data",
  SERVICES: "services",
  MESSAGES: "messages",
};

export const getRouteTypePath = (routes, path) => {
  const paths = {
    [ROUTE_TYPES.DASHBOARD]: routes.DASHBOARD,
    [ROUTE_TYPES.RESOURCES]: routes.LEARN.RESOURCES,
    [ROUTE_TYPES.WEBINAR]: routes.LEARN.WEBINARS,
    [ROUTE_TYPES.NETWORK]: routes.NETWORK.DISCOVER,
    [ROUTE_TYPES.MESSAGES]: routes.NETWORK.MESSAGES,
    [ROUTE_TYPES.JOBS]: routes.JOBS.SEARCH,
    [ROUTE_TYPES.EMPLOYMENT]: routes.JOBS.EMPLOYMENT_DATA,
    [ROUTE_TYPES.SERVICES]: routes.LEARN.STUDENT_SERVICES,
  };

  return paths[path] ?? routes.DASHBOARD;
};

export const UNPROTECTED_ROUTES = [
  routes.LOGIN,
  routes.PRIVACY_POLICY,
  routes.TERMS_AND_CONDITIONS,
  routes.AMBASSADOR_ACCEPT_INVITE,
  routes.VERIFY_SECONDARY_EMAIL,
];

export const WIDGET_PATHS = [routes.WIDGET];

export const getRedirectRouteFromURL = () => {
  // Fetch saved routes from redux state
  let state = store.getState();
  const savedRoute = state?.preservedStore?.savedRoute ?? null;

  const currentPath = window.location.pathname;
  const routeParams = savedRoute ?? fetchRouteParamsFromURL();

  let path =
    currentPath.includes(routes.LOGIN) ||
    currentPath.includes(routes.AMBASSADOR_ACCEPT_INVITE)
      ? routes.DASHBOARD
      : [...UNPROTECTED_ROUTES, ...WIDGET_PATHS].includes(currentPath) ||
        currentPath.includes(routes.WIDGET) // with route param for token
      ? // If no saved route found then prioritize current route
        currentPath + window.location.search
      : routes.DASHBOARD;

  if (routeParams && !isEmpty(routeParams)) {
    const {
      route_type,
      route_path = routes.DASHBOARD,
      ...additionalRouteParams
    } = routeParams;

    if (route_type) {
      path =
        additionalRouteParams && !isEmpty(additionalRouteParams)
          ? `${createQueryStringURL(
              getRouteTypePath(routes, route_type),
              additionalRouteParams
            )}`
          : getRouteTypePath(routes, route_type);
    } else {
      path =
        additionalRouteParams && !isEmpty(additionalRouteParams)
          ? `${createQueryStringURL(route_path, additionalRouteParams)}`
          : route_path === "/"
          ? routes.DASHBOARD
          : route_path;
    }
  }
  return path;
};

export const fetchRouteParamsFromURL = (defaultValues = {}) => {
  const searchParams = new URLSearchParams(window?.location?.search);

  const ROUTE_PARAMS = ["route_type"]; // using array to keep it extendable for future usage in case of id and view specific redirects

  const params = {};
  // Fetch route parameters from current URL
  ROUTE_PARAMS.forEach((param) => {
    if (searchParams.get(param) || defaultValues[param])
      params[param] = searchParams.get(param) ?? defaultValues[param] ?? "";
  });

  // Add current path of the URL if its any protected path after login to be accessed after login/signup
  const route_path = window.location.pathname;
  if (
    ![...UNPROTECTED_ROUTES, ...WIDGET_PATHS].includes(route_path) &&
    !route_path.includes(routes.WIDGET) // with route param for token
  ) {
    params.route_path = route_path;
  }

  return params;
};

/**
 * Method checks if pageURL is permissible to be accessed
 * @param {Object} permissions from Redux store
 * @param {String} pageURL of module
 * @returns {Boolean} status
 */
export const isPageAccessible = (permissions = {}, pageURL = null) => {
  if (!isEmpty(permissions) && !isNull(pageURL)) {
    return !(
      (routes.NETWORK.MESSAGES.includes(pageURL) &&
        permissions?.chat === false) ||
      (routes.NETWORK.DISCOVER.includes(pageURL) &&
        permissions?.topics === false) ||
      (pageURL !== "/jobs" &&
        routes.JOBS.EMPLOYMENT_DATA.includes(pageURL) &&
        permissions?.employment_data === false) ||
      (routes.LEARN.WEBINARS.includes(pageURL) &&
        permissions?.webinar === false) ||
      (routes.LEARN.RESOURCES.includes(pageURL) &&
        permissions?.resources === false) ||
      (routes.LEARN.STUDENT_SERVICES.includes(pageURL) &&
        permissions?.student_services === false)
    );
  }
  return false;
};

import React, { memo } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Styles from "./PhoneNumberSelectStyles";

const PhoneNumberSelect = ({
  onChange = () => {},
  onBlur = () => {},
  name,
  value,
  placeholder,
  label = "",
  isDisabled = false,
  error = false,
  autoFocus = false,
}) => {
  return (
    <Styles>
      <div className={`phone-number-select ${error ? "input-error" : ""}`}>
        {label && (
          <label htmlFor={"name*"} className={"textbox-label text-primary"}>
            {label}
          </label>
        )}
        <PhoneInput
          enableSearch
          disableSearchIcon
          countryCodeEditable={false}
          country={"us"}
          value={value}
          onChange={(phone_number, { dialCode }, e) => {
            onChange(phone_number, dialCode, e);
          }}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={isDisabled}
          inputProps={{
            name,
            autoFocus,
            required: true,
          }}
          searchPlaceholder="Search for countries"
          searchNotFound="No countries to show"
          dropdownClass="phone-number-select-dropdown"
          inputClass="phone-number-select-input"
        />
        {error && <p className="error-text">{error}</p>}
      </div>
    </Styles>
  );
};
export default memo(PhoneNumberSelect);

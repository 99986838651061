import React, { memo, useEffect } from "react";
import { isEmpty } from "lodash";

import styles from "./ChatContactListItem.module.scss";
import { createModuleStyleExtractor } from "../../../../utils/css";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";
import { Button, ButtonModifier } from "../../../atoms/Button/Button";
import { createFriendlyTitle } from "../../../../utils/common";
import usePersistState from "../../../../state/usePersistState";
import usePromise from "../../../../hooks/usePromise/usePromise";
import {
  getFavoriteNetworksAPI,
  favorite_Unfavorite_NetworksAPI,
} from "../../../../services/networkServices";
import { toastify } from "../../../../helper/helper";
import { useAppState } from "../../../../context";

import checkIcon from "../../../../assets/svg/checkmark-facelift-button.svg";
import StarIcon from "../../../StarIcon/StarIcon";

const cx = createModuleStyleExtractor(styles);

const ChatContactListItem = ({
  item,
  onContactSelect,
  group = false,
  groupTags = [],
  setSelectedUser = () => {},
}) => {
  const {
    country_image,
    country_of_origin,
    full_name,
    user_profile,
    user_types,
    major,
    graduation_year,
    id,
  } = item;

  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const { setFavoriteUsersCount } = useAppState("chat");
  const { favoriteUsers = [], setFavoriteUsers } = usePersistState();

  const [callingGetFavoriteNetworksAPI, refreshGetFavoriteNetworksAPI] =
    usePromise(getFavoriteNetworksAPI);
  const [
    callingFavorite_Unfavorite_NetworksAPI,
    refreshFavorite_Unfavorite_NetworksAPI,
  ] = usePromise(favorite_Unfavorite_NetworksAPI);

  const handleStarClick = () => {
    refreshFavorite_Unfavorite_NetworksAPI(id);
  };

  useEffect(() => {
    if (
      callingGetFavoriteNetworksAPI.hasFetched() &&
      callingGetFavoriteNetworksAPI.hasErrors() === false
    ) {
      const { favourite_list = [] } = callingGetFavoriteNetworksAPI.data().data;
      setFavoriteUsersCount(favourite_list.length);
      setFavoriteUsers(favourite_list.map(({ id }) => id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetFavoriteNetworksAPI.isFetching()]);

  useEffect(() => {
    if (
      callingFavorite_Unfavorite_NetworksAPI.hasFetched() &&
      callingFavorite_Unfavorite_NetworksAPI.hasErrors() === false
    ) {
      toastify(
        "success",
        callingFavorite_Unfavorite_NetworksAPI.data().message
      );
      refreshGetFavoriteNetworksAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingFavorite_Unfavorite_NetworksAPI.isFetching()]);

  return (
    <div className={cx("chat-users")} onClick={() => setSelectedUser(item)}>
      <div className={cx("chat-users__container")}>
        <CircularAvatar
          src={user_profile}
          name={full_name}
          round={true}
          size={55}
        />
        <div className={cx("user-more-info")}>
          <div className={cx("user-bio")}>
            <div className={cx("user-bio_info")}>
              <div className={cx("title")}>
                {" "}
                {createFriendlyTitle(full_name)}{" "}
              </div>
              <div className={cx("badge")}>
                {user_types?.map((type, index) => (
                  <span
                    key={index}
                    className={cx(
                      type === "Ambassador"
                        ? "badge--red"
                        : type !== "Mentor"
                        ? "badge--user"
                        : ""
                    )}
                  >
                    {type}
                  </span>
                ))}
              </div>
            </div>
            <span className={cx("user-bio__profesion")}>
              {major} {major && "|"} {graduation_year}
            </span>
            <span className={cx("user-location")}>
              <span>
                <img
                  src={
                    (user_types ?? []).includes("Staff") &&
                    (!country_image || isEmpty(country_image))
                      ? "https://flagpedia.net/data/flags/normal/us.png"
                      : country_image
                  }
                  style={{ width: "25px" }}
                  alt="Country"
                />
              </span>
              <span className="country-title">
                {(user_types ?? []).includes("Staff") &&
                (!country_of_origin || isEmpty(country_of_origin))
                  ? "United States"
                  : country_of_origin}
              </span>
            </span>
          </div>
          <div className={cx("user-more-info__actions")}>
            <div
              className={cx([
                "user-more-info__actions__button",
                accessibilityHelp === "true" &&
                  "user-more-info__actions__showActions",
              ])}
            >
              {group && groupTags.find((tag) => parseInt(tag.id) === id) ? (
                <Button
                  modifier={ButtonModifier.CHAT_LIST_ADDED}
                  type={"button"}
                  onClick={() => onContactSelect(item)}
                >
                  <span>Added</span>
                  <img src={checkIcon} alt="check" />
                </Button>
              ) : (
                <Button
                  modifier={ButtonModifier.CHAT_LIST}
                  type={"button"}
                  onClick={() => onContactSelect(item)}
                >
                  <span>{!group ? "Message" : "Add"}</span>
                </Button>
              )}
            </div>
            <div
              className={cx([
                "user-more-info__actions__star",
                favoriteUsers.includes(id) &&
                  "user-more-info__actions__star--active",
                accessibilityHelp === "true" &&
                  "user-more-info__actions__showActions",
              ])}
            >
              {favoriteUsers.includes(id) ? (
                <StarIcon
                  aria-label={`Remove ${full_name} from favourites`}
                  variant="filled"
                  onClick={handleStarClick}
                />
              ) : (
                <StarIcon
                  aria-label={`Add ${full_name} to favourites`}
                  onClick={handleStarClick}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ChatContactListItem);
